<template>
  <nav class="sidebar sidebar-offcanvas" id="sidebar">
    <div class="side-bar-fixed">
      <ul class="nav">
        <li class="nav-item">
          <router-link :to="{ name: 'analytics' }" class="nav-link">
            <i class="fas fa-chart-line"></i>
            <span class="menu-title ml-2">Analytics</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'messages' }" class="nav-link d-flex flex-wrap align-items-center">
            <i class="fa fa-list-ul" aria-hidden="true"></i>
            <span class="menu-title ml-2"> Messages Sent</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'replies' }" class="nav-link d-flex flex-wrap align-items-center">
            <i class="far fa-envelope"></i>
            <span class="menu-title ml-2"> Replies <span style="font-weight: 900;" v-if="unread_reply_count>0">({{ unread_reply_count }})</span></span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'manage_schedules' }" class="nav-link">
            <i class="fa fa-clock" aria-hidden="true"></i>
            <span class="menu-title ml-2">Schedules</span>
            <span v-if="current_plan==='FREE'" class="ml-1 badge badge-danger">Upgrade</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'my_recordings' }" class="nav-link">
            <i class="far fa-circle"></i>
            <span class="menu-title ml-2">Video Library</span>
            <span class="ml-1 badge badge-success">Coming Soon</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'manage_contacts' }" class="nav-link">
            <i class="fas fa-address-card"></i>
            <span class="menu-title ml-2">Manage Contacts</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'my_verified_emails' }" class="nav-link">
            <i class="fas fa-th-list"></i>
            <span class="menu-title ml-2">Sender Emails</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'manage_domains' }" class="nav-link">
            <i class="fas fa-server"></i>
            <span class="menu-title ml-2">Manage Domains</span>
            <span v-if="current_plan==='FREE' || current_plan==='STANDARD'" class="ml-1 badge badge-danger">Upgrade</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'manage_templates' }" class="nav-link">
            <i class="fas fa-clipboard-list"></i>
            <span class="menu-title ml-2">My Templates</span>
            <span v-if="current_plan === 'FREE'" class="ml-1 badge badge-danger">Upgrade</span>
          </router-link>
        </li>
        <!-- <li class="nav-item">
              <router-link :to="{ name: 'manage_landing_pages' }" class="nav-link">
                <i class="fad fa-album-collection pr-2"></i>
                <span class="menu-title">Manage Landing Pages</span>
                
              </router-link>
            </li> -->
        <li class="nav-item">
          <router-link :to="{ name: 'my_team' }" class="nav-link">
            <i class="fa fa-users" aria-hidden="true"></i>
            <span class="menu-title ml-2">My Team</span>
            <span class="ml-1 badge badge-success">Coming Soon</span>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link :to="{ name: 'integrations' }" class="nav-link">
            <i class="fas fa-bolt"></i>
            <span class="menu-title ml-2">Integrations</span>
            <span class="ml-1 badge badge-success">Coming Soon</span>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link :to="{ name: 'manage_subscription' }" class="nav-link">
            <i class="far fa-credit-card"></i>
            <span class="menu-title ml-2">Manage Subscriptions</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'manage_payment_history' }"
            class="nav-link"
          >
            <i class="fas fa-file-invoice"></i>
            <span class="menu-title ml-2">Payments & Invoices</span>
          </router-link>
        </li>

        <li v-if="false" class="nav-item mb-4">
          <router-link :to="{ name: 'account_settings' }" class="nav-link">
            <img
              src="../../public/assets/images/menu/templates.png"
              width="15"
            />
            <span class="menu-title ml-2"> Settings</span>
          </router-link>
        </li>
        <!-- <li class="nav-item pb-4">
              <a class="nav-link" href="pages/tables/basic-table.html">
                <i class="fa fa-users pr-2" aria-hidden="true"></i>
                <span class="menu-title">My Teams</span>
              </a>
            </li>
            <li class="nav-item pb-4">
              <a class="nav-link" href="pages/icons/themify.html">
                <i class="ti-star menu-icon"></i>
                <span class="menu-title">Campaigns</span>
              </a>
            </li>
            <li class="nav-item pb-4">
              <a
                class="nav-link"
                data-toggle="collapse"
                href="#auth"
                aria-expanded="false"
                aria-controls="auth"
              >
                <i class="ti-user menu-icon"></i>
                <span class="menu-title">My Branding</span>
              </a>
            </li>
            <li class="nav-item pb-4">
              <a
                class="nav-link"
                data-toggle="collapse"
                href="#auth"
                aria-expanded="false"
                aria-controls="auth"
              >
                <i class="ti-user menu-icon"></i>
                <span class="menu-title">Notification Settings</span>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link pb-4"
                data-toggle="collapse"
                href="#my-profile"
                aria-expanded="false"
                aria-controls="my-profile"
              >
                <i class="ti-palette menu-icon"></i>
                <span class="menu-title">My Profile</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" id="my-profile">
                <ul class="nav flex-column sub-menu">
                  <li class="nav-item pb-4">
                    <a class="nav-link" href="pages/ui-features/buttons.html"
                      >My Profile Settings</a
                    >
                  </li>
                </ul>
              </div>
            </li> -->
      </ul>
    </div>
  </nav>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import * as api from "@/services/api";

let refreshTimer = null;
export default {
  name: "MainMenuComponent",
  data() {
    return {};
  },
  computed: {
    current_plan() {
      return this.$store.state.access_level;
    },
    unread_reply_count() {
      return this.$store.state.unread_replies_cnt;
    }
  },
  methods: {},
  mounted: function () {
    let that = this;
    refreshTimer = setInterval(() => {
      that.$store.dispatch("fetchUnreadRepliesCount");
    }, 5000);
  },
  beforeDestroy() {
    if(refreshTimer) {
      clearInterval(refreshTimer);
      refreshTimer = null;
    }
  },
};
</script>

<style scoped>
.router-link-active {
  /* background: #e9e9e9bf; */
  color: #49c9cb !important;
  font-weight: 600;
}
.sidebar .nav:not(.sub-menu) > .nav-item.active {
  background: #e8e7ec !important;
}

span.premium {
  background: #ffd602;
  border-radius: 5px;
  margin-left: 10px;
  padding: 0px 6px;
}

.sidebar .nav .nav-item.active > .nav-link i,
.sidebar .nav .nav-item.active > .nav-link .menu-title,
.sidebar .nav .nav-item.active > .nav-link .menu-arrow {
  color: #49c9cb !important;
}
.sidebar .nav .nav-item .nav-link i {
  font-size: 1.1rem;
}
</style>
