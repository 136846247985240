<template>
  <div class="d-flex flex-column container-scroller w-100">
    
    <div id="idPageBodyWrapper" class="page-body-wrapper">
      <slot name="no_main_menu_content">
        <slot name="main_menu">
          <MainMenuComponent v-if="!hide_main_menu"></MainMenuComponent>
        </slot>
        <!-- partial -->
        <div id="idMainPanel" class="main-panel">
          <div class="content-wrapper">
            <div class="row">
              <div class="col-md-12 grid-margin">
                <slot name="main_content"></slot>
              </div>
            </div>
          </div>
        </div>
      </slot>
    </div>

    <div
      id="idBottomMenu"
      class="bottom-menu menu-fixed-bottom flex-wrap justify-content-center"
    >
      <div
        class="d-flex flex-wrap justify-content-around bottom-menu-inner-wrapper"
      >
        <div class="menu-item-holder">
          <router-link class="bottom-menu-text" :to="{ path: `analytics` }"
            >
            <p class="mobile-nav-bar d-flex flex-column">
              <i style="font-size: 2rem;" class="fas fa-chart-line"></i>
              <span>Analytics</span>
            </p></router-link
          >
        </div>
        <div class="menu-item-holder">
          <router-link class="bottom-menu-text" :to="{ path: `messages` }"
            >
            <p class="mobile-nav-bar d-flex flex-column">
              <i style="font-size: 2rem;" class="fas fa-list-ul"></i>
              <span>Messages Sent</span></p>
          </router-link>
        </div>
        <div class="menu-item-holder">
          <router-link class="bottom-menu-text" :to="{ path: `replies` }"
            >
            <p class="mobile-nav-bar d-flex flex-column">
              <i style="font-size: 2rem;" class="far fa-envelope"></i>
              <span>Replies <span style="font-weight: 900;" v-if="unread_reply_count>0">({{ unread_reply_count }})</span></span></p>
          </router-link>
        </div>
        <div class="menu-item-holder">
          <router-link class="bottom-menu-text" :to="{ path: `manage-templates` }"
            >
            <p class="mobile-nav-bar d-flex flex-column">
              <i style="font-size: 2rem;" class="fas fa-clipboard-list"></i>
              <span>Templates</span></p>
          </router-link>
        </div>
      </div>
    </div>


    <div
        v-if="show_screen_record_countdown_timer" class="countdown d-flex flex-wrap"
            style="
              position: fixed;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              background: rgba(55, 55, 55, 0.5);
              z-index: 9999999;
            "
          >
            <h2
              style="
                font-size: 11rem;
                color: #ffffff;
                margin-left: auto;
                margin-right: auto;
                margin-top: auto;
                margin-bottom: auto;
                transition-delay: 1s;
              "
            >
              {{ countdown_number }}
            </h2>
          </div>


  </div>
</template>

<script>
import MainMenuComponent from "@/components/MainMenuComponent";
// import TopNavBarComponent from "@/components/TopNavBarComponent";

export default {
  name: "BaseComponent",
  data() {
    return {
      current_user_display_name: "",
      isOnLine: true
    };
  },
  components: {
    MainMenuComponent,
    // TopNavBarComponent,
  },
  created() {},
  computed: {
    getCurrentPlan() {
      return this.$store.state.access_level;
    },
    unread_reply_count() {
      return this.$store.state.unread_replies_cnt;
    },
    show_screen_record_countdown_timer() {
      return this.$store.state.show_screen_record_countdown_timer;
    },
    countdown_number() {
      return this.$store.state.scr_countdown_value;
    },
    hide_main_menu() {
      return this.$store.state.route && (this.$store.state.route.name === 'record_video');
    }
  },
  methods: {
    showBottomFixedMenu: function () {
      let bottomMenuElement = document.getElementById("idBottomMenu");

      var scrollBarPosition = window.pageYOffset | document.body.scrollTop;
      if (scrollBarPosition >= 200) {
        if (bottomMenuElement) {
          bottomMenuElement.classList.remove("show-menu");
        }
      } else {
        if (bottomMenuElement) {
          bottomMenuElement.classList.add("show-menu");
        }
      }
    },
  },
  mounted: function () {
    //console.log(this.$store.state.sideBarOpen);

    if(!this.$store.state.sideBarOpen) {
      document.getElementById("sidebar").classList.add("d-none");
      document.getElementById("idMainPanel").classList.add("body-box-shadow");
      document.getElementById("idPageBodyWrapper").classList.add("justify-content-center");
    }
    else {
      document.getElementById("sidebar").classList.remove("d-none");
      document.getElementById("idMainPanel").classList.remove("body-box-shadow");
      document.getElementById("idPageBodyWrapper").classList.remove("justify-content-center");
    }

    let that = this;
    window.onscroll = function () {
      that.showBottomFixedMenu();
    };
    that.showBottomFixedMenu();
  },
  beforeDestroy() {

  },
};
</script>
<style>
#idInitialLoader {
  display: none!important;
}
div.menu-fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
}
div.bottom-menu-inner-wrapper {
  width: 100% !important;
  padding: 5px 5px 8px 5px;
}
div.bottom-menu {
  width: 100%;
  background: white;
  z-index: 999999;
  display: none;
}

.bottom-menu .menu-item-holder {
  opacity: 0.3;
  margin-top: auto;
  margin-bottom: auto;
}
@media (max-width: 767px) {
  .bottom-menu .bottom-menu-text {
    font-weight: 500;
    font-size: 10px;
    width: 100% !important;
    text-align: center !important;
  }
  div.bottom-menu.show-menu {
    display: flex;
  }
  .footer-outer-wrapper {
    padding-bottom: 100px;
  }
  a.bottom-menu-text i.fa {
    margin: 0px auto !important;
    width: 100%;
    font-size: 24px;
    margin-top: 0px;
    color: #000 !important;
  }
  a.bottom-menu-text.router-link-exact-active.router-link-active,
  a.bottom-menu-text.router-link-exact-active.router-link-active i.fa {
    color: #000 !important;
  }
  .bottom-menu .menu-item-holder {
    opacity: 1 !important;
  }
}
/* .menu-item-holder a.bottom-menu-text.router-link-exact-active.router-link-active {
    color: #0000 !IMPORTANT;
}
.bottom-menu .menu-item-holder i.fa {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    color: #787878 !important;
} */

nav.navbar.col-lg-12.col-12.p-0.fixed-top.d-flex.flex-row {
  box-shadow: 1px 1px 2px 0.5px #c7c5d8 !important;
}
.pagination button.btn.btn-primary {
  border: 1px solid #ededed !important;
}
.header-text {
  font-weight: 600;
}
.refresh-icon i.fas.fa-sync {
  text-align: center;
  font-size: 1.3rem;
  color: rgb(255, 214, 2) !important;
  border-radius: 5px;
  line-height: 30px;
}
button.btn.refresh-icon.btn-secondary {
  background: inherit !important;
  margin-bottom: 10px;
  border: inherit;
}

span.spinner-grow.spinner-grow-sm {
  color: rgb(255, 214, 2) !important;
}
.nav-tabs {
  border-bottom: 1px solid transparent!important;
}
.nav-tabs .nav-link:hover,
  .nav-tabs .nav-link {
    border-color: transparent!important;
}
.nav-tabs .nav-link:hover,
  .nav-tabs .nav-link:focus {
    background: transparent !important;
    border-bottom: 2px solid rgb(73, 201, 203) !important;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  background: transparent !important;
  border-bottom: 2px solid rgb(73, 201, 203) !important;
}
.tabs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.tab-content > .active {
  display: flex!important;
  flex-direction: column;
  flex-grow: 1;
  width: 100%!important;
  overflow-x: auto!important;
}
.landing-page-preview {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.device-preview-model.selected {
  border-bottom: 2px solid #000!important;
}
.desktop-preview {
  min-width: 1050px!important;
  min-height: 1000px!important;
  max-height: 1200px!important;
  overflow-x: auto!important;
}
.mobile-preview {
  max-width: 360px!important;
  max-height: 1080px!important;
  margin-left: auto!important;
  margin-right: auto!important;
}
.tabs {
  width: 100%!important;
}
.nav-tabs .nav-link {
  font-size: 1.1rem!important;
}
::-webkit-scrollbar {
    width: 6px!important;
    height: 6px!important;
}
::-webkit-scrollbar-thumb {
    background-color: rgba(11,20,26,.2);
}
::-webkit-scrollbar-track {
    background: rgba(255,255,255,.1);
}
.home_bg {
  background: #ffd602!important;
}
.btn-primary {
  background: #ffd602!important;
  border: 1px solid #ffd602!important;
}
</style>
