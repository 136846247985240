// eslint-disable-next-line no-unused-vars
import Vue from "vue";
import VueCookies from "vue-cookies";
import VTooltip from 'v-tooltip'
import AWS from 'aws-sdk';
import App from "./App.vue";
import router from "@/router";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import GAuth from "vue-google-oauth2";
import globalConfig from "./global";
import VModal from "vue-js-modal/dist/index.nocss.js";
import "vue-js-modal/dist/styles.css";
import vuetify from "./plugins/vuetify";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import Sticky from "vue-sticky-directive";
import VueSocialShare from "vue-social-sharing";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueChatScroll from "vue-chat-scroll";


Vue.use(AWS);
Vue.use(VueChatScroll);

const toastOptions = {
  // You can set your default options here
};

const gauthOption = {
  clientId: globalConfig.GOOGLE_CLIENT_ID,
  //scope:
  //  "profile email https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/gmail.send",
  scope: "profile email https://www.googleapis.com/auth/contacts.readonly",
  //prompt: "consent",
  fetch_basic_profile: true,
  //ux_mode: "redirect",
  redirect_uri: globalConfig.BASE_URL + "/auth/redirect/",
  ss_domain: globalConfig.BASE_URL,
};

Vue.use(VueCookies);
Vue.use(VTooltip)
Vue.use(VueSocialShare);

window.$cookies.config("1d");

Vue.use(GAuth, gauthOption);
Vue.use(VModal);

Vue.use(Sticky);

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(Toast, toastOptions);

Vue.config.productionTip = false;

Vue.use(VueMoment, {
  moment,
});

Sentry.init({
  Vue,
  dsn: "https://ba83f8d875e64e0197502dc4ef88321a@o529144.ingest.sentry.io/6476539", //codenginebd@gmail.com
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "kimpod.com", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

Vue.component("date-picker", DatePicker);

new Vue({
  vuetify,
  router,
  store: store,
  render: (h) => h(App),
  created() {
    let that = this;
    this.$store.dispatch("setClientTz");
    this.$store.dispatch("fetchPlanDetails");
    
    window.addEventListener('offline', () => {
      console.log("Offline")
      that.$store.commit('setOnlineStatus', false)
    })
    window.addEventListener('online', () => {
      console.log("Online")
      that.$store.commit('setOnlineStatus', true)
    })
  },
  data() {
    return {
      masterBodyStyle: {
        backgroundColor: "#16a085",
      },
    };
  },
  components: {
    DatePicker,
  },
}).$mount("#app");
