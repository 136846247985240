<template>
  <BaseComponent>
    <template v-slot:main_content>
      <div class="d-flex flex-column">
        <div
          class="d-flex flex-wrap justify-content-between header-container new-message"
        >
          <h2 class="header-text mt-3">
            Messages Sent <span
              class="ml-1"
              v-if="current_plan === 'FREE'"
              style="font-size: 0.9rem"
              >({{ total_count }}/50 videos)</span
            >
          </h2>
        </div>
        <div class="showing-details d-flex justify-content-between mt-3">
          <div
            v-if="false"
            class="custom-select"
            style="width: 190px; height: fit-content; padding: 0"
          >
            <select v-model="ld" v-on:change="ldFilterChange">
              <option value="30">Last 30 days</option>
              <option value="90">Last 90 days</option>
              <option value="120">Last 120 days</option>
              <option value="180">Last 180 days</option>
              <option value="all">All time</option>
            </select>
          </div>

          <b-button
            v-if="false"
            @click="handleRefresh()"
            :disabled="msg_loading"
            class="refresh-icon"
          >
            <b-spinner v-show="msg_loading" small type="grow"></b-spinner>
            <i v-show="!msg_loading" class="fas fa-sync"></i>
          </b-button>

          <div
            v-if="!(current_plan === 'FREE' && total_count >= 50)"
            class="header-link w-100"
            style="float: right"
          >
            <router-link
              :to="{ name: 'record_video' }"
              class="btn btn-sm btn-trans mr-2 w-100"
              style="
                background: rgb(73 201 203) !important;
                color: white !important;
                float: none;
              "
            >
              <span>
                <i class="fas fa-video"></i>
                Send Video</span
              >
            </router-link>
          </div>
        </div>
        <div class="d-flex flex-wrap">
          <div class="inbox-message-list vh-100 d-flex flex-column flex-grow-1">
            <div v-if="msg_loading == true">
              <ThreadMessageListLoader></ThreadMessageListLoader>
            </div>
            <div
              v-if="message_threads.length > 0 && msg_loading == false"
              style="overflow-y: auto"
              class="d-flex flex-column flex-grow-1"
            >
              <div
                @click="on_mouse_click(i)"
                v-for="(m, i) in message_threads"
                :key="i"
                @mouseenter="current_item = i"
                @mouseleave="current_item = null"
                :class="{
                  'mb-3': true,
                  highlight: current_item == i,
                  selected: selected_item == i,
                }"
              >
                <div
                  class="inbox-item-details"
                  style="
                    position: relative;
                    cursor: pointer;
                    box-shadow: rgb(235 235 235) 0px 0px 8px 0px !important; padding: 10px 0 10px 0;
                  "
                >
                  <div class="d-flex flex-column">
                    <div
                      v-if="false"
                      class="d-flex align-items-start pt-2"
                      style="width: 65px"
                    >
                      <avatar
                        class="mt-1"
                        fullname="My Sticker"
                        size="65"
                      ></avatar>
                    </div>
                    <div class="pl-2 pr-2">
                      <div class="d-flex flex-wrap justify-content-between">
                        <span
                          style="
                            font-weight: 500;
                            padding-bottom: 0px;
                            font-size: 0.9rem !important;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                          "
                          >{{ m.to_emails }}</span
                        >
                        <span
                          class="badge badge-warning"
                          style="
                            color: #212529;
                            border: 1px solid #d7d7d7;
                            background: transparent;
                            font-weight: 700 !important;
                          "
                          >{{ m.sending_status }}</span
                        >
                      </div>
                      <div
                        style="font-weight: 700;
                          padding-bottom: 0px;
                          font-size: 1rem !important;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                          overflow: hidden;margin: 15px 0;color: rgb(80 80 80);"
                      >
                        {{ m.subject }}
                      </div>
                      <div v-if="m.sending_status == 'Failed'">
                        <span
                          class="mr-2 text-danger badge badge-warning"
                          style="
                            font-weight: 600;
                            text-transform: uppercase;
                            background-color: #ededed;
                          "
                        >
                          Sending Failed</span
                        >
                      </div>
                      <div v-if="m.sending_status == 'Delayed'">
                        <span
                          class="mr-2 text-warning badge badge-warning"
                          style="
                            font-weight: 600;
                            text-transform: uppercase;
                            background-color: #ededed;
                          "
                        >
                          Delayed</span
                        >
                      </div>
                      <div
                        class="d-flex flex-wrap justify-content-between sending-status-date mt-2"
                      >
                        <div
                          style="
                            font-size: 0.8rem !important;
                            color: rgba(0, 0, 0, 0.9);
                          "
                        >
                          <vue-moments-ago
                            prefix="" suffix="ago"
                            :date="moment.utc(m.last_message_time).local().format('MM/DD/YYYY HH:mm')"
                            lang="en"
                          ></vue-moments-ago>
                        </div>
                        <div
                          v-if="
                            (m.sending_status != 'Failed') &
                            (m.sending_status != 'Processing')
                          "
                          class="d-flex justify-content-end"
                          style="
                            margin-bottom: 0 !important;
                            font-size: 0.7rem !important;
                            text-transform: uppercase;
                          "
                        >
                          <span
                            title="Sent"
                            data-title="Sent"
                            class="mr-2"
                            v-if="m.thread_analytics.sent"
                            ><i class="fa fa-paper-plane" aria-hidden="true"></i
                          ></span>
                          <span
                            title="Delivered"
                            data-title="Delivered"
                            class="mr-2"
                            v-if="m.thread_analytics.delivered"
                            ><i class="fas fa-check"></i
                          ></span>
                          <span
                            title="Opened"
                            data-title="Opened"
                            v-if="m.thread_analytics.opened"
                            ><i class="fas fa-check-double"></i
                          ></span>
                          <span
                            title="Watched"
                            data-title="Watched"
                            class="ml-2"
                            v-if="m.thread_analytics.watched"
                          >
                            <i class="fa fa-eye" aria-hidden="true"></i
                          ></span>
                          <span
                            class="ml-2"
                            v-if="m.thread_analytics.user_replied > 0"
                          >
                            <i class="fa fa-envelope" aria-hidden="true"></i></span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div v-if="msg_loading === false">
                <div colspan="5">
                  <div class="d-flex flex-wrap justify-content-center w-100 p-5">
                    <p style="font-size: 1.6rem; margin-top: 10px; margin-right: 15px; color: #848484;">
                      No messages found.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="pagination"
              v-show="total_count > 0"
              style="
                margin-top: 15px;
                display: flex;
                flex-direction: column;
                flex-shrink: 0;
                margin-bottom: 200px;
              "
            >
              <div class="pagination-content">
                <b-button
                  @click="previousPagination(prev_page)"
                  :disabled="prev_page === null && true"
                  variant="btn btn-primary"
                  style="width: 45px!important;border-radius: 50%;"
                >
                  <i v-show="!isPreviousLoading" class="fas fa-chevron-left"></i>
                  <b-spinner
                    v-show="isPreviousLoading"
                    small
                    type="grow"
                  ></b-spinner>

                  <span style="margin-left: 5px" v-show="isPreviousLoading">
                  </span>

                  <span style="margin-left: 5px" v-show="!isPreviousLoading">
                  </span>
                </b-button>

                <p class="pagination-name">
                  Page {{ current_page }} of {{ total_page }}
                </p>

                <b-button
                  @click="currentPagination(next_page)"
                  :disabled="next_page === null && true"
                  variant="btn btn-primary"
                  style="width: 45px !important; border-radius: 50%"
                >
                  <b-spinner
                    v-show="isNextLoading"
                    small
                    type="grow"
                  ></b-spinner>
                  <span v-show="isNextLoading"></span>

                  <span v-show="!isNextLoading"></span>
                  <i v-show="!isNextLoading" class="fas fa-chevron-right"></i>
                </b-button>
              </div>
            </div>
          </div>

          <div
            v-if="show_detail_sidebar & !detail_loading"
            id="idFixedSideBarInboxDetailOpaque"
          ></div>
          <div
            v-if="!show_detail_sidebar & detail_loading"
            id="idFixedSideBarInboxDetail"
            class="vh-100 align-items-center"
            style="height: 100vh; width: calc(100% - 360px)"
          >
            <ThreadMessageDetailLoader></ThreadMessageDetailLoader>
          </div>
          <div
            v-if="show_detail_sidebar & !detail_loading"
            id="idFixedSideBarInboxDetail"
            class="d-flex flex-column"
            style="height: 100vh; width: calc(100% - 360px)"
          >
            <div
              style="
                z-index: 99999;
                padding: 9px;
              "
            >
              <div
                style="
                  font-size: 1.4rem;
                  font-weight: 600;
                  letter-spacing: 0.03rem;
                  cursor: pointer;
                  text-align: center;
                "
              >
                <span
                  class="fa fa-chevron-left"
                  id="idToggleSidebarIcon"
                  @click="toogle_detail_sidebar"
                  style="
                    cursor: pointer;
                    font-size: 1.1rem;
                    margin-right: 10px;
                    z-index: 999;
                  "
                ></span>

                <span id="idThreadDetailHeader">{{ threadTitle }}</span>
                <i
                  style="margin-left: 15px"
                  @click="showCancelConfirmation()"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="threadUsers"
                >
                  <img
                    class="mr-2"
                    src="../../public/assets/images/management-md.png"
                    style="width: 25px !important; margin-top: -5px"
                  />
                </i>
                <i
                  style="margin-left: 15px"
                  @click="showGroupConfirmation()"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="threadUsers"
                >
                  <img
                    class="mr-2"
                    src="../../public/assets/images/analytics-md.png"
                    style="width: 25px !important; margin-top: -5px"
                  />
                </i>

                <b-modal
                  title="Users"
                  id="idTemplateCancelConfirmation"
                  ref="idTemplateCancelConfirmationDialog"
                  no-close-on-esc
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header-close
                >
                  <li
                    v-for="(threadUser, index) in threadUsers"
                    v-bind:key="index"
                  >
                    {{ threadUser }}
                  </li>

                  <p class="d-flex flex-wrap justify-content-center">
                    <button
                      class="btn btn-sm btn-warning"
                      @click="hideCancelConfirmation()"
                    >
                      Got it
                    </button>
                  </p>
                </b-modal>
              </div>
              <ul class="message-sent">
                <li v-if="sendingStatus != 'Failed'">
                  <div
                    class="mr-3 message-icon-level-text"
                    v-if="threadAnalytics.sent"
                  >
                    <span v-b-tooltip.hover.left="sent_at"
                      ><i class="fa fa-paper-plane" aria-hidden="true"></i>
                      Sent</span
                    >
                  </div>
                  <div class="mr-3 message-level-text" v-else>
                    <span> Sent</span>
                  </div>
                </li>
                <li v-else>
                  <div class="mr-3 message-icon-level-text text-danger">
                    <span
                      ><i
                        class="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>
                      {{ sendingStatus }}</span
                    >
                  </div>
                </li>
                <li>
                  <div
                    class="mr-3 message-icon-level-text"
                    v-if="threadAnalytics.delivered"
                  >
                    <span v-b-tooltip.hover.left="delivered_at"
                      ><i
                        v-show="threadAnalytics.delivered"
                        class="fas fa-check"
                      ></i>
                      Delivered</span
                    >
                  </div>
                  <div class="mr-3 message-level-text" v-else>
                    <span> Delivered</span>
                  </div>
                </li>
                <li>
                  <div
                    class="mr-3 message-icon-level-text"
                    v-if="threadAnalytics.opened"
                  >
                    <span v-b-tooltip.hover.left="opened_at"
                      ><i class="fas fa-check-double"></i> Opened</span
                    >
                  </div>
                  <div class="mr-3 message-level-text" v-else>
                    <span> Opened</span>
                  </div>
                </li>
                <li>
                  <div
                    class="mr-3 message-icon-level-text"
                    v-if="threadAnalytics.clicked"
                  >
                    <span
                      ><i
                        v-show="threadAnalytics.clicked"
                        class="fas fa-check"
                      ></i>
                      Clicked</span
                    >
                  </div>
                  <div class="mr-3 message-level-text" v-else>
                    <span> Clicked</span>
                  </div>
                </li>
                <li>
                  <div
                    class="mr-3 message-icon-level-text"
                    v-if="threadAnalytics.watched"
                  >
                    <span
                      ><i class="fa fa-eye" aria-hidden="true"></i>
                      Watched</span
                    >
                  </div>
                  <div class="mr-3 message-level-text" v-else>
                    <span> Watched</span>
                  </div>
                </li>
                <li>
                  <div
                    class="mr-3 message-icon-level-text"
                    v-if="threadAnalytics.user_replied > 0"
                  >
                    <span
                      ><i class="fa fa-envelope" aria-hidden="true"></i>
                      Replied</span
                    >
                  </div>
                  <div class="mr-3 message-level-text" v-else>
                    <span> Replied</span>
                  </div>
                </li>
              </ul>
            </div>

            <div
              class="messageArea"
              style="
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                overflow: hidden;
                width: 100%;
              "
            >
              <div
                id="idMessageList"
                class="d-flex flex-column pt-4 flex-grow-1"
                style="width: 100%"
                v-chat-scroll
              >
                <div v-for="(m, i) in threadMessages" :key="i">
                  <div
                    v-bind:class="{
                      'd-flex flex-column': true,
                      'other-user': !m.sent_by_me && !m.first_message,
                      me: m.sent_by_me && !m.first_message,
                    }"
                  >
                    <div
                      v-bind:style="
                        m.first_message
                          ? {
                              padding: '20px',
                              'max-width': '600px',
                              margin: 'auto',
                              border: '1px solid #f3f3f3',
                              'border-radius': '20px',
                              'margin-bottom': '20px',
                            }
                          : {}
                      "
                    >
                      <div v-if="m.first_message">
                        <div
                          @click="showDetailPopup(m)"
                          class="poster-container"
                        >
                          <img
                            loading="lazy"
                            class="main-poster-img"
                            :src="m.poster_image"
                            style="width: 100%; cursor: pointer"
                          />
                          <i
                            class="fa fa-play main-poster-btn"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <div
                          class="mt-2"
                          v-if="m.first_message && m.has_detail"
                          style="cursor: pointer; font-weight: 600"
                          @click="showDetailPopup(m)"
                        >
                          <div
                            class="mt-2"
                            style="font-weight: 600"
                            v-if="m.first_message"
                            v-html="m.text"
                          ></div>
                          <p
                            style="
                              font-weight: 400;
                              font-size: 0.8rem;
                              font-style: italic;
                              opacity: 0.9;
                            "
                            class="pl-3"
                            v-if="templateInfo && templateInfo != ''"
                          >
                            (Sent with template
                            <span style="font-weight: 600">{{
                              templateInfo
                            }}</span
                            >)
                          </p>
                        </div>
                      </div>
                      <div v-else>
                        <div>
                          <span style="font-weight: 600; font-size: 0.8rem"
                            >{{ m.sender_name }}:</span
                          >
                        </div>
                        <vue-markdown v-if="m.content_type == 'MD'">{{
                          m.msg_body_text
                        }}</vue-markdown>
                        <div v-if="m.content_type == 'HTML'">
                          <div
                            v-if="m.has_detail"
                            style="cursor: pointer"
                            @click="showDetailPopup(m)"
                          >
                            <vue-markdown v-html="m.msg_body"></vue-markdown>
                          </div>
                          <div v-else>
                            <vue-markdown v-html="m.msg_body"></vue-markdown>
                          </div>
                        </div>

                        <div
                          v-bind:class="{
                            'd-flex flex-wrap align-items-center': true,
                            'justify-content-end': m.sent_by_me,
                            'justify-content-start': !m.sent_by_me,
                          }"
                          style="font-size: 0.8rem; text-align: right"
                        >
                          <i
                            v-if="m.detail_type === video_content"
                            style="font-size: 1.2rem"
                            class="fa fa-play-circle"
                            aria-hidden="true"
                          ></i>
                          <span
                            v-b-tooltip.hover.left="
                              moment.utc(m.sent_at).local().format('MM/DD/YYYY HH:mm')
                            "
                            v-if="m.sent_at && m.sent_at !== 'None'"
                          >
                            <vue-moments-ago
                              prefix=""
                              suffix="ago"
                              :date="moment.utc(m.sent_at).local().format('MM/DD/YYYY HH:mm')"
                              lang="en"
                            ></vue-moments-ago>
                          </span>
                          <span
                            v-b-tooltip.hover.left="
                              moment.utc(m.replied_at).local().format('MM/DD/YYYY HH:mm')
                            "
                            v-else-if="m.replied_at && m.replied_at !== 'None'"
                          >
                            <vue-moments-ago
                              prefix=""
                              suffix="ago"
                              :date="moment.utc(m.replied_at).local().format('MM/DD/YYYY HH:mm')"
                              lang="en"
                            ></vue-moments-ago>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="m.attachments.length > 0"
                      class="d-flex flex-column mb-2"
                    >
                      <div v-for="(ma, j) in m.attachments" :key="j">
                        <a target="_blank" :href="ma.url"
                          ><i class="fa fa-paperclip" aria-hidden="true"></i>
                          {{ ma.original_name }}</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="message-box"
                tabindex="0"
                v-if="sendingStatus != 'Failed'"
              >
                <div id="sveInputEmoji">
                  <div class="your-input-box">
                    <textarea
                      :disabled="disable_sending"
                      id="idReplyTextbox"
                      v-model="reply_text"
                      v-on:keyup.enter="sendReply"
                      class="d-flex flex-grow-1 mr-2"
                      style="
                        padding: 10px;
                        border: 1px solid #ddd9d9;
                        border-radius: 10px;
                        background: #ffffff;
                        width: 100%;
                      "
                    ></textarea>
                  </div>
                  <div class="emoji-picker">
                    <VEmojiPicker
                      v-show="showDialog"
                      labelSearch="Search"
                      @select="onSelectEmoji"
                    />
                  </div>

                  <div class="d-flex flex-wrap justify-content-between mt-2">
                    <div>
                      <button
                        :disabled="disable_sending"
                        id="idEmojiButton"
                        class="btn btn-emoji"
                        @click="toogleDialogEmoji"
                      >
                        {{ emoji_button_icon }}
                      </button>
                      <button
                        :disabled="disable_sending"
                        id="idSendVideoIcon"
                        class="btn btn-trans"
                        @click="showVideoRecordModal()"
                      >
                        <i class="fas fa-video"></i>
                      </button>
                      <button
                        v-show="false"
                        style="margin-top: 10px"
                        class="btn btn-trans ml-3"
                      >
                        <i class="fas fa-paperclip"></i>
                      </button>
                    </div>
                    <div>
                      <button
                        :disabled="disable_sending"
                        @click="sendReply"
                        id="idSendReplyBtn"
                        class="btn btn-danger"
                        style="
                          font-size: 0.9rem;
                          text-transform: uppercase;
                          max-height: 40px;
                        "
                      >
                        Reply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            ref="idRecordingDetailPopup"
            class="d-none"
            style="
              background: rgba(0, 0, 0, 0.5);
              z-index: 999999;
              position: fixed;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              overflow-y: auto;
            "
          >
            <div id="idVideoDetailPopupBody">
              <h5
                style="font-size: 1.2rem; font-weight: 600"
                class="text-center text-dark p-2"
              >
                Message Details
                <i
                  @click="hideDetailPopup()"
                  class="fas fa-times"
                  style="cursor: pointer; float: right"
                ></i>
              </h5>
              <div class="d-flex flex-wrap justify-content-center">
                <video-player
                  v-if="replyVideoPlaybackReady"
                  id="idReplyDetailVideoPlayer"
                  :src="detail_content"
                  :key="detail_content"
                  :videoURL="detail_content"
                  :options="videoOptions"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          style="
            background: rgba(0, 0, 0, 0.3);
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 999;
          "
          v-if="isEnable"
        >
          <div class="history-message">
            <div>
              <div v-if="false" class="history-copy-text">
                <div
                  v-on:focus="$event.target.select()"
                  ref="clone"
                  style="cursor: pointer; text-align: right"
                  class="d-flex input-copy-text align-items-center"
                  readonly
                  :value="urlLink"
                >
                  {{ urlLink }}
                  <span
                    @click="doCopy"
                    class="docopy"
                    v-b-tooltip.hover.left="copyText"
                  >
                    <i class="fas fa-copy"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="w-100 d-flex flex-wrap justify-content-start">
              <div class="analytics-cancel">
                <i
                  class="fa fa-chevron-left"
                  @click="isEnable = false"
                  style="
                    cursor: pointer;
                    font-size: 1.5rem;
                    position: absolute;
                    left: 0px;
                    top: 15px;
                    z-index: 1000;
                  "
                ></i>
              </div>
              <div
                class="d-flex flex-column timeline"
                style="background: white; padding: 0 20px 20px 20px"
              >
                <h3 class="mb-2">Timeline</h3>
                <b-spinner
                  v-show="msg_loading"
                  type="grow"
                  label="Spinning"
                  style="margin: 0px 150px 0px"
                ></b-spinner>

                <timeline v-show="!msg_loading">
                  <div
                    v-for="(threadUser, index) in threadhistory"
                    v-bind:key="index"
                  >
                    <timeline-item :hollow="true"
                      >{{ threadUser.text }} -
                      {{
                        moment.utc(threadUser.time).local().format('MM/DD/YYYY HH:mm')
                      }}
                      <span v-if="threadUser.ua"> - {{ threadUser.ua }}</span>
                      </timeline-item
                    >
                  </div>
                </timeline>
              </div>
              <div class="blocks">
                <div class="single-message-history-block">
                  <h5>
                    {{
                      threadAnalytics.num_opens > 1
                        ? `Open ${threadAnalytics.num_opens || 0} times`
                        : `Open ${threadAnalytics.num_opens || 0} time`
                    }}
                  </h5>
                </div>
                <div class="single-message-history-block">
                  <p>
                    {{
                      threadAnalytics.total_reply_count > 1
                        ? `Replies ${
                            threadAnalytics.total_reply_count || 0
                          } times`
                        : ` Replies ${
                            threadAnalytics.total_reply_count || 0
                          } time`
                    }}
                  </p>
                  <a
                    v-show="threadAnalytics.total_reply_count > 0"
                    @click="showInboxConfirmation()"
                    style="text-decoration: underline;font-size: 0.9rem;"
                  >
                    Details
                </a>
                </div>
                <div class="single-message-history-block">
                  <h5>
                    {{
                      threadAnalytics.num_clicked > 1
                        ? ` ${threadAnalytics.num_clicked || 0} message clicks`
                        : ` ${threadAnalytics.num_clicked || 0} message click`
                    }}
                  </h5>
                </div>
                <div class="single-message-history-block">
                  <h5>
                    {{
                      threadAnalytics.liked_count > 1
                        ? `${threadAnalytics.liked_count || 0} likes`
                        : `${threadAnalytics.liked_count || 0} like`
                    }}
                  </h5>
                </div>
                <div class="single-message-history-block">
                  <h5>
                    {{
                      threadAnalytics.loved_count > 1
                        ? `${threadAnalytics.loved_count || 0} loves`
                        : `${threadAnalytics.loved_count || 0} love`
                    }}
                  </h5>
                </div>
                <div class="single-message-history-block">
                  <p>
                    {{
                      threadAnalytics.num_cta_click > 1
                        ? ` ${threadAnalytics.num_cta_click || 0} CTA clicks`
                        : `${threadAnalytics.num_cta_click || 0} CTA click`
                    }}
                  </p>

                  <button
                    v-show="num_cta_click > 0"
                    type="button"
                    @click="showCTAConfirmation()"
                    class="btn btn-primary"
                  >
                    Details
                  </button>
                </div>
              </div>

              <b-modal
                title="Replied details"
                id="idInboxConfirmation"
                ref="idInboxConfirmationDialog"
                no-close-on-esc
                no-close-on-backdrop
                centered
                hide-footer
                hide-header-close
              >
                <div class="history-analytics modal-popup">
                  <div class="single-message-history-block">
                    <h5>
                      {{
                        threadAnalytics.you_replied > 1
                          ? `You replied ${
                              threadAnalytics.you_replied || 0
                            } times`
                          : `You replied  ${
                              threadAnalytics.you_replied || 0
                            } time`
                      }}
                    </h5>
                  </div>

                  <div class="single-message-history-block">
                    <h5>
                      {{
                        threadAnalytics.user_replied > 1
                          ? `user ${threadAnalytics.user_replied || 0} times`
                          : `user ${threadAnalytics.user_replied || 0} time`
                      }}
                    </h5>
                  </div>

                  <p
                    class="d-flex flex-wrap justify-content-center"
                    width="100%"
                  >
                    <button
                      class="btn btn-sm btn-warning"
                      @click="hideInboxCancelConfirmation()"
                    >
                      Got it
                    </button>
                  </p>
                </div>
              </b-modal>
              <b-modal
                title="Replied details"
                id="idInboxConfirmation"
                ref="idsCTAConfirmationDialog"
                no-close-on-esc
                no-close-on-backdrop
                centered
                hide-footer
                hide-header-close
              >
                <div class="history-analytics modal-popup">
                  <div class="single-message-history-block">
                    <h5>
                      {{
                        threadAnalytics.you_replied > 1
                          ? `You replied ${
                              threadAnalytics.you_replied || 0
                            } times`
                          : `You replied  ${
                              threadAnalytics.you_replied || 0
                            } time`
                      }}
                    </h5>
                  </div>

                  <div class="single-message-history-block">
                    <h5>
                      {{
                        threadAnalytics.user_replied > 1
                          ? `user ${threadAnalytics.user_replied || 0} times`
                          : `user ${threadAnalytics.user_replied || 0} time`
                      }}
                    </h5>
                  </div>

                  <p
                    class="d-flex flex-wrap justify-content-center"
                    width="100%"
                  >
                    <button
                      class="btn btn-sm btn-warning"
                      @click="hideCTAConfirmation()"
                    >
                      Got it
                    </button>
                  </p>
                </div>
              </b-modal>
              <b-modal
                title="Video Watch details"
                id="idInboxConfirmation"
                ref="idsWatchCountConfirmationDialog"
                no-close-on-esc
                no-close-on-backdrop
                centered
                hide-footer
                hide-header-close
              >
                <div class="history-analytics modal-popup">
                  <div class="single-message-history-block">
                    <h5>
                      {{
                        `Video Watch Count 0 Second -  ${
                          threadAnalytics.video_watch_count || 0
                        } `
                      }}
                    </h5>
                  </div>
                  <div class="single-message-history-block">
                    <h5>
                      {{
                        `Video Watch Count 2 seconds - ${
                          threadAnalytics.video_watch_count2s_display || 0
                        }`
                      }}
                    </h5>
                  </div>
                  <div class="single-message-history-block">
                    <h5>
                      {{
                        `Video Watch Count 3 seconds - ${
                          threadAnalytics.video_watch_count3s_display || 0
                        } `
                      }}
                    </h5>
                  </div>
                  <div class="single-message-history-block">
                    <h5>
                      {{
                        `Video Watch Count 5 seconds - ${
                          threadAnalytics.video_watch_count5s_display || 0
                        } `
                      }}
                    </h5>
                  </div>
                  <div class="single-message-history-block">
                    <h5>
                      {{
                        `Video Watch Count 10 seconds - ${
                          threadAnalytics.video_watch_count10s_display || 0
                        }`
                      }}
                    </h5>
                  </div>
                  <div class="single-message-history-block">
                    <h5>
                      {{
                        `Video Watch Count 20 seconds - ${
                          threadAnalytics.video_watch_count20s_display || 0
                        } `
                      }}
                    </h5>
                  </div>
                  <div class="single-message-history-block">
                    <h5>
                      {{
                        `Video Watch Count 30 seconds -  ${
                          threadAnalytics.video_watch_count30s_display || 0
                        } `
                      }}
                    </h5>
                  </div>

                  <div class="single-message-history-block">
                    <h5>
                      {{
                        `Video Watch Count 60 seconds -  ${
                          threadAnalytics.video_watch_count60s_display || 0
                        } `
                      }}
                    </h5>
                  </div>
                  <p
                    class="d-flex flex-wrap justify-content-center"
                    width="100%"
                  >
                    <button
                      class="btn btn-sm btn-warning"
                      @click="hideWatchCountConfirmation()"
                    >
                      Got it
                    </button>
                  </p>
                </div>
              </b-modal>
            </div>
          </div>
        </div>

        <div
          ref="idVideoReplyRecorderPopup"
          class="d-none align-items-center"
          style="
            background: rgba(0, 0, 0, 0.8);
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 99998;
          "
        >
          <div
            id="idVideoReplyRecorderPopupBody"
            ref="idVideoReplyRecorderPopupBody"
            style="text-align: center; margin: auto; position: relative"
          >
            <i
              @click="closeRecordModal"
              ref="idVideoRecorderClose"
              class="fas fa-times"
              style="
                cursor: pointer;
                font-size: 2rem;
                position: absolute;
                top: 1.8px;
                right: 5px;
                z-index: 999;
              "
            ></i>

            <div
              id="idReplyVideoRecorderWrapper"
              ref="idReplyVideoRecorderWrapper"
              class="d-flex flex-wrap justify-content-center"
              style="min-width: 500px"
            >
              <img
                ref="idReplyRecorderLoader"
                class="d-none"
                src="../../public/assets/images/loading.gif"
                style="width: 60px !important; height: 60px"
              />
              <div
                ref="idReplyRecorderWrapper"
                class="d-none"
                style="width: 100%"
                v-if="!device_permission_denied"
                v-html="videoJS"
              ></div>
              <span
                class="d-none"
                ref="idShowVideoSettingsOptions"
                @click="showVideoSettingsOptions()"
                style="
                  cursor: pointer;
                  position: absolute;
                  left: 5px;
                  top: 2px;
                  border-radius: 20px;
                "
              >
                <i class="fa fa-cog" aria-hidden="true"></i>
              </span>
              <button
                ref="idSendBtnContainer"
                @click="sendVideo"
                v-show="deviceReady"
                class="d-none justify-content-center align-items-center"
                style="
                  padding: 10px;
                  background: #ffd602 !important;
                  color: white !important;
                  width: 100px;
                  position: absolute;
                  bottom: 100px;
                  border-radius: 5px;
                "
              >
                <i class="fa fa-paper-plane" aria-hidden="true"></i>
                <span class="ml-1">Send</span>
              </button>
              <button
                class=""
                v-show="deviceReady"
                style="
                  width: 40px;
                  height: 40px;
                  position: absolute;
                  bottom: 50px;
                  background: white;
                  border-radius: 20px;
                "
                @click="togglerecord"
              >
                <span v-show="!isRecording">
                  <img
                    class="mb-1"
                    src="../../public/assets/images/record.png"
                    width="40"
                  />
                  <span
                    v-show="false"
                    id="idStartRecordingLabel"
                    ref="idStartRecordingLabel"
                    >Start Recording</span
                  >
                </span>
                <span v-show="isRecording">
                  <img
                    class="mb-1"
                    src="../../public/assets/images/stop-record.png"
                    width="40"
                  />
                  <span v-show="false">Stop Recording</span>
                </span>
              </button>
              <canvas
                ref="hiddenCanvas"
                class="d-none"
                width="640"
                height="480"
              ></canvas>
              <div
                class="p-3 alert alert-danger m-4 mt-5"
                v-if="device_permission_denied"
              >
                <img
                  class="mr-2"
                  src="../../public/assets/images/denied.png"
                  width="15"
                />
                <span
                  >Please allow device permission to record audio & video using
                  your mic and camera.</span
                >
              </div>
              <div
                id="idDeviceAlreadyUsedUnavailable"
                ref="idDeviceAlreadyUsedUnavailable"
                class="d-none p-3 alert alert-danger mt-3"
              >
                <img
                  class="mr-2"
                  src="../../public/assets/images/denied.png"
                  width="15"
                />
                <span>The device is unavailable or already being used.</span>
              </div>
            </div>
            <div
              v-if="showVideoSettings"
              class="d-flex flex-column p-3"
              style="
                z-index: 999;
                position: absolute;
                background: rgba(255, 255, 255, 0.8);
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
              "
            >
              <div
                style="
                  margin-top: 60px;
                  max-width: 400px;
                  margin-left: auto;
                  margin-right: auto;
                "
              >
                <select
                  class="form-control"
                  v-show="deviceReady"
                  v-model="vidsrc"
                  @change="changeVideoInput"
                  style="max-width: 400px"
                >
                  <option
                    v-for="(d, i) in videoDevices"
                    :value="d.deviceId"
                    :key="i"
                  >
                    {{ d.label }}
                  </option>
                </select>
                <select
                  class="form-control mt-2"
                  v-show="deviceReady"
                  v-model="audiosrc"
                  @change="changeAudioInput"
                  style="max-width: 400px"
                >
                  <option
                    v-for="(d, i) in audioDevices"
                    :value="d.deviceId"
                    :key="i"
                  >
                    {{ d.label }}
                  </option>
                </select>
                <button
                  @click="hideVideSettings()"
                  class="btn btn-sm text-white mt-4"
                  style="background: rgb(255, 214, 2) !important; width: 60px"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>

        <b-modal
          :title="submit_title"
          id="idReplySubmitStatusPopup"
          ref="idReplySubmitStatusPopup"
          hide-header
          no-close-on-esc
          no-close-on-backdrop
          centered
          hide-footer
          hide-header-close
        >
          <p class="pt-3 pb-3 text-center">
            <img
              class="mr-2"
              src="../../public/assets/images/loading.gif"
              width="23"
            />
            {{ submit_message }}
          </p>
        </b-modal>
        <div
          ref="idVideoRecordProgressPopup"
          class="d-none align-items-center"
          style="
            background: rgba(0, 0, 0, 0.5);
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 99999;
          "
        >
          <div
            class="d-flex align-items-center"
            style="
              text-align: center;
              margin: auto;
              background: white;
              min-width: 220px;
              max-width: 600px;
              max-height: 600px;
              padding: 20px;
            "
          >
            <img
              class="mr-1"
              src="../../public/assets/images/loading.gif"
              style="width: 60px !important; height: 60px"
            />
            <span ref="idVideoRecordProgressPopupText">Processing</span>
          </div>
        </div>
      </div>
    </template>
  </BaseComponent>
</template>

<script>
import Vue from "vue";
import { Buffer } from "buffer";
import { uuid } from "vue-uuid";
import { VEmojiPicker } from "v-emoji-picker";
import Avatar from "vue-avatar-component";
import VueMomentsAgo from "vue-moments-ago";

import moment from "moment";
import VideoPlayer from "@/components/players/VideoPlayer";

import VueMarkdown from "vue-markdown";
import BaseComponent from "@/components/BaseComponent";
import ThreadMessageListLoader from "@/components/loader/ThreadMessageListLoader";
import ThreadMessageDetailLoader from "@/components/loader/ThreadMessageDetailLoader";
// https://codesandbox.io/s/zw4olply3l?file=/src/components/HelloWorld.vue:640-644

import { BModal, VBModal } from "bootstrap-vue";

// eslint-disable-next-line no-unused-vars
import * as api from "@/services/api";
import globalVars from "../global";

// Video JS
import { Timeline, TimelineItem } from "vue-cute-timeline";
import "vue-cute-timeline/dist/index.css";

import "video.js/dist/video-js.min.css";
import videojs from "video.js";
import RecordRTC from "recordrtc";
import "videojs-record/dist/css/videojs.record.css";

// the following imports are only needed when you're recording
// audio-only using the videojs-wavesurfer plugin
import WaveSurfer from "wavesurfer.js";
import MicrophonePlugin from "wavesurfer.js/dist/plugin/wavesurfer.microphone.js";
WaveSurfer.microphone = MicrophonePlugin;

// register videojs-wavesurfer plugin
import "videojs-wavesurfer/dist/css/videojs.wavesurfer.css";

var isOpera = !!window.opera || navigator.userAgent.indexOf("OPR/") !== -1;
var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
var isEdge = /Edge/.test(navigator.userAgent);

let toastOptions = {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
};

let maxRecordingDuration = 5 * 60;
let maxRecordingSize = 1024 * 1024 * 100;
let maxTimeWaitAfterRecordingStopped = 60; // 10 seconds

const options = {
  controls: true,
  liveui: true,
  width: 500,
  // height: 500,
  fluid: true,
  bigPlayButton: true,
  PictureInPictureWindow: true,
  controlBar: {
    recordToggle: false,
    trimmer: false,
    fullscreenToggle: false,
    progressControl: true,
  },
  plugins: {
    record: {
      pip: true,
      audio: true,
      video: { frameRate: { ideal: 30, max: 30 } },
      //screen: true,
      maxLength: maxRecordingDuration /*seconds*/, // 5 minutes
      maxFileSize: maxRecordingSize,
      displayMilliseconds: false,
      debug: true,
      timeSlice: 2000,
      frameWidth: 320,
      frameHeight: 240,
    },
  },
};

function applyVideoWorkaround() {
  // use correct video mimetype for opera
  if (isOpera) {
    options.plugins.record.videoMimeType = "video/webm;codecs=vp8"; // or vp9
  } else {
    // options.plugins.record.videoMimeType = 'video/webm;codecs=H264';
    // options.plugins.record.videoMimeType = 'video/mp4';
  }
}

applyVideoWorkaround();

let player = null;
let recordedBlob = null;
let chatSocket = null;
let recordedBlobs = [];
let videoRecordUUID = null;
let totalUploadInitiated = 0;
let totalUploadCompleted = 0;
let fileUploadS3Instance = null;
let fileUploadS3FileKey = null;
let bucketName = globalVars.VIDEO_UPLOAD_BUCKET;
let bucketRegion = "us-east-1";
let IdentityPoolId = "us-east-1:e0bbf09b-7694-4843-9166-8abab853ba40";
let fileUploadS3SimpleUploadRef = null;

//
let currentChunkIndex = 0;
let s3UploadError = false;
let streamRecordCompleted = false;
let uploadInstances = [];
let recordingProcessStarted = false;
let fileUploadCompleted = false;
let recordingStopped = false;
let maxUploadTries = 3;
let binaryBlob = null;
let canUpload = false;
let videoRecordCompleted = false;

function resetGolabVariables() {
  player = null;
  recordedBlob = null;
  recordedBlobs = [];
  videoRecordUUID = null;
  totalUploadInitiated = 0;
  totalUploadCompleted = 0;
  fileUploadS3Instance = null;
  fileUploadS3FileKey = null;
  bucketName = globalVars.VIDEO_UPLOAD_BUCKET;
  bucketRegion = "us-east-1";
  IdentityPoolId = "us-east-1:e0bbf09b-7694-4843-9166-8abab853ba40";
  fileUploadS3SimpleUploadRef = null;

  //
  currentChunkIndex = 0;
  s3UploadError = false;
  streamRecordCompleted = false;
  uploadInstances = [];
  recordingProcessStarted = false;
  fileUploadCompleted = false;
  recordingStopped = false;
  maxUploadTries = 3;
  binaryBlob = null;
  canUpload = false;
  videoRecordCompleted = false;
}

function abortS3DirectUpload(uInstance) {
  return new Promise(function (resolve, reject) {
    try {
      uInstance.abort();
      resolve();
    } catch (e) {
      reject(e);
    }
  });
}

function initializeS3(
  bucketName,
  bucketRegion,
  IdentityPoolId,
  completedCallback,
  failedCallback
) {
  try {
    if (fileUploadS3Instance) {
      completedCallback(fileUploadS3Instance);
      return;
    }
    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    });

    let s3Instance = new AWS.S3({
      apiVersion: "2006-03-01",
      params: { Bucket: bucketName },
    });
    completedCallback(s3Instance);
  } catch (e) {
    failedCallback(e);
  }
}

function initializeS3Force(
  bucketName,
  bucketRegion,
  IdentityPoolId,
  completedCallback,
  failedCallback
) {
  try {
    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    });

    let s3Instance = new AWS.S3({
      apiVersion: "2006-03-01",
      params: { Bucket: bucketName },
    });
    completedCallback(s3Instance);
  } catch (e) {
    failedCallback(e);
  }
}

function uploadToS3(
  s3Instance,
  attempt,
  fileKey,
  blob,
  acl,
  contentEncoding,
  contentType,
  successCallback,
  failedCallback,
  progressCallback
) {
  if (s3UploadError) {
    failedCallback("ERROR");
    return;
  }
  var attempt = attempt || 1;
  let payload = {
    Key: fileKey,
    Body: blob,
    ACL: "public-read",
  };
  if (acl) {
    payload.ACL = acl;
  }
  if (contentEncoding) {
    payload.ContentEncoding = contentEncoding;
  }
  if (contentType) {
    payload.ContentType = contentType;
  }
  try {
    fileUploadS3SimpleUploadRef = s3Instance
      .upload(payload, function (err, data) {
        console.log(err, data);
        if (err) {
          console.log("Err");
          //videoRecordUploadCompletedCallback("FAILURE");
          //console.log(err);
          if (attempt < maxUploadTries) {
            console.log("Retrying uploadToS3 of attempt: #", attempt);
            uploadToS3(
              s3Instance,
              attempt + 1,
              fileKey,
              blob,
              acl,
              contentEncoding,
              contentType,
              successCallback,
              failedCallback,
              progressCallback
            );
          } else {
            console.log("Failed uploading file key: ", fileKey);
            failedCallback(err);
          }
        } else {
          console.log("Success");
          //videoRecordUploadCompletedCallback("SUCCESS");
          successCallback(data);
        }
      })
      .on("httpUploadProgress", function (progress) {
        var uploaded = parseInt((progress.loaded * 100) / progress.total);
        //alert(uploaded);
        if (progressCallback) {
          progressCallback(uploaded);
        }
      });
    uploadInstances.push(fileUploadS3SimpleUploadRef);
  } catch (e) {
    console.log("Gherr");
    console.log(e);
    failedCallback(e);
  }
}

function S3MultipartQueueUpload(qSize, progressCallback) {
  console.log("Inside multi part");
  if (!binaryBlob) {
    return false;
  }
  if (progressCallback) {
    progressCallback(null, null, "Initializing Upload");
  }
  var qSize = qSize || 4;
  var numOfWorkers = 0;
  var taskIndex = 0;

  var tasks = {};

  var partSize = 1024 * 1024 * 2;
  var totalParts = 0;
  for (
    var rangeStart = 0;
    rangeStart < binaryBlob.size;
    rangeStart += partSize
  ) {
    var end = Math.min(rangeStart + partSize, binaryBlob.size);
    tasks[totalParts] = {
      start: rangeStart,
      end: end,
      status: "p", //p, u, f = Pending, Uploaded, Failed
    };
    totalParts += 1;
  }

  console.log(tasks);

  var totalTasks = Object.keys(tasks).length;
  var totalSuccess = 0;

  let blobName = binaryBlob.name;
  var re = /(?:\.([^.]+))?$/;
  var fileExt = re.exec(blobName)[1];
  console.log("Starting");
  return new Promise((resolve, reject) => {
    initializeS3(
      bucketName,
      bucketRegion,
      IdentityPoolId,
      function (s3Instance) {
        fileUploadS3Instance = s3Instance;

        const handleSuccessResult = (index) => (result) => {
          console.log("handleSuccessResult " + index + " " + result);
          //console.log(result);
          totalSuccess += 1;
          let uploaded = parseInt((totalSuccess * 100) / totalTasks);
          if (progressCallback) {
            progressCallback(
              totalTasks,
              totalSuccess,
              "Uploading(" + uploaded + "%)"
            );
          }
          tasks[index].status = result;
          numOfWorkers--;
          getNextTask();
        };

        const handleFailureResult = (index) => (result) => {
          console.log("handleFailureResult " + index + " " + result);
          //console.log(result);
          tasks[index].status = result;
          //numOfWorkers--;
          //getNextTask();
          s3UploadError = true;
          reject();
        };

        const getNextTask = () => {
          console.log("getNextTask " + taskIndex);
          console.log("s3UploadError " + s3UploadError);
          console.log("numOfWorkers " + numOfWorkers);
          console.log("taskIndex " + taskIndex);
          console.log("totalTasks " + totalTasks);
          if (s3UploadError) {
            return;
          }

          if (numOfWorkers < qSize && taskIndex < totalTasks) {
            console.log("Entering " + taskIndex);
            var rangeStart = tasks[taskIndex].start;
            var end = tasks[taskIndex].end;
            console.log("rangeStart " + rangeStart);
            console.log("end " + end);
            fileUploadS3FileKey =
              "videos/" + videoRecordUUID + "/" + taskIndex + "." + fileExt;
            console.log("fileUploadS3FileKey " + fileUploadS3FileKey);
            let blobChunk = binaryBlob.slice(rangeStart, end);
            uploadToS3(
              fileUploadS3Instance,
              1,
              fileUploadS3FileKey,
              blobChunk,
              null,
              null,
              null,
              handleSuccessResult(taskIndex),
              handleFailureResult(taskIndex)
            );
            taskIndex++;
            numOfWorkers++;
            getNextTask();
          } else if (numOfWorkers === 0 && taskIndex === totalTasks) {
            resolve(tasks);
          }
        };
        getNextTask();
      }
    );
  });
}

function tryS3Upload(
  context = "record",
  completedCallback,
  progressCallback,
  skipUI = false,
  mUploadProgressCallback
) {
  if (!binaryBlob) {
    return false;
  }
  if (binaryBlob.size <= 100 * 1024 * 1024) {
    let blobChunk = binaryBlob;
    initializeS3(
      bucketName,
      bucketRegion,
      IdentityPoolId,
      function (s3Instance) {
        console.log("S3 initialized");
        console.log(s3Instance);
        let blobName = blobChunk.name;
        let fileExt = blobName.substr(
          blobName.lastIndexOf("."),
          blobName.length
        );
        fileUploadS3FileKey = "videos/" + videoRecordUUID + "/single" + fileExt;
        console.log("2");
        fileUploadS3Instance = s3Instance;
        uploadToS3(
          fileUploadS3Instance,
          1,
          fileUploadS3FileKey,
          blobChunk,
          null,
          null,
          null,
          function (d) {
            console.log("Uploaded: " + fileUploadS3FileKey);
            videoRecordCompleted = true;
            s3UploadError = false;
            if (completedCallback) {
              completedCallback("SUCCESS");
            }
          },
          function (err) {
            console.log("Upload error 2");
            s3UploadError = true;
            console.log("Error uploading: " + fileUploadS3FileKey);
            console.log(err);
            if (completedCallback) {
              completedCallback("FAILED");
            }
          },
          progressCallback
        );
      },
      function (err) {
        console.log("Herror");
        console.log(err);
        completedCallback("FAILED");
      }
    );
  } else {
    let mUploader = S3MultipartQueueUpload(4, mUploadProgressCallback);
    if (mUploader) {
      mUploader
        .then((d) => {
          videoRecordCompleted = true;
          fileUploadCompleted = true;
          s3UploadError = false;
          if (completedCallback) {
            completedCallback("SUCCESS");
          }
        })
        .catch((err) => {
          s3UploadError = true;
          fileUploadCompleted = false;
          console.log("Error uploading: " + fileUploadS3FileKey);
          if (completedCallback) {
            completedCallback("FAILED");
          }
        });
    } else {
      Vue.$toast.error("Please select a video to upload", toastOptions);
    }
  }
}

function cancelAllUploads() {
  return new Promise(function (resolve, reject) {
    for (var i = 0; i < uploadInstances.length; i++) {
      abortS3DirectUpload(uploadInstances[i]);
    }
    resolve();
  });
}

async function loadPlayer(app) {
  let recorderError = false;
  let that = app;

  let stream = await navigator.mediaDevices
    .getUserMedia({
      video: true,
      audio: true,
      width: { ideal: 9999 },
      height: { ideal: 9999 },
    })
    .catch(function (err) {
      recorderError = true;
    });

  if (recorderError) {
    return;
  }

  let { width, height } = stream.getTracks()[0].getSettings();

  options.plugins.record.video.width = width;
  options.plugins.record.video.height = height;
  options.plugins.record.frameWidth = width;
  options.plugins.record.frameHeight = height;
  // options.plugins.record.image = true;

  function initializeVideoJSPlayer(successCallback) {
    //console.log(document.getElementById("replyVideoPlayer"))
    player = videojs("replyVideoPlayer", options, function () {
      // print version information at startup
      var msg =
        "Using video.js " +
        videojs.VERSION +
        " with videojs-record " +
        videojs.getPluginVersion("record") +
        " and recordrtc " +
        RecordRTC.version;
      videojs.log(msg);
    });

    console.log(6);

    // error handling
    player.on("deviceError", function () {
      console.warn("device error:", player.deviceErrorCode);
    });

    player.on("error", function (element, error) {
      console.error(error);
    });

    // user clicked the record button and started recording
    player.on("startRecord", async function () {
      console.log("started recording!");
      //takethumbnail(myVideo_html5_api);
      app.hasstaticthumbnail = true;

      if (videoRecordUUID) {
        api
          .cancelVideoInstruction({ uuid: videoRecordUUID })
          .catch(function (err) {});
      }

      videoRecordUUID = uuid.v4();
      console.log("Started record");
      //console.log(videoRecordUUID);

      recordedBlobs = [];
      streamRecordCompleted = false;
      cancelAllUploads()
        .then(function () {
          uploadInstances = [];
          console.log("Aborted");
        })
        .catch(function (err) {});
      recordingProcessStarted = true;
      //disableRecordingOptions();
      //hideVideoReady();
      //hideVideoRecordError();

      fileUploadCompleted = false;

      totalUploadInitiated = 0;
      totalUploadCompleted = 0;
      recordingStopped = false;

      //console.log(vm)

      api
        .createVideoInstruction({ uuid: videoRecordUUID })
        .catch(function (err) {});

      currentChunkIndex = 0;
      s3UploadError = false;
      binaryBlob = null;

      that.$refs["idSendBtnContainer"].classList.remove("d-flex");
      that.$refs["idSendBtnContainer"].classList.add("d-none");
      that.$refs["idShowVideoSettingsOptions"].classList.add("d-none");
    });

    // user clicked the record button and started recording
    player.on("progressRecord", async function (r) {
      //console.log("progressRecord recording!");
      //takethumbnail(myVideo_html5_api);
      //console.log(r);
      //window.r = r;
      //console.log(player.recordedData);
      //console.log(player.recordedData.size());
    });
    player.on("timestamp", async function (current, all) {
      if (totalUploadInitiated - totalUploadCompleted >= 5) {
        s3UploadError = true;
        cancelAllUploads()
          .then(function () {
            uploadInstances = [];
            console.log("Aborted");
          })
          .catch(function (err) {});
        return;
      }

      try {
        console.log("s3UploadError " + s3UploadError);
        if (s3UploadError) {
          return;
        }
        //console.log("timestamp recording!");
        //takethumbnail(myVideo_html5_api);
        //console.log(player.recordedData);

        let blobChunk = player.recordedData[player.recordedData.length - 1];
        if (blobChunk) {
          currentChunkIndex += 1;
          initializeS3(
            bucketName,
            bucketRegion,
            IdentityPoolId,
            function (s3Instance) {
              let blobName = blobChunk.name;
              let fileExt = blobName.substr(
                blobName.lastIndexOf("."),
                blobName.length
              );
              fileUploadS3FileKey =
                "videos/" + videoRecordUUID + "/" + currentChunkIndex + fileExt;
              fileUploadS3Instance = s3Instance;
              totalUploadInitiated += 1;
              uploadToS3(
                fileUploadS3Instance,
                1,
                fileUploadS3FileKey,
                blobChunk,
                null,
                null,
                null,
                function (d) {
                  totalUploadCompleted += 1;
                  console.log("Uploaded chunk: " + currentChunkIndex);
                },
                function (err) {
                  s3UploadError = true;
                  console.log("Error uploading chunk: " + currentChunkIndex);
                }
              );
            }
          );
        }
        //console.log(player.recordedData.size);
      } catch (e) {
        s3UploadError = true;
        cancelAllUploads()
          .then(function () {
            uploadInstances = [];
            console.log("Aborted");
          })
          .catch(function (err) {});
      }
    });

    player.on("deviceError", async function (r) {
      //User doesn't allow the browser to access the webcam and/or microphone.. https://developer.mozilla.org/en-US/docs/Web/API/Navigator/getUserMedia#errorcallback
      console.log("Error", r);
    });

    // user completed recording and stream is available
    player.on("finishRecord", function () {
      that.$refs["idStartRecordingLabel"].textContent = "Re-record";
      that.$refs["idShowVideoSettingsOptions"].classList.remove("d-none");
      try {
        console.log("Finishing");
        binaryBlob = player.recordedData;
        that.$refs["idVideoRecordProgressPopupText"].textContent = "Processing";
        that.$refs["idVideoRecordProgressPopup"].classList.remove("d-none");
        that.$refs["idVideoRecordProgressPopup"].classList.add("d-flex");
        if (s3UploadError) {
          cancelAllUploads()
            .then(function () {
              uploadInstances = [];
              console.log("Aborted");
            })
            .catch(function (err) {});
          s3UploadError = false;
          fileUploadCompleted = false;
          let completedCallback = (status) => {
            if (status === "SUCCESS") {
              s3UploadError = false;
              that.$refs["idVideoRecordProgressPopup"].classList.remove(
                "d-flex"
              );
              that.$refs["idVideoRecordProgressPopup"].classList.add("d-none");
              that.$refs["idSendBtnContainer"].classList.remove("d-none");
              that.$refs["idSendBtnContainer"].classList.add("d-flex");
            } else {
              s3UploadError = true;
              that.$refs["idVideoRecordProgressPopup"].classList.remove(
                "d-flex"
              );
              that.$refs["idVideoRecordProgressPopup"].classList.add("d-none");
              that.$refs["idSendBtnContainer"].classList.remove("d-none");
              that.$refs["idSendBtnContainer"].classList.add("d-flex");
            }
          };
          let mUploadProgressCallback = (
            total,
            completed,
            progressStatusText
          ) => {
            if (progressStatusText) {
              let progress = parseInt((completed * 100) / total);
              that.$refs["idVideoRecordProgressPopupText"].textContent =
                "Processing(" + progress + "%)";
            }
          };
          let progressCallback = (progress) => {
            that.$refs["idVideoRecordProgressPopupText"].textContent =
              "Processing(" + progress + "%)";
          };
          tryS3Upload(
            "record",
            completedCallback,
            progressCallback,
            true,
            mUploadProgressCallback
          );
        } else {
          if (totalUploadInitiated === totalUploadCompleted) {
            s3UploadError = false;
            that.$refs["idVideoRecordProgressPopup"].classList.remove("d-flex");
            that.$refs["idVideoRecordProgressPopup"].classList.add("d-none");
            that.$refs["idSendBtnContainer"].classList.remove("d-none");
            that.$refs["idSendBtnContainer"].classList.add("d-flex");
          } else {
            console.log("All files not yet uploaded. Waiting to finish...");
            let timeWaited = 0;
            let timeToWait =
              maxTimeWaitAfterRecordingStopped +
              (totalUploadInitiated - totalUploadCompleted) * 3;
            (function waitForUploadFinish() {
              if (totalUploadInitiated === totalUploadCompleted) {
                s3UploadError = false;
                that.$refs["idVideoRecordProgressPopup"].classList.remove(
                  "d-flex"
                );
                that.$refs["idVideoRecordProgressPopup"].classList.add(
                  "d-none"
                );
                that.$refs["idSendBtnContainer"].classList.remove("d-none");
                that.$refs["idSendBtnContainer"].classList.add("d-flex");
                return;
              }
              if (timeWaited >= timeToWait) {
                console.log("Waiting is over");
                s3UploadError = true;
                cancelAllUploads()
                  .then(function () {
                    uploadInstances = [];
                    console.log("Aborted");
                  })
                  .catch(function (err) {});
                that.$refs["idVideoRecordProgressPopup"].classList.remove(
                  "d-flex"
                );
                that.$refs["idVideoRecordProgressPopup"].classList.add(
                  "d-none"
                );
                that.$refs["idSendBtnContainer"].classList.remove("d-none");
                that.$refs["idSendBtnContainer"].classList.add("d-flex");
                return;
              }
              let progressPerccentage = parseInt(
                (totalUploadCompleted * 100) / totalUploadInitiated
              );
              that.$refs["idVideoRecordProgressPopupText"].textContent =
                "Processing(" + progressPerccentage + "%)";
              timeWaited += 1;
              console.log("Waiting...");
              setTimeout(waitForUploadFinish, 1000);
            })();
          }
        }
      } catch (e) {
        //console.log("Errr")
        s3UploadError = true;
        that.$refs["idVideoRecordProgressPopup"].classList.remove("d-flex");
        that.$refs["idVideoRecordProgressPopup"].classList.add("d-none");
        that.$refs["idSendBtnContainer"].classList.remove("d-none");
        that.$refs["idSendBtnContainer"].classList.add("d-flex");
        cancelAllUploads()
          .then(function () {
            uploadInstances = [];
            console.log("Aborted");
          })
          .catch(function (err) {});
      }
    });

    //player.trimmer();

    //player.trimmer();

    if (typeof successCallback !== "undefined") {
      successCallback();
    }
  }

  initializeVideoJSPlayer();
}

function firstvideodevice(devices) {
  for (let d of devices) {
    if (d.kind === "videoinput") {
      return d.deviceId;
    }
  }
  return null;
}

function firstaudiodevice(devices) {
  for (let d of devices) {
    if (d.kind === "audioinput") {
      return d.deviceId;
    }
  }
  return null;
}

function getInitialData() {
  return {
    copyText: "Click on the icon for copy the link",
    threadhistory: [],
    sent_at: null,
    delivered_at: null,
    opened_at: null,

    page: 1,
    showDialog: false,
    loading: false,
    isEnable: false,
    show_detail_sidebar: false,
    current_item: null,
    selected_item: null,
    message_threads: [],
    current_user_display_name: "",
    // Message detail
    threadTitle: "",
    threadUsers: "",
    threadAnalytics: {},
    threadMessages: [],
    thread_unique_id: null,
    reply_text: "",
    video_text_message: "",

    vidsrc: null,
    audiosrc: null,
    devices: [],
    isRecording: false,
    deviceReady: false,

    submit_in_progress: false,
    video_upload_error: false,
    total_page: 0,
    page_start: 0,
    page_end: 0,
    total_count: 0,
    prev_page: 0,
    current_page: 0,
    next_page: 0,
    deleteId: -1,
    isNextLoading: false,
    isPreviousLoading: false,
    currentIndex: -1,
    isEnableMoreAction: false,
    submit_title: "",
    submit_message: "",
    emoji_button_icon: "😃",
    from_email_detail: null,
    disable_sending: false,
    chat_reconnect_in: 10,
    detail_content: "",
    poster_image: "",
    replyVideoPlaybackReady: false,
    msg_body: "",
    detail_type: false,
    html_content: "HTML",
    first_message: "",
    video_content: "VIDEO",
    urlLink: "",
    num_cta_click: "",
    msg_loading: false,
    doCopyText: false,
    device_permission_denied: false,
    device_error: false,
    device_status: "loading",
    showVideoReplyModal: false,
    renderReplyPopup: false,
    videoJS:
      '<video id="replyVideoPlayer" controls playsinline preload="auto" class="video-js vjs-default-skin"></video>',
    recordingCompleted: false,
    startRecordingLabel: "Start Recording",
    showVideoSettings: false,
    chatDisconnectedMessage: " ",
    sendingStatus: "",
    templateInfo: "",
    detail_loading: false,
    ld: 30,
    isMobile: window.innerWidth <= 767,
  };
}

export default {
  name: "MessagesSentComponent",
  data() {
    return getInitialData();
  },
  components: {
    BaseComponent,
    VueMarkdown,
    BModal,
    VEmojiPicker,
    Timeline,
    TimelineItem,
    VideoPlayer,
    Avatar,
    VueMomentsAgo,
    ThreadMessageListLoader,
    ThreadMessageDetailLoader,
  },
  directives: { "b-modal": VBModal },
  methods: {
    handleFocus() {
      //this.showDialog = true;
    },
    handleFocusOut() {
      this.showDialog = false;
    },
    showVideoSettingsOptions() {
      this.showVideoSettings = true;
    },
    hideVideSettings() {
      this.showVideoSettings = false;
    },
    resetRecordingVariables() {
      this.vidsrc = null;
      this.audiosrcv;
      this.devices = [];
      this.isRecording = false;
      this.deviceReady = false;
      this.video_upload_error = false;
      this.disable_sending = false;
      this.device_permission_denied = false;
      this.device_error = false;
      this.device_status = "loading";
    },
    loadRecorder(app) {
      let that = app;
      //console.log("loadRecorder with: ");
      //console.log(that);
      that.$refs["idSendBtnContainer"].classList.remove("d-flex");
      that.$refs["idSendBtnContainer"].classList.add("d-none");
      that.$refs["idReplyRecorderLoader"].classList.remove("d-none");
      that.$refs["idDeviceAlreadyUsedUnavailable"].classList.add("d-none");
      that.$refs["idShowVideoSettingsOptions"].classList.add("d-none");
      loadPlayer(that)
        .then(function () {
          that.device_status = "ready";
          player.on("deviceReady", function () {
            if (that.devices.length === 0) {
              player.record().enumerateDevices();
            }
          });
          player.on("enumerateReady", function () {
            that.devices = player.record().devices;
            //console.log("Devices: ");
            //console.log(that.devices);
            that.deviceReady = true;
            if (!that.vidsrc) {
              that.vidsrc = firstvideodevice(that.devices);
            }
            if (!that.audiosrc) {
              that.audiosrc = firstaudiodevice(that.devices);
            }
          });
          that.deviceReady = true;
          player.record().getDevice();
          that.$refs["idReplyRecorderLoader"].classList.add("d-none");
          that.$refs["idDeviceAlreadyUsedUnavailable"].classList.add("d-none");
          that.$refs["idReplyRecorderWrapper"].classList.remove("d-none");
          that.$refs["idShowVideoSettingsOptions"].classList.remove("d-none");
        })
        .catch(function (err) {
          console.log(err);
          that.$refs["idReplyRecorderWrapper"].classList.add("d-none");
          that.$refs["idShowVideoSettingsOptions"].classList.add("d-none");
          that.$refs["idDeviceAlreadyUsedUnavailable"].classList.add("d-none");
          that.$refs["idReplyRecorderLoader"].classList.add("d-none");
          if (err.message === "Permission denied") {
            that.$refs["idDeviceAlreadyUsedUnavailable"].classList.add(
              "d-none"
            );
            that.device_permission_denied = true;
          } else {
            that.device_permission_denied = false;
            that.$refs["idDeviceAlreadyUsedUnavailable"].classList.remove(
              "d-none"
            );
          }
          that.device_status = "failed";
        });
    },
    handleRefresh() {
      this.loading = true;
      const that = this;

      that.msg_loading = true;

      api
        .getthreads({
          page: this.$route.query.page || 1,
          fields:
            "unique_id,subject,from_email,to_emails,last_message_time,thread_analytics,sending_status",
        })
        .then(function (res) {
          that.msg_loading = false;
          if (res.results) {
            that.message_threads = res.results;
            that.total_page = res.total_page;
            that.page_start = res.page_start;
            that.page_end = res.page_end;
            that.total_count = res.count;
            that.prev_page = res.prev_page;
            that.current_page = res.current_page;
            that.next_page = res.next_page;
            that.checkAndLoadChatDetail();
          } else {
            that.message_threads = [];
            that.total_page = 0;
            that.page_start = 0;
            that.page_end = 0;
            that.total_count = 0;
            that.prev_page = 0;
            that.current_page = 0;
            that.next_page = 0;
            that.checkAndLoadChatDetail();
          }
        })
        .catch(function (err) {
          that.msg_loading = false;
        });
    },
    ldFilterChange() {
      //alert(this.ld);
      this.loading = true;
      const that = this;

      that.msg_loading = true;

      api
        .getthreads({
          page: this.$route.query.page || 1,
          ld: that.ld,
          fields:
            "unique_id,subject,from_email,to_emails,last_message_time,thread_analytics,sending_status",
        })
        .then(function (res) {
          that.msg_loading = false;
          if (res.results) {
            that.message_threads = res.results;
            that.total_page = res.total_page;
            that.page_start = res.page_start;
            that.page_end = res.page_end;
            that.total_count = res.count;
            that.prev_page = res.prev_page;
            that.current_page = res.current_page;
            that.next_page = res.next_page;
            that.checkAndLoadChatDetail();
          } else {
            that.message_threads = [];
            that.total_page = 0;
            that.page_start = 0;
            that.page_end = 0;
            that.total_count = 0;
            that.prev_page = 0;
            that.current_page = 0;
            that.next_page = 0;
            that.checkAndLoadChatDetail();
          }
        })
        .catch(function (err) {
          that.msg_loading = false;
        });
    },
    fmtMSS(s) {
      return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
    },

    showCancelConfirmation() {
      this.$refs["idTemplateCancelConfirmationDialog"].show();
    },
    hideCancelConfirmation() {
      this.$refs["idTemplateCancelConfirmationDialog"].hide();
    },
    showInboxConfirmation() {
      this.$refs["idInboxConfirmationDialog"].show();
    },
    hideInboxCancelConfirmation() {
      this.$refs["idInboxConfirmationDialog"].hide();
    },
    showWatchCountConfirmation() {
      this.$refs["idsWatchCountConfirmationDialog"].show();
    },
    hideWatchCountConfirmation() {
      this.$refs["idsWatchCountConfirmationDialog"].hide();
    },
    showCTAConfirmation() {
      this.$refs["idsCTAConfirmationDialog"].show();
    },
    hideCTAConfirmation() {
      this.$refs["idsCTAConfirmationDialog"].hide();
    },
    showGroupConfirmation() {
      this.isEnable = !this.isEnable;
      this.urlLink =
        "https://app.kimpod.com/landing?v=" + this.thread_unique_id;

      this.$refs["idTemplateGrouponfirmationDialog"].show();
    },
    hideGroupConfirmation() {
      this.$refs["idTemplateGrouponfirmationDialog"].hide();
    },

    showDetailPopup(item) {
      this.replyVideoPlaybackReady = false;
      this.replyVideoPlaybackReady = true;
      this.detail_content = item.video_url;
      this.detail_type = item.detail_type;
      this.poster_image = item.poster_image;
      this.msg_body = item.msg_body;
      this.first_message = item.first_message;
      this.$refs["idRecordingDetailPopup"].classList.remove("d-none");
    },
    hideDetailPopup() {
      this.$refs["idRecordingDetailPopup"].classList.add("d-none");
      this.replyVideoPlaybackReady = false;
    },
    showReplySubmitPopup() {
      this.$refs["idReplySubmitStatusPopup"].show();
    },
    hideReplySubmitPopup() {
      this.$refs["idReplySubmitStatusPopup"].hide();
    },
    submit_ok_button_click_handler: function () {},
    currentPagination: function (page_number) {
      let that = this;
      that.page_no = page_number;

      that.isNextLoading = true;
      this.$router.push({
        path: "/messages",
        query: { page: page_number },
      });
      api
        .getthreads({
          page: page_number,
          fields:
            "unique_id,subject,from_email,to_emails,last_message_time,thread_analytics,sending_status",
        })
        .then(function (res) {
          that.isNextLoading = false;

          if (res.results) {
            that.message_threads = res.results;
            that.total_page = res.total_page;
            that.page_start = res.page_start;
            that.page_end = res.page_end;
            that.total_count = res.count;
            that.prev_page = res.prev_page;
            that.current_page = res.current_page;
            that.next_page = res.next_page;
            that.checkAndLoadChatDetail();
          } else {
            that.message_threads = [];
            that.total_page = 0;
            that.page_start = 0;
            that.page_end = 0;
            that.total_count = 0;
            that.prev_page = 0;
            that.current_page = 0;
            that.next_page = 0;
            that.checkAndLoadChatDetail();
          }
        });
    },
    doCopy: function () {
      this.doCopyText = !this.doCopyText;
      this.copyText = "Copied";
      this.$refs.clone.focus();
      document.execCommand("copy");
    },
    previousPagination: function (page_number) {
      let that = this;
      that.isPreviousLoading = true;

      this.$router.push({
        path: "/messages",
        query: { page: page_number },
      });
      api
        .getthreads({
          page: page_number,
          fields:
            "unique_id,subject,from_email,to_emails,last_message_time,thread_analytics",
        })
        .then(function (res) {
          that.isPreviousLoading = false;
          if (res.results) {
            that.message_threads = res.results;
            that.total_page = res.total_page;
            that.page_start = res.page_start;
            that.page_end = res.page_end;
            that.total_count = res.count;
            that.prev_page = res.prev_page;
            that.current_page = res.current_page;
            that.next_page = res.next_page;
            that.checkAndLoadChatDetail();
          } else {
            that.message_threads = [];
            that.total_page = 0;
            that.page_start = 0;
            that.page_end = 0;
            that.total_count = 0;
            that.prev_page = 0;
            that.current_page = 0;
            that.next_page = 0;
            that.checkAndLoadChatDetail();
          }
        });
    },
    toogleDialogEmoji() {
      document.getElementById("Categories").style.background = "white";

      let collection = document.getElementsByClassName("container-emoji");
      collection[0].style.background = "white";

      collection[0].style.height = "260px";
      let gridEmojis = document.getElementsByClassName("grid-emojis");
      gridEmojis[0].style.display = "inherit";

      //console.log("Toogle!");
      this.showDialog = !this.showDialog;
    },

    onSelectEmoji(emoji) {
      this.reply_text += emoji.data || "";
      this.emoji_button_icon = emoji.data || "😀";
      this.toogleDialogEmoji();
    },
    toogleDialogEmojiDisable() {
      this.showDialog = false;
    },
    showVideoModal() {
      //this.$refs["videoReplyModal"].show();
      //this.showVideoReplyModal = true;
      this.$refs["idVideoReplyRecorderPopup"].classList.remove("d-none");
      this.$refs["idVideoReplyRecorderPopup"].classList.add("d-flex");
    },
    hideVideoModal() {
      //this.$refs["videoReplyModal"].hide();
      //this.showVideoReplyModal = false;
      this.$refs["idVideoReplyRecorderPopup"].classList.remove("d-flex");
      this.$refs["idVideoReplyRecorderPopup"].classList.add("d-none");
    },
    captureThumb() {
      var videoPlayer = document.getElementById("replyVideoPlayer_html5_api");
      var canvas = this.$refs["hiddenCanvas"];
      var context = canvas.getContext("2d");
      var w, h, ratio;
      ratio = videoPlayer.clientWidth / videoPlayer.clientHeight;
      w = videoPlayer.clientWidth - 100;
      h = parseInt(w / ratio, 10);
      canvas.width = w;
      canvas.height = h;
      context.fillRect(0, 0, w, h);
      context.drawImage(videoPlayer, 0, 0, w, h);
    },
    uploadCanvasThumb() {
      var canvas = this.$refs["hiddenCanvas"];
      canvas.toBlob((blob) => {
        if (blob === null) return;
        let payload = {
          Key: "videos/replies/" + videoRecordUUID + ".jpg",
          Body: blob,
          ACL: "public-read",
          ContentType: "image/jpeg",
        };
        initializeS3Force(
          "kimpod",
          bucketRegion,
          IdentityPoolId,
          function (s3Instance) {
            s3Instance.upload(payload, function (err, data) {
              console.log(err, data);
              if (err) {
                console.log("Err");
              } else {
                console.log("Success");
              }
            });
          }
        );
      }, "image/jpeg");
    },
    sendVideo: function () {
      let that = this;

      that.captureThumb();

      that.uploadCanvasThumb();

      function uploadCompletedCallback(data) {
        let payload = {
          thread_unique_id: that.thread_unique_id,
          sender: that.from_email_detail,
          messageUUID: videoRecordUUID,
          type: "REC_VID",
          source: "INBOX",
          token: localStorage.getItem("sve_token"),
        };

        console.log("Sending message");
        console.log(payload);

        if (that.postMessage(payload)) {
          fileUploadCompleted = true;
          s3UploadError = false;
          //document.getElementById("idVideoRecordProgressPopup").classList.remove("d-flex");
          //document.getElementById("idVideoRecordProgressPopup").classList.add("d-none");
          that.$refs["idVideoRecordProgressPopup"].classList.remove("d-flex");
          that.$refs["idVideoRecordProgressPopup"].classList.add("d-none");
          that.closeRecordModal();
          Vue.$toast.success("Sent successfully", toastOptions);
        } else {
          Vue.$toast.error("Couldn't send the message", toastOptions);
          return;
        }
      }

      function uploadFailedCallback(err) {
        s3UploadError = true;
        that.$refs["idVideoRecordProgressPopup"].classList.remove("d-flex");
        that.$refs["idVideoRecordProgressPopup"].classList.add("d-none");
        Vue.$toast.error("Could not send", toastOptions);
      }

      if (!s3UploadError) {
        that.$refs["idVideoRecordProgressPopupText"].textContent = "Sending";
        that.$refs["idVideoRecordProgressPopup"].classList.remove("d-none");
        that.$refs["idVideoRecordProgressPopup"].classList.add("d-flex");
        var instruction = {
          key: "videos/" + videoRecordUUID,
          fileId: videoRecordUUID,
          context: "reply-record",
        };
        var buf = Buffer.from(JSON.stringify(instruction));

        initializeS3(
          bucketName,
          bucketRegion,
          IdentityPoolId,
          function (s3Instance) {
            fileUploadS3FileKey = "videos/" + videoRecordUUID + "/index.json";
            fileUploadS3Instance = s3Instance;

            uploadToS3(
              fileUploadS3Instance,
              1,
              fileUploadS3FileKey,
              buf,
              null,
              null,
              null,
              function (d) {
                uploadCompletedCallback(d);
              },
              function (err) {
                uploadFailedCallback(err);
              }
            );
          }
        );
      } else {
        cancelAllUploads()
          .then(function () {
            uploadInstances = [];
            console.log("Aborted");
          })
          .catch(function (err) {});
        s3UploadError = false;
        fileUploadCompleted = false;

        that.$refs["idVideoRecordProgressPopupText"].textContent = "Sending";
        that.$refs["idVideoRecordProgressPopup"].classList.remove("d-none");
        that.$refs["idVideoRecordProgressPopup"].classList.add("d-flex");
        let completedCallback = (status) => {
          if (status === "SUCCESS") {
            // Now finish the uploading
            that.$refs["idVideoRecordProgressPopupText"].textContent =
              "Sending";
            var instruction = {
              key: "videos/" + videoRecordUUID,
              fileId: videoRecordUUID,
              context: "reply-record",
            };
            var buf = Buffer.from(JSON.stringify(instruction));

            initializeS3(
              bucketName,
              bucketRegion,
              IdentityPoolId,
              function (s3Instance) {
                fileUploadS3FileKey =
                  "videos/" + videoRecordUUID + "/index.json";
                fileUploadS3Instance = s3Instance;

                uploadToS3(
                  fileUploadS3Instance,
                  1,
                  fileUploadS3FileKey,
                  buf,
                  null,
                  null,
                  null,
                  function (d) {
                    uploadCompletedCallback(d);
                  },
                  function (err) {
                    uploadFailedCallback(err);
                  }
                );
              }
            );
          } else {
            s3UploadError = true;
            that.$refs["idVideoRecordProgressPopup"].classList.remove("d-flex");
            that.$refs["idVideoRecordProgressPopup"].classList.add("d-none");
            Vue.$toast.error("Error in upload", toastOptions);
          }
        };
        let mUploadProgressCallback = (
          total,
          completed,
          progressStatusText
        ) => {
          if (progressStatusText) {
            let progress = parseInt((completed * 100) / total);
            that.$refs["idVideoRecordProgressPopupText"].textContent =
              "Processing(" + progress + "%)";
          }
        };
        let progressCallback = (progress) => {
          that.$refs["idVideoRecordProgressPopupText"].textContent =
            "Processing(" + progress + "%)";
        };
        tryS3Upload(
          "record",
          completedCallback,
          progressCallback,
          true,
          mUploadProgressCallback
        );
      }
    },
    postMessage: function (payload) {
      if (chatSocket) {
        chatSocket.send(JSON.stringify(payload));
        return true;
      }
      return false;
    },
    sendReply: function () {
      let that = this;
      if (!that.reply_text) {
        if (document.getElementById("idReplyTextbox")) {
          document.getElementById("idReplyTextbox").focus();
        }

        return false;
      }
      if (!that.from_email_detail) {
        that.disable_sending = false;
        return false;
      }

      that.disable_sending = false;
      let payload = {
        thread_unique_id: that.thread_unique_id,
        sender: that.from_email_detail,
        message: that.reply_text,
        token: localStorage.getItem("sve_token"),
      };
      that.reply_text = "";
      if (document.getElementById("idReplyTextbox")) {
        document.getElementById("idReplyTextbox").focus();
      }
      that.postMessage(payload);
    },
    changeVideoInput: function (e) {
      const deviceId = e.target.value;
      document.getElementById("idSendBtnContainer").classList.remove("d-flex");
      document.getElementById("idSendBtnContainer").classList.add("d-none");
      try {
        // change video input device
        player.record().setVideoInput(deviceId);
      } catch (error) {
        console.warn("error in changing video input", error);
        // jump back to first output device in the list as it's the default
        e.target.selectedIndex = 0;
      }
    },
    changeAudioInput: function (e) {
      const deviceId = e.target.value;
      try {
        // change video input device
        player.record().setAudioInput(deviceId);
      } catch (error) {
        console.warn("error in changing audio input", error);
        // jump back to first output device in the list as it's the default
        e.target.selectedIndex = 0;
      }
    },
    togglerecord: function (e) {
      e.preventDefault();
      if (player.record().isRecording()) {
        player.record().stop();
      } else {
        player.record().start();
      }
      this.isRecording = player.record().isRecording();
    },

    closeRecordModal: function () {
      if (player) {
        player.dispose();
      }
      if (videojs.getPlayers()["replyVideoPlayer"]) {
        delete videojs.getPlayers()["replyVideoPlayer"];
      }
      this.device_permission_denied = true;
      this.hideVideoModal();
      this.isRecording = false;
      //this.renderReplyPopup = false;
      //resetGolabVariables();
      //this.resetRecordingVariables()
      //alert(this.device_permission_denied);
      this.$refs["idSendBtnContainer"].classList.remove("d-flex");
      this.$refs["idSendBtnContainer"].classList.add("d-none");
    },
    showVideoRecordModal: async function () {
      //this.$modal.show('uploadVideoModal');
      //this.renderReplyPopup = true;
      this.device_permission_denied = false;
      //this.resetRecordingVariables();
      this.showVideoModal();
      // Video JS
      let that = this;
      that.deviceReady = false;
      that.isRecording = false;
      //autosize(document.querySelector('#idMessageField'));

      resetGolabVariables();

      //console.log(this.data)
      that.$refs["idReplyRecorderLoader"].classList.remove("d-none");
      that.$refs["idShowVideoSettingsOptions"].classList.add("d-none");
      that.$refs["idVideoRecorderClose"].classList.add("d-none");
      this.$refs["idSendBtnContainer"].classList.remove("d-flex");
      that.$refs["idSendBtnContainer"].classList.add("d-none");
      that.$refs["idDeviceAlreadyUsedUnavailable"].classList.add("d-none");

      let recorderError = false;

      await navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: true,
          width: { ideal: 9999 },
          height: { ideal: 9999 },
        })
        .catch(function (err) {
          recorderError = true;
          that.$refs["idVideoRecorderClose"].classList.remove("d-none");
          that.$refs["idShowVideoSettingsOptions"].classList.add("d-none");
          that.$refs["idReplyRecorderLoader"].classList.add("d-none");
          if (err.message === "Permission denied") {
            that.device_permission_denied = true;
            that.$refs["idDeviceAlreadyUsedUnavailable"].classList.add(
              "d-none"
            );
          } else {
            that.device_permission_denied = false;
            that.$refs["idDeviceAlreadyUsedUnavailable"].classList.remove(
              "d-none"
            );
          }
        });

      if (recorderError) {
        return;
      }

      that.$refs["idVideoRecorderClose"].classList.remove("d-none");

      that.loadRecorder(that);
    },
    toogle_detail_sidebar: function () {
      this.show_detail_sidebar = !this.show_detail_sidebar;
      this.toggle_html_css();
      if (!this.show_detail_sidebar) {
        history.replaceState(
          {},
          document.title,
          window.location.toString().split("#")[0]
        );
      }
      this.disconnectChat();
    },
    toggle_html_css: function () {
      /*if (this.show_detail_sidebar) {
        document.getElementsByTagName("html")[0].style.overflow = "hidden";
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
        setTimeout(function () {
          var element = document.getElementById("idReplyTextbox");
          // smooth scroll to element and align it at the bottom
          element.scrollIntoView();
        }, 1000);
      } else {
        document.getElementsByTagName("html")[0].style.overflow = "auto";
        document.getElementsByTagName("body")[0].style.overflow = "auto";
      }*/
    },
    show_chat_sidebar: function () {
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
      var element = document.getElementById("idReplyTextbox");
      // smooth scroll to element and align it at the bottom
      element.scrollIntoView();
    },
    hide_chat_sidebar: function () {
      document.getElementsByTagName("html")[0].style.overflow = "auto";
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    },
    on_mouse_hover: function () {},
    fetchChatDetail: function () {
      let that = this;
      return new Promise(function (resolve, reject) {
        if (!that.thread_unique_id) {
          resolve(false);
        } else {
          api
            .getThreadDetail(that.thread_unique_id)
            .then(function (r) {
              console.log(r);
              that.threadTitle = r.subject;
              that.threadhistory = r.thread_analytics.history;
              that.sendingStatus = r.sending_status;
              that.threadUsers = r.thread_users.split(",");
              that.threadAnalytics = r.thread_analytics;
              that.threadMessages = r.messages;
              that.from_email_detail = r.from_email;
              that.templateInfo = r.template_info;
              //alert(that.from_email_detail);
              //alert(r.from_email);

              /*setTimeout(function () {
                document.getElementById(
                  "idFixedSideBarInboxDetail"
                ).scrollTop = 1000000;
                document.getElementById("idReplyTextbox").focus();
                console.log("Done");
              }, 200);*/

              resolve(r);
            })
            .catch(function (err) {
              //console.log(err);
              reject(err);
            });
        }
      });
    },
    on_mouse_click: function (i) {
      //$("html", "body").addClass("noscrollbar");
      this.selected_item = i;
      this.show_detail_sidebar = false;
      this.detail_loading = true;
      let thread = this.message_threads[i];
      window.location.href = "#thread/" + thread.unique_id;
      this.thread_unique_id = thread.unique_id;
      //let thread = this.message_threads[i];
      //window.location.href = "/thread/" + thread.unique_id;
      let that = this;

      that
        .fetchChatDetail()
        .then(function (response) {
          that.show_detail_sidebar = true;
          that.detail_loading = false;
          if (response) {
            that.disconnectChat();
            that.toggle_html_css();
            that.connectChat(thread.unique_id);
          } else {
            that.disconnectChat();
          }
        })
        .catch(function (err) {
          that.show_detail_sidebar = true;
          that.detail_loading = false;
          that.disconnectChat();
        });
    },
    connectChat: function (thread_uuid) {
      let that = this;
      console.log("Connecting websocket");
      //var proto = window.location.protocol == "http:" ? "ws://" : "wss://";
      chatSocket = new WebSocket(
        "wss://api.kimpod.com/ws/chat/" + thread_uuid + "/"
        //"ws://localhost:8002/ws/chat/" + thread_uuid + "/"
      );
      chatSocket.onmessage = function (e) {
        console.log("Inside");
        console.log(e.data);
        var data = JSON.parse(e.data);
        data = data.message;
        //console.log(data);
        /*that
          .fetchChatDetail()
          .then(function (response) {
            that.toggle_html_css();
          })
          .catch(function (err) {});
          */
        if (data.sender === that.from_email_detail) {
          data.sent_by_me = true;
        } else {
          data.sent_by_me = false;
        }
        let exists = false;
        for (var i = 0; i < that.threadMessages.length; i++) {
          if (that.threadMessages[i].id === data.id) {
            exists = true;
            break;
          }
        }
        if (!exists) {
          that.threadMessages.push(data);
        }
      };

      chatSocket.onclose = function (e) {
        console.error("Chat socket closed unexpectedly");
        //console.log(e);
        that.disable_sending = true;
        that.chatDisconnectedMessage =
          "The chat is disconnected. To continue using chat, please refresh the page. If you still experience the same issue feel free to contact at support@kimpod.com.";
        setTimeout(that.connectChat, that.chat_reconnect_in, thread_uuid);
        if (that.chat_reconnect_in < 60000) {
          that.chat_reconnect_in += 1000;
        }
      };
      chatSocket.onopen = function (e) {
        console.log("e", e);
        that.disable_sending = false;
        that.chatDisconnectedMessage = "";
      };
    },
    disconnectChat: function () {
      console.log("Chat disconnected");
      if (chatSocket) {
        chatSocket.close();
      }
    },
    getUserTZ: function () {
      return this.$moment.tz.guess();
    },
    checkAndLoadChatDetail: function () {
      let that = this;
      // Check if there is a thread detail URL. If so load that detail.
      if (window.location.toString().indexOf("#") != -1) {
        let hashPart = window.location.toString().split("#")[1];
        hashPart = hashPart.replace("thread/", "");
        if (hashPart.indexOf("/") != -1) {
          hashPart = hashPart.substring(0, hashPart.indexOf("/"));
        } else if (hashPart.indexOf("?") != -1) {
          hashPart = hashPart.substring(0, hashPart.indexOf("?"));
        }
        if (hashPart) {
          that.thread_unique_id = hashPart;
          that.show_detail_sidebar = true;
          that.msg_loading = true;

          for(var k = 0; k < that.message_threads.length; k++) {
            if(that.message_threads[k].unique_id === that.thread_unique_id) {
              that.selected_item = k;
              break;
            }
          }

          api
            .getThreadDetail(hashPart)
            .then(function (r) {
              that.threadTitle = r.subject;
              that.threadhistory = r.thread_analytics.history;
              console.log(
                " r.thread_analytics.history;",
                r.thread_analytics.history
              );

              that.sent_at = moment.utc(r.thread_analytics.sent_at).local().format('MM/DD/YYYY HH:mm'); 
              that.opened_at = moment.utc(r.thread_analytics.opened_at).local().format('MM/DD/YYYY HH:mm');
              that.delivered_at = moment.utc(r.thread_analytics.delivered_at).local().format('MM/DD/YYYY HH:mm'); 

              that.threadUsers = r.thread_users.split(",");

              that.threadAnalytics = r.thread_analytics;
              that.threadMessages = r.messages;
              that.from_email_detail = r.from_email;
              that.msg_loading = false;
              that.templateInfo = r.template_info;

              /*setTimeout(function () {
                document.getElementById(
                  "idFixedSideBarInboxDetail"
                ).scrollTop = 1000;
                document.getElementById("idReplyTextbox").focus();
                console.log("Done");
              }, 200);*/



            })
            .catch(function (err) {
              //console.log(err);
            });
          that.toggle_html_css();
          that.connectChat(that.thread_unique_id);
        } else {
          that.thread_unique_id = null;
        }
      } else {
        that.thread_unique_id = null;
        if (that.message_threads.length > 0) {
          that.on_mouse_click(0);
        }
      }
    },
    initializeCustomSelect: function () {
      //alert(1)
      let that = this;
      var x, i, j, l, ll, selElmnt, a, b, c;
      /* Look for any elements with the class "custom-select": */
      x = document.getElementsByClassName("custom-select");
      l = x.length;
      for (i = 0; i < l; i++) {
        selElmnt = x[i].getElementsByTagName("select")[0];
        ll = selElmnt.length;
        /* For each element, create a new DIV that will act as the selected item: */
        a = document.createElement("DIV");
        a.setAttribute("class", "select-selected");
        a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
        x[i].appendChild(a);
        /* For each element, create a new DIV that will contain the option list: */
        b = document.createElement("DIV");
        b.setAttribute("class", "select-items select-hide");
        for (j = 1; j < ll; j++) {
          /* For each option in the original select element,
          create a new DIV that will act as an option item: */
          c = document.createElement("DIV");
          c.innerHTML = selElmnt.options[j].innerHTML;
          c.addEventListener("click", function (e) {
            //alert(1);
            /* When an item is clicked, update the original select box,
              and the selected item: */
            var y, i, k, s, h, sl, yl;
            s = this.parentNode.parentNode.getElementsByTagName("select")[0];
            sl = s.length;
            h = this.parentNode.previousSibling;
            for (i = 0; i < sl; i++) {
              if (s.options[i].innerHTML == this.innerHTML) {
                s.selectedIndex = i;
                //console.log(that.ld);
                h.innerHTML = this.innerHTML;
                y = this.parentNode.getElementsByClassName("same-as-selected");
                yl = y.length;
                for (k = 0; k < yl; k++) {
                  y[k].removeAttribute("class");
                }
                this.setAttribute("class", "same-as-selected");
                break;
              }
            }
            h.click();
          });
          b.appendChild(c);
        }
        x[i].appendChild(b);
        a.addEventListener("click", function (e) {
          /* When the select box is clicked, close any other select boxes,
          and open/close the current select box: */
          e.stopPropagation();
          closeAllSelect(this);
          this.nextSibling.classList.toggle("select-hide");
          this.classList.toggle("select-arrow-active");
        });
      }

      function closeAllSelect(elmnt) {
        /* A function that will close all select boxes in the document,
        except the current select box: */
        var x,
          y,
          i,
          xl,
          yl,
          arrNo = [];
        x = document.getElementsByClassName("select-items");
        y = document.getElementsByClassName("select-selected");
        xl = x.length;
        yl = y.length;
        for (i = 0; i < yl; i++) {
          if (elmnt == y[i]) {
            arrNo.push(i);
          } else {
            y[i].classList.remove("select-arrow-active");
          }
        }
        for (i = 0; i < xl; i++) {
          if (arrNo.indexOf(i)) {
            x[i].classList.add("select-hide");
          }
        }
      }

      /* If the user clicks anywhere outside the select box,
      then close all select boxes: */
      document.addEventListener("click", closeAllSelect);
    },
  },

  computed: {
    current_plan() {
      return this.$store.state.access_level;
    },
    
    get_lp_url: function () {
      return "https://app.kimpod.com/landing?v=" + this.thread_unique_id;
    },
    audioDevices: function () {
      return this.devices.filter(function (d) {
        return d.kind === "audioinput";
      });
    },
    videoDevices: function () {
      //console.log(this.devices);
      return this.devices.filter(function (d) {
        return d.kind === "videoinput";
      });
    },
    videoOptions: function () {
      let that = this;
      //alert(that.main_video);
      return {
        autoplay: true,
        controls: true,
        //aspectRatio: "16:9",
        playbackRates: [0.5, 1, 1.5, 2],
        userActions: { click: true },
        //nativeControlsForTouch: true,
        //liveui: true,
        preload: "metadata",
        pictureInPicture: false,
        //fluid: true,
        poster: that.poster_image,
        //src: "https://kimpod.s3.us-east-1.amazonaws.com/videos/89e03c75-ca56-4e8b-84a8-d351324663f8.mp4",
        sources: [
          {
            src: that.detail_content, //"https://doh0ql53hw6bi.cloudfront.net/de0bc9d9-d97f-47b1-8159-ed9bba6dbcd6/index.m3u8",
            type: "application/x-mpegURL", //"video/mp4",
          },
        ],
        html5: {
          hls: {
            limitRenditionByPlayerDimensions: false,
            //bandwidth: 6194304
            bandwidth: 800000,
          },
        },
      };
    },
  },
  created() {
    this.moment = moment;
  },

  beforeDestroy() {
    //document.getElementsByTagName('html')[0].style.overflow = 'auto';
  },

  mounted() {
    //document.getElementById("idBottomMenu").style.display = 'none';
    //document.getElementsByTagName('html')[0].style.overflow = 'hidden';

    AWS.config.httpOptions.timeout = 0;

    this.initializeCustomSelect();

    if (!navigator.mediaDevices && !navigator.mediaDevices.getUserMedia) {
      this.isRecordingSupported = false;
    } else {
      this.isRecordingSupported = true;
    }

    recordingProcessStarted = false;
    this.devices = [];

    this.loading = true;
    const that = this;

    that.msg_loading = true;
    api
      .getthreads({
        page: this.$route.query.page || 1,
        fields:
          "unique_id,subject,from_email,to_emails,last_message_time,thread_analytics,sending_status",
      })
      .then(function (res) {
        that.msg_loading = false;
        if (res.results) {
          that.message_threads = res.results;
          that.total_page = res.total_page;
          that.page_start = res.page_start;
          that.page_end = res.page_end;
          that.total_count = res.count;
          that.prev_page = res.prev_page;
          that.current_page = res.current_page;
          that.next_page = res.next_page;
          if (!that.isMobile) {
            that.checkAndLoadChatDetail();
          }
        } else {
          that.message_threads = [];
          that.total_page = 0;
          that.page_start = 0;
          that.page_end = 0;
          that.total_count = 0;
          that.prev_page = 0;
          that.current_page = 0;
          that.next_page = 0;
          if (!that.isMobile) {
            that.checkAndLoadChatDetail();
          }
        }
      })
      .catch(function (err) {
        that.msg_loading = false;
      });
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-panel {
  padding: 0rem !important;
}
/* ----------- iPad 1, 2, Mini and Air ----------- */
@media only screen and (max-width: 48em) {
  .header-link {
    width: 100% !important;
  }
  .main-panel {
    padding: 0 !important;
  }
}
/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
}

@media only screen and (max-width: 767px) {
  .single-message-history-block {
    box-shadow: inherit !important;
    height: 125px !important;

    border: 1px solid #f3f3f3;
    margin-bottom: 10px;
    border-radius: 20px;
  }
  .history-analytics.modal-popup .single-message-history-block {
    height: 115px !important;
    box-shadow: inherit !important;
  }
  .single-message-history-block p {
    padding-top: 40px !important;
  }
  .history-message-list {
    width: 100% !important;
  }
  .history-analytics {
    padding: 0px !important;
    width: 100% !important;
  }
  .single-message-history-block {
    width: 100% !important;
    margin-right: 0 !important;
  }

  div#EmojiPicker {
    left: 0px;
  }
  textarea {
    width: 100% !important;
  }
  .desktop-v {
    display: none !important;
  }
  .mobile-v {
    display: block !important;

    margin-top: 10px;
    float: left;
  }
  .col-md-1.schedule-action i {
    top: -200px !important;
  }
  .row.manage-schedule p {
    font-size: 15px !important;
  }
  ul.video-recorder-list.schedule-list {
    top: -200px !important;
  }
  ul.video-recorder-list.schedule-list li {
    display: block !important;
  }

  .d-flex.flex-wrap.message-box {
    bottom: 45px !important;
    left: 0px !important;
    background: #fff;
  }
  .timeline {
    width: 100% !important;
    margin: 3px 20px !important;
  }
  .blocks {
    width: 100% !important;
    padding: 20px !important;
  }
  .history-message {
    padding: 5px !important;
  }
  #idReplyVideoRecorderWrapper {
    min-width: 100% !important;
  }
  #idVideoReplyRecorderPopupBody {
    display: flex;
    flex-direction: column;
    min-width: 100% !important;
  }
  .messageArea {
    width: 100% !important;
  }

  #idMessageList {
    /* height: 400px !important; */
  }
  .history-message,
  #idMessageList {
    max-width: 100% !important;
    margin-left: auto;
    margin-right: auto;
    background: white;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  #idReplyDetailVideoPlayer {
    width: 100% !important;
    min-width: 100% !important;
  }
  #idVideoDetailPopupBody {
    width: 100% !important;
    min-width: 100% !important;
  }

  .poster-container-md {
    max-width: 100% !important;
    margin-right: 100px !important;
  }
  .inbox-item-details {
    margin-top: 10px !important;
    max-width: 100% !important;
    padding: 10px 20px !important;
  }
  .poster-container-md-wrapper {
    display: flex !important;
    flex-direction: column !important;
    cursor: pointer;
  }
  .sending-status-date {
  }
  #idFixedSideBarInboxDetail {
    position: fixed;
    overflow: auto;
    top: 63px;
    right: 0;
    bottom: 0;
    width: 100% !important;
    background: white;
    z-index: 999;
    box-shadow: 0px 0px 10px #232931;
    margin-top: 0 !important;
  }
  #idFixedSideBarInboxDetailOpaque {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #000;
    z-index: 998;
    opacity: 0.6;
  }
  .inbox-message-list {
    width: 100% !important;
  }
  #idToggleSidebarIcon {
    display: inline !important;
  }
  #idThreadDetailHeader {
    font-size: 1.1rem !important;
  }
  .message-box {
  }
  #idMessageList {
    padding-bottom: 140px;
  }
  #idInboxTable {
    max-height: 100% !important;
  }
  .showing-details {
    margin-left: auto;
    margin-right: auto;
  }
}

#idToggleSidebarIcon {
  display: none;
}

#idThreadDetailHeader {
  font-size: 1.4rem;
}

.inbox-item-details {
  width: 100%;
  padding: 2px;
}

.selected .inbox-item-details {
  background: #ececec;
}

.inbox-item-details div {
  font-size: 0.9rem !important;
}

.poster-container-md-wrapper {
  display: flex !important;
  flex-wrap: wrap !important;
  padding-right: 10px;
  background: #fff;
  margin-bottom: 3px;
  cursor: pointer;
}

#idVideoDetailPopupBody {
  background: white;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  border-radius: 10px;
  min-width: 600px;
}

#idReplyDetailVideoPlayer {
  min-width: 800px;
}

#idMessageList {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: white;
  padding-left: 40px;
  padding-right: 40px;
  /* height: 500px; */
  overflow-y: auto !important;
}

.history-message {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  background: white;
  padding-left: 40px;
  padding-right: 40px;
}

#idReplyVideoRecorderWrapper {
  min-width: 500px;
}
#idVideoReplyRecorderPopupBody {
  display: flex;
  align-items: center;
  min-width: 200px;
}
.timeline {
}
.blocks {
  width: 40%;
  margin-top: 20px;
}
.mobile-v {
  display: none;
}
.desktop-v {
  display: block;
}

.inbox-message-list {
  width: 360px;
  border-right: 1px solid #f2f2f2;
}

#idFixedSideBarInboxDetail {
  background: white;
  z-index: 1;
  width: calc(100% - 360px);
  margin-top: -70px;
}
#idFixedSideBarInboxDetailOpaque {
  display: none;
}
tr.highlight {
  /* background: #f2f4fa !important; */
  cursor: pointer;
}
tr.selected {
  /* background: #f9f9fb; */
}
.noscrollbar {
  overflow: hidden !important;
}
.me {
  max-width: 90%;
  float: right;
  text-align: right;
  padding: 20px;
  background: white;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-bottom: 20px;
  border: 1px solid #e5e5e5;
}
.other-user {
  max-width: 90%;
  float: left;
  text-align: left;
  padding: 20px;
  background: rgba(50, 128, 128, 0.1);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-bottom: 20px;
  border: 1px solid #e5e5e5;
}
.vm--modal {
  overflow: auto !important;
}
#idFixedSideBarInboxDetail img:not(.main-poster-img) {
  width: 200px !important;
}
#sveInputEmoji {
  position: relative;
  width: 100%;
}

button.btn.btn-emoji {
  background: none;
}
textarea#idReplyTextbox {
  width: 866px;
}
div.container-emoji {
  height: 190px !important;
}
div#EmojiPicker {
  float: left;
  top: -270px;
  position: absolute;
  z-index: 999999;
  left: -10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
}
.header-container.new-message {
  width: 100% !important;
}
.header-link a.btn.btn-trans.nav-link.pull-right.mr-2 {
  /* width: 170px; */
}
h2.header-text {
  font-weight: 600 !important;
  margin-left: 10px;
}
.message-content-area {
  cursor: pointer;
}
#idReplyTextbox:disabled {
  background: #f9f9f9 !important;
  cursor: not-allowed !important;
}
#idEmojiButton:disabled {
  cursor: not-allowed !important;
}
#idSendVideoIcon:disabled {
  cursor: not-allowed !important;
}
#idSendReplyBtn:disabled {
  cursor: not-allowed !important;
}
ul.video-recorder-list.list {
  background-color: #e9e9e9bf;
  left: 20px !important;
  right: inherit !important;
  margin: 0px !important;
  padding: 10px !important;
}

ul.message-sent {
  margin: 0px;
  text-align: center;
  padding: 5px 20px 0px;
}
ul.message-sent li {
  display: inline-block;
  cursor: pointer;
  font-size: 18px;
  margin-bottom: 15px;
}
.d-flex.flex-wrap.message-box {
  position: fixed;
  bottom: 0px;
  left: 500px;
}
div#idTemplateCancelConfirmation___BV_modal_body_ li {
  list-style: none;
  margin-bottom: 15px;
}

.history-message {
  padding: 20px;
  margin-top: 15px;
  position: absolute;
  z-index: 999999;
  margin-top: 0px;
  transition: 0.3s;
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  padding-bottom: 100px !important;
}
.history-message p {
  margin-left: 10px;
}

.history-copy-text {
  text-align: center;
  margin: 0 auto;
  width: 50%;
}

.analytics-cancel {
  float: left;
  font-size: 30px;
  color: #495057;
  font-weight: 400;
  cursor: pointer;
  margin-left: 23px;
  margin-bottom: 15px;
  position: absolute;
  left: 10px;
  top: 20px;
}

ul.message-sent {
  overflow: hidden;
  width: 100%;
  margin-top: 0px;
}

.history-message-list {
  width: 30%;
  float: left;
  margin-right: 15px;
  border: 1px solid #ddd;
  padding-bottom: 30px;
  border-radius: 5px;
  margin-bottom: 20px;
  background: white;
}

.thread-analytics {
  margin-top: 20px;
}
.single-message-history-block h5 {
  text-align: center;
  margin-top: 3px !important;
  padding-top: 30px;
  width: 80%;
  margin: 0 auto;
}
.history-analytics {
  float: right;
  width: 68%;
  padding: 0px 30px;
  display: flex;
  flex-wrap: wrap;
}
.single-message-history-block {
  width: 174px;
  height: 135px;
  text-align: center;
  font-size: 30px;
  margin-right: 13px;
  border: 1px solid #d2d2d2;
  border-radius: 10px;
  margin-bottom: 10px;
  background: white;
  float: left;
  box-shadow: 0px 0px 5px 0px #c7c5d8 !important;
}

.single-message-history-block p {
  margin: 0px !important;
  padding-top: 30px;
  font-size: 15px;
  font-weight: 500;
}
p.copy-analytics-link {
  float: left;
  cursor: pointer;
  margin: 0px;
}

input.input-copy-text {
  width: 80%;
  float: left;
  margin-top: 8px;
}
.docopy {
  float: left;
  width: 4%;
  cursor: pointer;
}

.single-message-history-block button.btn.btn-primary {
  background: inherit;
  color: black;
  text-decoration: underline;
  border: 1px solid #fff;
  padding: 0px !important;
  margin: 0px !important;
}

.mr-3.message-icon-level-text {
  color: #000;
  padding: 1px 0px;
}

.mr-3.message-level-text {
  color: grey;
  padding: 1px 0px;
}
.history-analytics.modal-popup {
  width: 100% !important;
}
.history-analytics.modal-popup p.d-flex.flex-wrap.justify-content-center {
  width: 100%;
  margin-top: 60px;
}

.history-analytics.modal-popup .single-message-history-block {
  height: 95px !important;
}
li.timeline-item {
  padding: 0px !important;
  text-align: left;
  float: left;
  margin: 0px 30px 15px;
  width: 100%;
  font-size: 0.8rem;
  line-height: 1.2rem;
  letter-spacing: 0.03rem;
}
.history-message-list h3 {
  text-align: center;
  margin-top: 15px;
  font-size: 24px;
}
.header-link button.btn.btn-primary {
  height: 43px;
}
.header-link a.btn.btn-trans.nav-link.pull-right.mr-2 {
  float: left;
}

.showing-details {
  width: 100%;
  border-bottom: 1px solid rgb(249, 249, 249) !important;
  min-width: 360px;
  max-width: 360px;
  padding-bottom: 10px;
}
.showing-icon {
  width: 200px;
  height: 200px;
  cursor: pointer;
}
span.spinner-grow.spinner-grow-sm {
  margin-top: 13px;
  cursor: pointer;
}

.showing-content {
  font-size: 14px;
  font-weight: 500;
  margin-top: 13px;
  float: left;
}
button.btn.refresh-icon.btn-secondary {
  border: inherit;
}
.poster-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  display: flex;
  background: #f9f9f9;
}

.poster-container-md {
  position: relative;
  width: 100%;
  max-width: 250px;
  display: flex;
  background: #333;
  max-height: 185px;
  display: none !important;
}

.poster-container-md img,
.poster-container img {
  height: auto;
  max-height: 187px;
  max-width: 250px;
  width: auto !important;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.poster-container-md .main-poster-btn,
.poster-container .main-poster-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  color: #fff;
  font-size: 4rem;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}
.poster-container .main-poster-btn:hover {
}
.message-icon-level-text {
  font-size: 0.7rem;
  text-transform: uppercase;
}
.message-level-text {
  font-size: 0.7rem;
  text-transform: uppercase;
}

span.emoji.border,
.emoji,
.border {
  border: inherit !important;
}
.message-box {
  width: 100%;
  margin: 0 auto;
  background: white;
  padding: 30px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 65px;
}
.messageArea {
  /* width: 1000px; */
  margin: 0 auto;
  background: white;
  padding-bottom: 10px;
  border-radius: 5px;
  height: 100%;
}
#idInboxTable {
  max-height: 100vh;
  overflow-y: auto;
}
</style>
<style>
/* The container must be positioned relative: */
.custom-select {
  position: relative;
  font-family: Arial;
}

.custom-select select {
  display: none; /*hide original SELECT element: */
}

.select-selected {
  background-color: rgb(73, 201, 203) !important;
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,
.select-selected {
  color: #ffffff;
  padding: 0 0 0 10px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  padding-bottom: 6px;
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: rgb(73, 201, 203) !important;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
.inbox-message-list .selected {
  border-top: 2px solid #49c9cb !important;
  border-bottom: 2px solid #49c9cb !important;
}
.message-reply-text {
  text-align: left !important;
}
</style>
