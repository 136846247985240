import Vue from "vue";
import Vuex from "vuex";
import * as api from "@/services/api";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    count: 0,
    user_tz: null,
    user: {
      loggedIn: false,
      info: null,
    },
    current_plan: {},
    access_level: null, // FREE, TRIAL, STANDARD, PREMIUM
    sideBarOpen: true,
    route: null,
    unread_replies_cnt: 0,
    total_messages_sent: 0,
    client_tz: "",
    isOnline: navigator.onLine,
    apiFetchError: false,
    show_screen_record_countdown_timer: false,
    scr_countdown_value: 0,
    recording_mode: null,
    hide_record_popup: true,
    hide_record_popup_close: true,
    globalAPIError: null, // 403, 429, 44, 500
    recording_status: "pending",
    video_upload_uuid: null,
    video_record_src: null,
    new_video_title: null,
    execute_post_upload_action: false
  },
  getters: {
    auth(state) {
      return state.user;
    },
    isLoggedIn(state) {
      return state && state.user && state.user.loggedIn;
    },
    isSidebarOpen(state) {
      if(state && state.sideBarOpen) {
        return true;
      }
      return false;
    }
  },
  actions: {
    fetchPlanDetails({commit}) {
      let that = this;
      //console.log("Fetching subscription details");
      if(!(this.state.route && (this.state.route.name === 'login' 
        || this.state.route.name === 'signup_complete' 
        || this.state.route.name === 'signup' 
        || this.state.route.name === 'too_many_requests' 
        || this.state.route.name === 'server_error' 
        || this.state.route.name === 'permission_denied'
        || this.state.route.name === 'not_found'
        || this.state.route.name === 'open_view'
        || this.state.route.name === 'landing_page'))) {
        api.SubscriptionStatus({}).then((res) => {
          //console.log(res)
          if (res.code == 200) {
            store.commit("setSubscriptionStatus", res.subscription_data);
          } else {
            that.$router.push({
              path: "/server-error",
            });
          }
        });
      }
    },
    fetchUnreadRepliesCount({commit}) {
        api.fetchUnreadRepliesCount({}).then((res) => {
          //console.log(res)
          if (res.code == 200) {
            store.commit("updateMessageStat", res);
          }
        });
    },
    setClientTz({commit}) {
      try {
        store.commit("setClientTZ", Intl.DateTimeFormat().resolvedOptions().timeZone);
      }catch(e) {
        console.log("Could not detect client timezone automatically.")
      }      
    }
  },
  mutations: {
    set_route(state, route) {
      state.route = route;
    },
    setLoggedIn2(state, userInfo) {
      state.user.info = userInfo;
      state.user.loggedIn = true;
      //console.log("Mutation called2!");
    },
    setLoggedIn(state, userInfo) {
      state.user.info = userInfo;
      state.user.loggedIn = true;
      //console.log("Mutation called!");
    },
    setSubscriptionStatus(state, subscription_info) {
      state.current_plan = subscription_info;
      state.access_level = subscription_info.access;
    },
    toggleSidebarOpenStatus(state) {
      state.sideBarOpen = !state.sideBarOpen;
    },
    updateMessageStat(state, stat) {
      state.unread_replies_cnt = stat.unread_count;
      state.total_sent_count = stat.total_sent_count;
    },
    setClientTZ(state, ctz) {
      state.client_tz = ctz;
    },
    setOnlineStatus(state, isOnline) {
      state.isOnline = isOnline;
      //console.log(isOnline);
    },
    setAPIFetchError(state, status) {
      state.apiFetchError = status;
      //console.log(isOnline);
    },
    setGlobalAPIResponseError(state, error) {
      state.globalAPIError = error;
      //console.log(isOnline);
    },
    setScreenRecordCountdownInfo(state, info) {
      state.show_screen_record_countdown_timer = info.show_screen_record_countdown_timer;
      state.scr_countdown_value = info.scr_countdown_value;
      //console.log(isOnline);
    },

    clearRecordingMode(state) {
      state.recording_mode = "pending";
      state.hide_record_popup = true;
      state.hide_record_popup_close = true;
    },

    setRecordingMode(state, mode) {
      state.recording_status = "pending";
      state.video_upload_uuid = null;
      state.video_record_src = null;

      state.recording_mode = mode;
      state.hide_record_popup = false;
      state.hide_record_popup_close = false;
      state.execute_post_upload_action = false;
    },
    clearRecordingInfo(state) {
      state.recording_status = "pending";
      state.video_upload_uuid = null;
      state.video_record_src = null;
      state.hide_record_popup = true;
      state.hide_record_popup_close = true;
      state.execute_post_upload_action = false;
    },
    updateRecordingInfo(state, info) {
      state.recording_status = info.status;
      state.video_upload_uuid = info.video_upload_uuid;
      state.video_record_src = info.video_record_src;
      state.new_video_title = info.new_video_title;
    },
    setRecordPopupCloseD(state, status) {
      state.hide_record_popup_close = status;
    },
    setExecutePostUploadAction(state) {
      state.execute_post_upload_action = true;
    }
  },
});

export default store;
