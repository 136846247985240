<template>
  <nav
    class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row justify-content-between"
  >
    <router-link
      class="navbar-brand brand-logo-mini ml-lg-1 text-white mr-5"
      :to="{ path: `/` }"
    >
      <img
        style="width: 60px; margin-left: 10px"
        src="../../public/assets/images/logo-m.png"
      />
      <span style="position: absolute;bottom: 30px;left: 10px;color: #ffffff;background: #413d3d;font-size: 0.8rem;padding: 2px;">beta</span>
    </router-link>
    <div
      class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center"
    >
      <router-link
        class="navbar-brand brand-logo mr-5 ml-lg-5 text-white"
        :to="{ path: `/` }"
      >
        <img
          style="width: 200px; height: auto"
          src="../../public/assets/images/logo.png"
        />
        <span style="position: absolute;bottom: 30px;left: 20px;color: #ffffff;background: #413d3d;font-size: 0.8rem;padding: 2px;">beta</span>
      </router-link>
      <button
        @click="toggle_sidebar()"
        type="button"
        class="btn-toggler-desktop btn align-self-center collapsed ml-2"
        style="
          color: #fff;
          overflow-anchor: none;
          font-size: 1.3rem;
          margin-top: 7px;
          font-weight: 600;
        "
      >
        ☰
      </button>
      <div
        class="navbar-menu-wrapper d-flex align-items-center justify-content-end"
      >
        <div>
          <b-sidebar id="sidebar-right" right shadow>
            <div class="mobile-sidebar">
              <div class="menu-item-holder">
                <router-link
                  class="bottom-menu-text"
                  :to="{ path: `analytics` }"
                >
                  <i class="fas fa-chart-line"></i>
                  <p class="mobile-nav-bar">Analytics</p></router-link
                >
              </div>
              <div class="menu-item-holder">
                <router-link
                  class="bottom-menu-text"
                  :to="{ path: `messages` }"
                >
                  <i class="fas fa-list-ul"></i>
                  <p class="mobile-nav-bar">Messages Sent</p></router-link
                >
              </div>
              <div class="menu-item-holder">
                <router-link class="bottom-menu-text" :to="{ path: `replies` }">
                  <i class="far fa-envelope"></i>
                  <p class="mobile-nav-bar">Replies <span style="font-weight: 900;" v-if="unread_reply_count>0">({{ unread_reply_count }})</span></p></router-link
                >
              </div>
              <div class="menu-item-holder">
                <router-link
                  class="bottom-menu-text"
                  :to="{ path: `manage-schedules` }"
                >
                  <i class="far fa-clock"></i>
                  <p class="mobile-nav-bar">Schedules</p></router-link
                >
              </div>
              <div class="menu-item-holder">
                <router-link
                  class="bottom-menu-text"
                  :to="{ path: `my-recordings` }"
                >
                  <i class="far fa-circle"></i>
                  <p class="mobile-nav-bar">
                    Video Library<span
                      class="ml-1 badge badge-success"
                      style="font-size: 0.6rem"
                      >Coming Soon</span
                    >
                  </p></router-link
                >
              </div>
              <div class="menu-item-holder">
                <router-link
                  class="bottom-menu-text"
                  :to="{ path: `manage-contacts` }"
                >
                  <i class="fas fa-address-card"></i>
                  <p class="mobile-nav-bar">Manage Contacts</p></router-link
                >
              </div>

              <div class="menu-item-holder">
                <router-link
                  class="bottom-menu-text"
                  :to="{ path: `verified-emails` }"
                >
                  <i class="fas fa-th-list"></i>
                  <p class="mobile-nav-bar">Sender Emails</p></router-link
                >
              </div>

              <div class="menu-item-holder">
                <router-link
                  class="bottom-menu-text"
                  :to="{ path: `manage-domains` }"
                >
                  <i class="fas fa-server"></i>
                  <p class="mobile-nav-bar">
                    Manage Domains<span
                      v-if="
                        current_plan === 'FREE' || current_plan === 'STANDARD'
                      "
                      style="font-size: 0.6rem"
                      class="ml-1 badge badge-danger"
                      >Upgrade</span
                    >
                  </p></router-link
                >
              </div>

              <div class="menu-item-holder">
                <router-link
                  class="bottom-menu-text"
                  :to="{ path: `manage-templates` }"
                >
                  <i class="fas fa-server"></i>
                  <p class="mobile-nav-bar">
                    Templates<span
                      v-if="current_plan === 'FREE'"
                      style="font-size: 0.6rem"
                      class="ml-1 badge badge-danger"
                      >Upgrade</span
                    >
                  </p></router-link
                >
              </div>

              <div class="menu-item-holder">
                <router-link class="bottom-menu-text" :to="{ path: `my-team` }">
                  <i class="fas fa-user-plus"></i>
                  <p class="mobile-nav-bar">
                    My Team<span
                      class="ml-1 badge badge-success"
                      style="font-size: 0.6rem"
                      >Coming Soon</span
                    >
                  </p></router-link
                >
              </div>

              <div class="menu-item-holder">
                <router-link
                  class="bottom-menu-text"
                  :to="{ path: `integrations` }"
                >
                  <i class="fas fa-bolt"></i>
                  <p class="mobile-nav-bar">
                    Integrations<span
                      class="ml-1 badge badge-success"
                      style="font-size: 0.6rem"
                      >Coming Soon</span
                    >
                  </p></router-link
                >
              </div>

              <div class="menu-item-holder">
                <router-link
                  class="bottom-menu-text"
                  :to="{ path: `manage-subscriptions` }"
                >
                  <i class="far fa-credit-card"></i>
                  <p class="mobile-nav-bar">Subscription</p></router-link
                >
              </div>

              <div class="menu-item-holder">
                <router-link
                  class="bottom-menu-text"
                  :to="{ path: `payments` }"
                >
                  <i class="far fa-credit-card"></i>
                  <p class="mobile-nav-bar">Payments & Invoices</p></router-link
                >
              </div>
            </div>
          </b-sidebar>
        </div>
      </div>
    </div>

    <div class="d-flex flex-wrap">
      <div class="top-d-menu-item align-self-center p-2 mr-2">
        <router-link
          class="bottom-menu-text vh-100 align-items-center"
          :to="{ path: `/analytics` }"
        >
          <!-- <img class="mr-1" src="../../public/assets/images/menu/home.png" width="20"/> -->
          <span class="mt-2">Home</span>
        </router-link>
      </div>
      <!-- <div class="top-d-menu-item align-self-center p-2 mr-2">
                    <router-link class="bottom-menu-text" :to="{ path: `dashboard`}" >
                    <img class="mr-1" src="../../public/assets/images/menu/dash-md.png" width="20"/>
                    <span class="mt-2">Dashboard</span>
                    </router-link>
                </div> -->
      <div class="top-d-menu-item align-self-center p-2 mr-2">
        <router-link class="bottom-menu-text" :to="{ path: `/messages` }">
          <!-- <img class="mr-1" src="../../public/assets/images/menu/inbox.png" width="20"/> -->
          <span class="mt-2">Messages Sent</span>
        </router-link>
      </div>

      <div class="top-d-menu-item align-self-center p-2 mr-2">
        <router-link
          class="bottom-menu-text"
          :to="{ path: `/manage-subscriptions` }"
        >
          <!-- <img class="mr-1" src="../../public/assets/images/menu/subscription.png" width="20"/> -->
          <span class="mt-2">Plan and Subscriptions</span>
        </router-link>
      </div>
      <div class="top-d-menu-item align-self-center p-2 mr-2">
        <router-link class="bottom-menu-text" :to="{ path: `/help` }">
          <!-- <img class="mr-1" src="../../public/assets/images/menu/help.png" width="25"/> -->
          <span class="mt-2">Help</span>
        </router-link>
      </div>

      <div class="dropdown logout-dropdown">
        <a
          class="btn dropdown-toggle ffweight-600 d-flex align-items-center"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          style="font-size: 1.3rem"
        >
          <img
            v-show="photo_url_loading"
            v-if="photo_url"
            :src="photo_url"
            style="height: 40px;
              width: 40px;
              margin-left: -1px;
              border-radius: 50%;
            "
          />

          <Avatar v-if="!photo_url" :hsl="avatar_hsl" :text="avatar_short_name"></Avatar>

          <i v-show="!photo_url_loading" class="fa fa-user-tie"></i>
        </a>

        <div
          class="dropdown-menu p-3"
          style="right: 0; left: auto"
          aria-labelledby="dropdownMenuButton"
        >
          <div class="mb-2">
            {{ email }}
          </div>
          <router-link class="dropdown-item d-flex align-items-center" :to="{ path: '/profile' }">
            <img
              v-show="photo_url_loading"
              v-if="photo_url"
              :src="photo_url"
              style="height: 35px;
                width: 35px;
                margin-left: -1px;
                border-radius: 50%;
              "
            />

            <Avatar v-if="!photo_url" :hsl="avatar_hsl" :text="avatar_short_name"></Avatar>

            <i v-show="!photo_url_loading" class="fa fa-user-tie"></i>
            <span style="margin-left: 10px">
              {{ fullName }}
            </span></router-link
          >
          <button class="logout-btn" @click="submit_logout">
            <i class="fa fa-sign-out-alt"></i>

            <span style="margin-left: 10px">Logout</span>
          </button>
        </div>
      </div>
      <b-button
        class="navbar-toggler navbar-toggler align-self-center"
        type="button"
        v-b-toggle.sidebar-right
      >
        ☰</b-button
      >
    </div>
  </nav>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import Avatar from "@/components/Avatar";
import * as api from "@/services/api";

export default {
  name: "TopNavBarComponent",
  data() {
    return {
      first_name: "My Profile ",
      display_name: '',
      email: "",
      photo_url: "",
      loading: false,
      photo_url_loading: false,
      avatar_html: '',
      avatar_hsl: '',
      avatar_short_name: ''
    };
  },
  components: {
    Avatar
  },
  computed: {
    current_plan() {
      return this.$store.state.access_level;
    },
    fullName() {
      return this.display_name;
    },
    unread_reply_count() {
      return this.$store.state.unread_replies_cnt;
    }
  },
  async created() {
    const that = this;
    let data = localStorage.getItem("u");
    let user = JSON.parse(data);
    this.first_name = user.first_name;
    this.display_name = user.display_name;
    this.email = user.email;
  },
  methods: {
    toggle_sidebar: function () {
      //console.log(this.$store.state.sideBarOpen)
      //console.log(this.$refs.avatarHtmlRef.$el.outerHTML);
      if (this.$store.state.sideBarOpen) {
        document.getElementById("sidebar").classList.add("d-none");
        document.getElementById("idMainPanel").classList.add("body-box-shadow");
        document
          .getElementById("idPageBodyWrapper")
          .classList.add("justify-content-center");
      } else {
        document.getElementById("sidebar").classList.remove("d-none");
        document
          .getElementById("idMainPanel")
          .classList.remove("body-box-shadow");
        document
          .getElementById("idPageBodyWrapper")
          .classList.remove("justify-content-center");
      }
      this.$store.commit("toggleSidebarOpenStatus");
    },
    submit_logout: function () {
      let that = this;
      api.Logout().then(function () {
        localStorage.removeItem("sve_token");
        that.$router.push("/login");
      });
    },
  },
  mounted: function () {
    let data = localStorage.getItem("u");
    let user = JSON.parse(data);

    this.first_name = user.first_name;
    if (user.photo_url) {
      this.photo_url = user.photo_url;
      this.photo_url_loading = true;
    } else {
      this.photo_url_loading = true;
    }

    this.email = user.email;

    this.avatar_hsl = user.avatar_hsl_color;
    this.avatar_short_name = user.avatar_title_letters;
  },
};
</script>
<style>
.b-sidebar {
  width: 100% !important;
}
.body-box-shadow {
  box-shadow: 0px 0px 15px 0px #c7c5d8 !important;
}
svg.bi-x.b-icon.bi {
  font-size: 1.4rem !important;
  color: white !important;
}
</style>
<style scoped>
.top-d-menu-item a {
  color: #000 !important;
  font-weight: 600 !important;
  padding: 10px 15px;
  font-size: 1.3rem;
}

.ffweight-600 {
  font-weight: 600 !important;
}

@media only screen and (max-width: 767px) {
  .btn-toggler-desktop {
    display: none !important;
  }
  a.bottom-menu-text.vh-100.align-items-center.router-link-exact-active.router-link-active,
  a.bottom-menu-text.router-link-exact-active.router-link-active {
    background: inherit !important;
  }
  button.btn.navbar-toggler.align-self-center.btn-secondary.collapsed {
    visibility: visible !important;
  }
  .menu-item-header {
    display: none;
  }

  .text-center.navbar-brand-wrapper.d-flex.align-items-center.justify-content-center {
    width: 0px !important;
  }

  button.close.text-dark {
    font-size: 40px !important;
    color: white !important;
  }
  content-wrapper {
    padding: 20px 0px;
  }
  .mobile-sidebar a.bottom-menu-text {
    margin-bottom: 15px !important;
    overflow: hidden;
    display: block;
  }
  .mobile-sidebar a.bottom-menu-text i.fa {
    float: left;
    width: 15%;
    margin-right: 15px !important;
    margin-top: 5px !important;
    height: 40px !important;
  }
  .mobile-sidebar p.mobile-nav-bar {
    width: 70%;
    float: left;
    margin-top: 2px;
    text-align: left;
    margin-bottom: 0px;
    font-size: 1.1rem;
  }
  .mobile-sidebar {
    width: 82%;
    margin: 0 auto;
    overflow-y: auto;
  }
  a.bottom-menu-text.vh-100.align-items-center.router-link-exact-active.router-link-active,
  a.bottom-menu-text.router-link-exact-active.router-link-active {
    background: inherit !important;
  }
}
.dropdown-item {
  width: 80% !important;
}

a.bottom-menu-text.vh-100.align-items-center.router-link-exact-active.router-link-active,
a.bottom-menu-text.router-link-exact-active.router-link-active {
  background: rgb(0 0 0 / 10%);
}

.dropdown-item:hover,
.dropdown-item:focus {
  background: inherit;
}

button.logout-btn {
  font-size: 15px;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  padding: 10px;
}
.dropdown.logout-dropdown {
  margin-right: 5px;
}
button.btn.navbar-toggler.align-self-center.btn-secondary.collapsed {
  background: inherit !important;
  color: #fff !important;
  border: none !important;
  box-shadow: none !important;
  visibility: hidden;
}
.dropdown-menu.show {
  padding: 0px;
}
i.ti-power-off.text-primary {
  margin-right: 10px;
}

i.ti-settings.text-primary {
  margin-left: 15px;
  margin-right: 10px;
}
.dropdown .dropdown-menu .dropdown-item {
  width: 100% !important;
}
button.logout-btn:hover {
}
a.dropdown-item {
  padding: 10px !important;
}
a.nav-link.router-link-exact-active.router-link-active {
  background: #fff;
}
.sidebar .nav {
  position: fixed;
}
.navbar .navbar-brand-wrapper {
  width: auto;
}
.menu-item-header p {
  margin-right: 40px;
  margin-top: 25px;
  text-align: right;
  float: right;
}

.menu-item-header.menu-item-parent p {
  margin-right: 40px;
  margin-top: -5px;
  text-align: right;
  float: right;
}
.menu-item-header.menu-item-parent {
  width: 67%;
  margin-top: 15px;
}

.menu-item-holder.menu-item {
  float: right;
}
.menu-item-holder.menu-item .bottom-menu-text {
  float: left;
}
.menu-item-holder.menu-item i.fa {
  float: left;
  margin-right: 15px;
}
.menu-item-header p {
  margin-right: 40px;
  margin-top: -5px;
  float: right;
}
</style>

<style>
#sidebar-right.bg-light {
  background-color: #ffffff !important;
}
.b-sidebar > .b-sidebar-body {
  margin-top: 20px !important;
  overflow: scroll;
}
button#dropdownMenuButton {
  margin-top: 0px !important;
}
.mobile-sidebar p.mobile-nav-bar {
  width: 76%;
  font-size: 1.3rem;
  letter-spacing: 0.02rem;
}
.mobile-sidebar i {
  font-size: 1.3rem;
}
.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
  margin-left: 120px;
}
a.navbar-brand.brand-logo-mini.ml-lg-1.text-white.mr-5.router-link-active {
  display: none;
}
@media only screen and (max-width: 767px) {
  .top-d-menu-item {
    display: none !important;
  }
  a.navbar-brand.brand-logo-mini.ml-lg-1.text-white.mr-5.router-link-active {
    display: block !important;
  }
}
.router-link-active {
  /* background: rgba(50, 50, 50, 0.1); */
  /* padding: 10px 15px; */
}

.router-link-active p {
  color: #e8c617 !important;
  font-weight: 600;
}

.top-d-menu-item a:hover {
  background: rgba(50, 50, 50, 0.1) !important;
  padding: 10px 15px;
}
.dropdown.logout-dropdown.show {
  background: rgba(50, 50, 50, 0.1) !important;
}
a#dropdownMenuButton:hover {
  color: #000 !important;
  background: rgba(50, 50, 50, 0.1) !important;
}
.sidebar .nav:not(.sub-menu) > .nav-item.active {
  background: rgba(50, 50, 50, 0.1);
}
</style>
