<template>    
    <div id="idVideoFileUploadContainer" class="d-flex flex-column justify-content-center lp-main-wrapper p-3" style="background: #fff; margin: 0 auto; position: relative; transition: visibility 5s, opacity 0.5s linear;">        
      <h4 class="mt-3 mb-3 text-center"
            style="font-weight: 900; font-size: 1.2rem!important;">
            Upload Pre-recorded Video
          </h4>
          <div v-show="!video_file_selected">
            <p class="text-center">
              {{ file_upload_note }}
            </p>
            <p class="alert alert-danger" v-if="file_upload_size_error">{{ file_upload_size_error }}</p>
            <div
              id="idPrerecordedVideo"
              class="dropzone dz-clickable"
              data-toggle="dropzone"
            ></div>
          </div>

          <div v-show="video_file_selected">
            <!-- <video id="idPrerecordedVideoPlayer" style="max-width: 600px;box-shadow: 1px 1px 9px -2px #c7c5d8;" controls autoplay playsinline ref="preRecordedVideo" width="400" height="300"></video> -->
            <video-player-obsolete
              id="idPrerecordedVideoPlayer"
              :videoURL="pre_recorded_video_src"
              v-if="render_video_components"
            />
            <div
              class="d-flex flex-wrap justify-content-between p-1"
            >
              <div
                class="d-flex flex-wrap justify-content-start align-items-center"
                style="
                  height: 40px;
                  font-weight: 600;
                  font-size: small;
                "
              >
              <div id="idFileUploadReady"
                class="d-none flex-wrap justify-content-end">
                <i
                  style="color: green"
                  class="fa fa-check mt-2 mb-2"
                  aria-hidden="true"
                >
                  Video is ready</i
                >
              </div>
              <div
                id="idFileUploadError"
                class="d-none flex-wrap justify-content-end"
              >
                <i
                  style="color: red"
                  class="fa fa-exclamation-triangle mt-2 mb-2"
                  aria-hidden="true"
                >
                  Upload error.
                  <button @click="retryS3Upload('upload')" class="btn btn-danger">Retry</button></i
                >
              </div>
                <div id="idFileUploadProgressLabel" ref="fileUploadProgressLabel"></div>
                <div id="idFileUploadSizeLabel" class="ml-2" ref="fileUploadSizeLabel"></div>
              </div>
            </div>
          </div>
    </div>
  </template>
  <script>
  import Vue from 'vue';
  import { Buffer } from 'buffer';
  //var mime = require('mime-types')
  // eslint-disable-next-line no-unused-vars
  import "vue-search-select/dist/VueSearchSelect.css";
  import { ModelSelect } from "vue-search-select";
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import moment from "moment";
  import { uuid } from "vue-uuid";
  import Resumable from "resumablejs";
  import UploadingVideo from "./uploadvideo";
  import VideoPlayerObsolete from "@/components/players/VideoPlayerObsolete";

  import MainMenuComponent from "@/components/MainMenuComponent";
  import TopNavBarComponent from "@/components/TopNavBarComponent";
  import VideoPlayer from "@/components/players/VideoPlayer";

  import globalVars from "../global";

  import * as api from "@/services/api";
  import pell from "pell";
  import Toast from "vue-toastification";
  import "vue-toastification/dist/index.css";

let toastOptions = {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
};

//-----------------------------------------------------------------------------
// https://github.com/collab-project/videojs-record/blob/master/examples/browser-workarounds.js
//-----------------------------------------------------------------------------

/*eslint-disable*/
if (typeof MediaRecorder === "undefined") {
}

/*eslint-disable*/
var isOpera = !!window.opera || navigator.userAgent.indexOf("OPR/") !== -1;
var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
var isEdge = /Edge/.test(navigator.userAgent);

function takethumbnail(videl) {
  const dims = getscaleddims(videl.videoWidth, videl.videoHeight);
  thumbnail.width = dims[0];
  thumbnail.height = dims[1];
  thumbnail.getContext("2d").drawImage(videl, 0, 0, dims[0], dims[1]);
}

// Returns [width, height].
function getscaleddims(w, h) {
  var nw = 250;
  var ratio = nw / w;
  var nh = ratio * h;
  return [nw, nh];
}

/*eslint-disable*/
function applyAudioWorkaround() {
  if (isSafari || isEdge) {
    if (isSafari && window.MediaRecorder !== undefined) {
      // this version of Safari has MediaRecorder
      return;
    }

    // support recording in safari 11/12
    // see https://github.com/collab-project/videojs-record/issues/295
    options.plugins.record.audioRecorderType = StereoAudioRecorder;
    options.plugins.record.audioSampleRate = 44100;
    options.plugins.record.audioBufferSize = 4096;
    options.plugins.record.audioChannels = 2;

    console.log("applied audio workarounds for this browser");
  }
}

function applyVideoWorkaround() {
  // use correct video mimetype for opera
  if (isOpera) {
    options.plugins.record.videoMimeType = "video/webm;codecs=vp8"; // or vp9
  } else {
    // options.plugins.record.videoMimeType = 'video/webm;codecs=H264';
    // options.plugins.record.videoMimeType = 'video/mp4';
  }
}

/*eslint-disable*/
function applyScreenWorkaround() {
  // Polyfill in Firefox.
  // See https://blog.mozilla.org/webrtc/getdisplaymedia-now-available-in-adapter-js/
  if (adapter.browserDetails.browser == "firefox") {
    adapter.browserShim.shimGetDisplayMedia(window, "screen");
  }
}

let maxRecordingDuration = 5 * 60;
let maxRecordingSize = 1024 * 1024 * 100;
let maxScreenRecordingDuration = 5 * 60;
let maxScreenRecordingSize = 1024 * 1024 * 100;
let recordingProcessStarted = false;
let maxFileUploadSize = 100; // 100 MB
let maxTimeWaitAfterRecordingStopped = 60; // 10 seconds

// https://collab-project.github.io/videojs-record/#/usage
import "video.js/dist/video-js.min.css";
import videojs from "video.js";
import RecordRTC from "recordrtc";
import "videojs-record/dist/css/videojs.record.css";

import Record from "videojs-record/dist/videojs.record.js";

// For Dropzone
import "dropzone/dist/min/basic.min.css";
import "dropzone/dist/min/dropzone.min.css";
import Dropzone from "dropzone/dist/min/dropzone.min.js";
// the following imports are only needed when you're recording
// audio-only using the videojs-wavesurfer plugin
import WaveSurfer from "wavesurfer.js";
import MicrophonePlugin from "wavesurfer.js/dist/plugin/wavesurfer.microphone.js";
WaveSurfer.microphone = MicrophonePlugin;

// register videojs-wavesurfer plugin
import "videojs-wavesurfer/dist/css/videojs.wavesurfer.css";
import Wavesurfer from "videojs-wavesurfer/dist/videojs.wavesurfer.js";
import Cookies from "js-cookie";

// video js trimmer
/* eslint-disable */
//import "../../public/assets/vendors/videojs-trimmer.css";
//import "../../public/assets/vendors/videojs-trimmer.js";

let player = null;
let dropzone = null;

let screenRecorderStream = null;
let screenDesktopStream = null;
let screenAudioStream = null;

let screen_record_blob = null;
let custom_thumnail = null;

let allBlobs = [];

let videoRecordUUID = null;
let videoRecordCompleted = false;
let videoRecordFileKey = null;
let videoRecordMultipart = null;
let videoUploadPartNumber = 0;
let lastUploadedBlobIndex = -1;
let recordedBlobs = [];
let totalUploadInitiated = 0;
let totalUploadCompleted = 0;
let recordingStopped = false;
let streamRecordCompleted = false;
let recordingDurationInSecond = 0;
let recordingDurationTimer = null;

let screenRecordUUID = null;
let screenRecordCompleted = false;

let fileUploadUUID = null;
let fileUploadCompleted = false;
let fileUploadObject = {};
let numPartsLeftMPU = 0;
let totalPartsUploaded = 0;
let fileUploadS3Instance = null;
let fileUploadS3SimpleUploadRef = null;
let fileUploadS3InstanceSimple = null;
let fileUploadS3BucketName = null;
let fileUploadS3FileKey = null;
let fileUploadS3UploadId = null;
let bucketName = globalVars.VIDEO_UPLOAD_BUCKET;
let bucketRegion = "us-east-1";
let IdentityPoolId = "us-east-1:e0bbf09b-7694-4843-9166-8abab853ba40";

var multipartMap = {
  Parts: [],
};

//
let maxUploadTries = 3;
let currentChunkIndex = 0;
let s3UploadError = false;
let binaryBlob = null;
let canUpload = false;
let uploadInstances = [];
//

function resetGolabVariables() {
  player = null;
  dropzone = null;

  screenRecorderStream = null;
  screenDesktopStream = null;
  screenAudioStream = null;

  screen_record_blob = null;
  custom_thumnail = null;

  allBlobs = [];

  videoRecordUUID = null;
  videoRecordCompleted = false;
  videoRecordFileKey = null;
  videoRecordMultipart = null;
  videoUploadPartNumber = 0;
  lastUploadedBlobIndex = -1;
  recordedBlobs = [];
  totalUploadInitiated = 0;
  totalUploadCompleted = 0;
  streamRecordCompleted = false;
  recordingDurationInSecond = 0;
  recordingDurationTimer = null;

  screenRecordUUID = null;
  screenRecordCompleted = false;

  fileUploadUUID = null;
  fileUploadCompleted = false;
  fileUploadObject = {};
  numPartsLeftMPU = 0;
  totalPartsUploaded = 0;
  fileUploadS3Instance = null;
  fileUploadS3BucketName = null;
  fileUploadS3FileKey = null;
  fileUploadS3UploadId = null;
  bucketName = globalVars.VIDEO_UPLOAD_BUCKET;
  bucketRegion = "us-east-1";
  IdentityPoolId = "us-east-1:e0bbf09b-7694-4843-9166-8abab853ba40";

  multipartMap = {
    Parts: [],
  };

  maxUploadTries = 3;
  currentChunkIndex = 0;
  s3UploadError = false;
  binaryBlob = null
  canUpload = false;
  uploadInstances = []
}

function abortS3DirectUpload(uInstance) {
  return new Promise(function (resolve, reject) {
    try {
        uInstance.abort();
        resolve();
    } catch (e) {
      reject(e);
    }
  });
}

function abortS3MultipartUpload() {
  var params = {
    Bucket: fileUploadS3BucketName,
    Key: fileUploadS3FileKey,
    UploadId: fileUploadS3UploadId,
  };
  return new Promise(function (resolve, reject) {
    for (var i = 0; i < 6; i++) {
      try {
        fileUploadS3Instance.abortMultipartUpload(params, function (err, data) {
          //alert(1)
          if (err) console.log(err, err.stack); // an error occurred
          else {
          }
        });
      } catch (e) {
        console.log("Multi Abort Error ", e);
      }
    }
    resolve();
  });
}

function initializeS3(bucketName, bucketRegion, IdentityPoolId, completedCallback, failedCallback) {
  try {
    if (fileUploadS3Instance) {
      completedCallback(fileUploadS3Instance);
      return;
    }
    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    });

    let s3Instance = new AWS.S3({
      apiVersion: "2006-03-01",
      params: { Bucket: bucketName },
    });
    completedCallback(s3Instance);
  }catch(e) {
    failedCallback(e);
  }
}

function uploadToS3(s3Instance, attempt, fileKey, blob, acl, contentEncoding, contentType, successCallback, failedCallback, progressCallback) {
  if(s3UploadError) {
    failedCallback("ERROR")
     return;
  }  
  var attempt = attempt || 1;
    let payload = {
          Key: fileKey,
          Body: blob,
          ACL: 'public-read'
    };
    if(acl) {
      payload.ACL = acl;
    }
    if(contentEncoding) {
      payload.ContentEncoding = contentEncoding;
    }
    if(contentType) {
      payload.ContentType = contentType;
    }
    try {
      fileUploadS3SimpleUploadRef = s3Instance.upload(payload, 
        function(err, data) {
            console.log(err, data)
            if(err) {
              console.log("Err")
              //videoRecordUploadCompletedCallback("FAILURE");
              //console.log(err);
              if (attempt < maxUploadTries) {
                console.log('Retrying uploadToS3 of attempt: #', attempt)
                uploadToS3(s3Instance, attempt + 1, fileKey, blob, acl, contentEncoding, contentType, successCallback, failedCallback, progressCallback);
              } else {
                console.log('Failed uploading file key: ', fileKey)
                failedCallback(err)
              }
            }
            else {
              console.log("Success")
              //videoRecordUploadCompletedCallback("SUCCESS");
              successCallback(data);
            }
        }).on('httpUploadProgress', function (progress) {
        var uploaded = parseInt((progress.loaded * 100) / progress.total);
        //alert(uploaded);
        if(progressCallback) {
          progressCallback(uploaded);
        }
      });
      uploadInstances.push(fileUploadS3SimpleUploadRef);
    }catch(e) {
        console.log("Gherr");
        failedCallback(e);
    }
}

function S3MultipartQueueUpload(qSize, progressCallback) {
  if(!binaryBlob) {
      return false;
  }
  if(progressCallback) {
      progressCallback(null, null, "Initializing Upload");
  }
  var qSize = qSize || 4;
  var numOfWorkers = 0;
  var taskIndex = 0;

  var tasks = {};

  var partSize = 1024 * 1024 * 2;
  var totalParts = 0;
  for (
    var rangeStart = 0;
    rangeStart < binaryBlob.size;
    rangeStart += partSize
  ) {
    var end = Math.min(rangeStart + partSize, binaryBlob.size);
    /*var nextChunkSize = binaryBlob.size - end;
    if (nextChunkSize < partSize) {
      end = binaryBlob.size;
    }*/    
    tasks[totalParts] = {
        start: rangeStart,
        end: end,
        status: "p" //p, u, f = Pending, Uploaded, Failed
    }
    totalParts += 1;
  }

  //console.log(tasks);

  var totalTasks = Object.keys(tasks).length;
  var totalSuccess = 0;

  let blobName = binaryBlob.name;
  var re = /(?:\.([^.]+))?$/;
  var fileExt = re.exec(blobName)[1];
  console.log("Starting")
  return new Promise((resolve, reject) => {
    initializeS3(bucketName, bucketRegion, IdentityPoolId, function(s3Instance) {
        fileUploadS3Instance = s3Instance;

        const handleSuccessResult = index => result => {
          console.log("handleSuccessResult " + index + " " + result)
          //console.log(result);
          totalSuccess += 1;
          let uploaded = parseInt(
                  (totalSuccess * 100) / totalTasks
                );
          if(progressCallback) {
            progressCallback(totalTasks, totalSuccess, "Uploading("+ uploaded +"%)");
          }
          tasks[index].status = result;
          numOfWorkers--;
          getNextTask();
      };

      const handleFailureResult = index => result => {
        console.log("handleFailureResult " + index + " " + result)
        //console.log(result);
        tasks[index].status = result;
        //numOfWorkers--;
        //getNextTask();
        s3UploadError = true;
        reject()
      };

      const getNextTask = () => {
        console.log("getNextTask " + taskIndex)
        console.log("s3UploadError " + s3UploadError)
        console.log("numOfWorkers " + numOfWorkers)
        console.log("taskIndex " + taskIndex)
        console.log("totalTasks " + totalTasks);
        if(s3UploadError) {
          return;
        }

        if (numOfWorkers < qSize && taskIndex < totalTasks) {
          console.log("Entering " + taskIndex);
          var rangeStart = tasks[taskIndex].start;
          var end = tasks[taskIndex].end;
          console.log("rangeStart " + rangeStart)
          console.log("end " + end)
          fileUploadS3FileKey = "videos/" + videoRecordUUID + "/" + taskIndex + "." + fileExt;
          console.log("fileUploadS3FileKey " + fileUploadS3FileKey);
          let blobChunk = binaryBlob.slice(rangeStart, end);
          uploadToS3(fileUploadS3Instance, 1, fileUploadS3FileKey, 
            blobChunk, null, null, null, handleSuccessResult(taskIndex), handleFailureResult(taskIndex));
          taskIndex++;
          numOfWorkers++;
          getNextTask();

        } else if (numOfWorkers === 0 && taskIndex === totalTasks) {
            resolve(tasks);
        }
      };
      getNextTask();
    })
  });
}

function tryS3Upload(context="record", completedCallback, progressCallback, skipUI=false, mUploadProgressCallback) {
  if(!binaryBlob) {
      return false;
  }
  if(!skipUI) {
    if(context === "record") {
      document.getElementById("idVideoRecordProgressPopup").classList.remove("d-none");
      document.getElementById("idVideoRecordProgressPopup").classList.add("d-flex");
    }
    else if(context === "screen") {
      document.getElementById("idScrProgressPopupRef").classList.remove("d-none");
      document.getElementById("idScrProgressPopupRef").classList.add("d-flex");
    }
  }
  if(binaryBlob.size <= 100 * 1024 * 1024) {
    let blobChunk = binaryBlob;
    initializeS3(bucketName, bucketRegion, IdentityPoolId, function(s3Instance) {
        console.log("S3 initialized");
        console.log(s3Instance);
        let blobName = blobChunk.name;
        let fileExt = blobName.substr(blobName.lastIndexOf("."), blobName.length);
        fileUploadS3FileKey = "videos/" + videoRecordUUID + "/single" + fileExt;
        console.log("2");
        fileUploadS3Instance = s3Instance;
        uploadToS3(fileUploadS3Instance, 1, fileUploadS3FileKey, blobChunk, null, null, null,
          function(d) {
            console.log("Uploaded: " + fileUploadS3FileKey);
            videoRecordCompleted = true;
            s3UploadError = false;
            if(!skipUI) {
              if(context === "record") {
                document.getElementById("idVideoRecordProgressPopup").classList.remove("d-flex");
                document.getElementById("idVideoRecordProgressPopup").classList.add("d-none");
                hideVideoRecordError();
                showVideoReady();
              }
              else if(context === "screen") {
                document.getElementById("idScrProgressPopupRef").classList.remove("d-flex");
                document.getElementById("idScrProgressPopupRef").classList.add("d-none");
                hideScreenRecordError();
                showScreenRecordReady();
              }
              Vue.$toast.success("Video is ready", toastOptions);
            }
            
            if(completedCallback) {
              completedCallback("SUCCESS")
            }
          },
          function(err) {
            console.log("Upload error 2")
            s3UploadError = true;
            if(!skipUI) {
              Vue.$toast.error("Error in upload", toastOptions);
              if(context === "record") {
                document.getElementById("idVideoRecordProgressPopup").classList.remove("d-flex");
                document.getElementById("idVideoRecordProgressPopup").classList.add("d-none");
                hideVideoReady();
                showVideoRecordError();
              }
              else if(context === "screen") {
                document.getElementById("idScrProgressPopupRef").classList.remove("d-flex");
                document.getElementById("idScrProgressPopupRef").classList.add("d-none");
                hideScreenRecordError();
                showScreenRecordError();
              }
            }
            console.log("Error uploading: " + fileUploadS3FileKey);
            if(completedCallback) {
              completedCallback("FAILED")
            }
        },
        progressCallback)
    },
    function(err) {
        console.log("Herror");
        console.log(err)
        completedCallback("FAILED");
    });
  }
  else {
    let mUploader = S3MultipartQueueUpload(4, mUploadProgressCallback)
    if(mUploader) {
      mUploader.then(d=> {
        videoRecordCompleted = true;
        fileUploadCompleted = true;
        s3UploadError = false;
        if(!skipUI) {
          if(context === "record") {
            document.getElementById("idVideoRecordProgressPopup").classList.remove("d-flex");
            document.getElementById("idVideoRecordProgressPopup").classList.add("d-none");
            hideVideoRecordError();
            showVideoReady();
          }
          else if(context === "screen") {
            document.getElementById("idScrProgressPopupRef").classList.remove("d-flex");
            document.getElementById("idScrProgressPopupRef").classList.add("d-none");
            hideScreenRecordError();
            showScreenRecordReady();
          }
          else if(context == "upload") {
            document.getElementById("idFileUploadProgressLabel").textContent = "Upload Completed.";
            Vue.$toast.success("Upload completed", toastOptions);
            hideFileUploadError();
            hideFileUploadProgress();
            hideFileUploadInfoPanel();
            showFileUploadReady();
          }
          Vue.$toast.success("Video is ready", toastOptions);
        }
        
        if(completedCallback) {
          completedCallback("SUCCESS")
        }

      }).catch(err => {
        s3UploadError = true;
        fileUploadCompleted = false;
        if(!skipUI) {
          Vue.$toast.error("Error in upload", toastOptions);
          if(context === "record") {
            document.getElementById("idVideoRecordProgressPopup").classList.remove("d-flex");
            document.getElementById("idVideoRecordProgressPopup").classList.add("d-none");
            hideVideoReady();
            showVideoRecordError();
          }
          else if(context === "screen") {
            document.getElementById("idScrProgressPopupRef").classList.remove("d-flex");
            document.getElementById("idScrProgressPopupRef").classList.add("d-none");
            hideScreenRecordError();
            showScreenRecordError();
          }
          else if(context == "upload") {
            document.getElementById("idFileUploadProgressLabel").textContent = "Upload Failed.";
            Vue.$toast.error("Upload Failed", toastOptions);
            hideFileUploadReady();
            hideFileUploadProgress();
            hideFileUploadInfoPanel();
            showFileUploadError();
          }
        }
        console.log("Error uploading: " + fileUploadS3FileKey);
        if(completedCallback) {
          completedCallback("FAILED")
        }
      })
    }
    else {
      Vue.$toast.error("Please select a video to upload", toastOptions);
    }
  }
}

function cancelAllUploads() {
   return new Promise(function(resolve, reject) {
      for(var i = 0; i < uploadInstances.length; i++) {
          abortS3DirectUpload(uploadInstances[i])
      }
      resolve();
   });
}

// To merge audio sources
const mergeAudioStreams = (desktopStream, voiceStream) => {
  const context = new AudioContext();
  const destination = context.createMediaStreamDestination();
  let hasDesktop = false;
  let hasVoice = false;
  //console.log("Desktop audio stream");
  //console.log(desktopStream);
  //console.log(desktopStream.getAudioTracks());
  if (desktopStream && desktopStream.getAudioTracks().length > 0) {
    // If you don't want to share Audio from the desktop it should still work with just the voice.
    const source1 = context.createMediaStreamSource(desktopStream);
    const desktopGain = context.createGain();
    desktopGain.gain.value = 0.7;
    source1.connect(desktopGain).connect(destination);
    hasDesktop = true;
  }

  if (voiceStream && voiceStream.getAudioTracks().length > 0) {
    const source2 = context.createMediaStreamSource(voiceStream);
    const voiceGain = context.createGain();
    voiceGain.gain.value = 0.7;
    source2.connect(voiceGain).connect(destination);
    hasVoice = true;
  }

  return hasDesktop || hasVoice ? destination.stream.getAudioTracks() : [];
};

async function prepareScreenRecording(app, micAudio, desktopAudio) {
  //https://glitch.com/edit/#!/screen-record-voice?path=script.js%3A51%3A17
  //https://github.com/t-mullen/video-stream-merger
  //https://www.webrtc-experiment.com/ConcatenateBlobs/
  //https://javascriptio.com/view/409364/how-i-can-merge-audio-and-video-blob-on-javascript-jquery
  //https://github.com/webrtc/samples/tree/gh-pages/src/content/getusermedia/canvas
  //https://github.com/fbsamples/Canvas-Streaming-Example
  //https://www.youtube.com/watch?v=nCrQ1A2BEZ0
  //alert(desktopAudio)
  screenDesktopStream = await navigator.mediaDevices.getDisplayMedia({
    video: true,
    //audio: desktopAudio,
  });

  if (micAudio) {
    screenAudioStream = await navigator.mediaDevices.getUserMedia({
      audio: true
    });
    const tracks = [
      ...screenDesktopStream.getVideoTracks(),
      //...mergeAudioStreams(screenDesktopStream, screenAudioStream),
      ...screenAudioStream.getAudioTracks()
    ];

    //console.log("Tracks to add to stream", tracks);
    screenRecorderStream = new MediaStream(tracks);
  }
  else {
    const tracks = [
      ...screenDesktopStream.getVideoTracks()
    ];

    //console.log("Tracks to add to stream", tracks);
    screenRecorderStream = new MediaStream(tracks);
  }
  
}

function showFileUploadReady() {
  document.getElementById("idFileUploadReady").classList.remove("d-none");
  document.getElementById("idFileUploadReady").classList.add("d-flex");
}

function hideFileUploadReady() {
  document.getElementById("idFileUploadReady").classList.remove("d-flex");
  document.getElementById("idFileUploadReady").classList.add("d-none");
}

function showFileUploadError() {
  document.getElementById("idFileUploadError").classList.remove("d-none");
  document.getElementById("idFileUploadError").classList.add("d-flex");
}

function hideFileUploadError() {
  document.getElementById("idFileUploadError").classList.remove("d-flex");
  document.getElementById("idFileUploadError").classList.add("d-none");
}

function showFileUploadProgress() {
  document.getElementById("idFileUploadProgressLabel").classList.remove("d-none");
  document.getElementById("idFileUploadProgressLabel").classList.add("d-flex");
}

function hideFileUploadProgress() {
  document.getElementById("idFileUploadProgressLabel").classList.remove("d-flex");
  document.getElementById("idFileUploadProgressLabel").classList.add("d-none");
}

function showFileUploadInfoPanel() {
  document.getElementById("idFileUploadSizeLabel").classList.remove("d-none");
  document.getElementById("idFileUploadSizeLabel").classList.add("d-flex");
}

function hideFileUploadInfoPanel() {
  document.getElementById("idFileUploadSizeLabel").classList.remove("d-flex");
  document.getElementById("idFileUploadSizeLabel").classList.add("d-none");
}

function getInitialData() {
  return {
    // Keep track of the available inputs available for video.
    vidsrc: null,
    audiosrc: null,
    devices: [],

    loading: false,
    uploadContentType: "record",
    deviceReady: false,
    isRecording: false,

    submitReady: true,
    device_status: "loading",
    screenRecordPlayer: null,
    videoStart: false,
    recorder: null,
    screenDeviceReady: false,
    recordingStatus: "pending",
    screenAudioStream: null,
    screenDevices: [],
    screenAudioSrc: null,
    allowScreenRecordMic: true,
    allowScreenRecordDesktop: false,

    isRecordingSupported: false,
    isDisplaySupported: false,

    device_permission_denied: false,
    device_error: false,
    video_file_selected: false,
    pre_recorded_video_src: "",
    screen_recorded: false,
    screen_record_video_src: "",
    submit_ok_button_text: "Okay",
    recording_title: "",
    new_recording_error: "",
    start_recording_label: "Start Recording",
    notif_duration: null,
    show_countdown_timer: false,
    countdown_number: 4,
    settings_sidebar_open: true,
    render_video_components: false,

    file_upload_note: "",
    file_upload_size_error: ""
  };
}
  
  export default {
    name: "VideoFileUploaderComponent",
    components: {
      VideoPlayerObsolete
    },
    computed: {
      screenAudioDevices: function () {
        return this.screenDevices.filter(function (d) {
          return d.kind === "audioinput";
        });
      },
    },
    data() {
      return getInitialData();
    },
    methods: {
      retryS3Upload(context) {
          //alert(1)
          cancelAllUploads().then(function() {
            uploadInstances = [];
            console.log("Aborted")
          }).catch(function(err) {});
          s3UploadError = false;
          fileUploadCompleted = false;
          if(context === "upload") {
            return this.uploadVideoFile();
          }
        },
      uploadVideoFile: function () {
          let that = this;
          fileUploadCompleted = false;

          that.$emit("started");

          if(videoRecordUUID) {
            api.cancelVideoInstruction({uuid: videoRecordUUID}).catch(function(err) {});
          }

          videoRecordUUID = uuid.v4();

          let sizeInMB = binaryBlob.size / (1024 * 1024);
          that.$refs.fileUploadSizeLabel.textContent =
            "Size: " + Math.ceil(sizeInMB) + "MB";

          showFileUploadProgress();
          showFileUploadInfoPanel();

          var fileURL = URL.createObjectURL(binaryBlob);
          that.video_file_selected = true;
          that.pre_recorded_video_src = fileURL;
          api.createVideoInstruction({ uuid: videoRecordUUID }).catch(function(err) {});
          that.render_video_components = true;
          recordingProcessStarted = true;
          //disableRecordingOptions();
          hideFileUploadReady();
          hideFileUploadError();
          var fileURL = URL.createObjectURL(binaryBlob);
          that.$refs.fileUploadProgressLabel.textContent = "Reading File";
          s3UploadError = false;

          if(binaryBlob.size <= 100 * 1024 * 1024) {
            let completeCallback = status => {
                that.$refs.fileUploadSizeLabel.textContent = "";
                if(status === "SUCCESS") {
                    // Now finish the uploading
                    var instruction = {
                        key: "videos/" + videoRecordUUID,
                        fileId: videoRecordUUID,
                        context:"upload" 
                    };
                    var buf = Buffer.from(JSON.stringify(instruction));

                    initializeS3(bucketName, bucketRegion, IdentityPoolId, function(s3Instance) {
                        fileUploadS3FileKey = "videos/" + videoRecordUUID + "/index.json";
                        fileUploadS3Instance = s3Instance;

                        uploadToS3(fileUploadS3Instance, 1, fileUploadS3FileKey, buf, null, null, null,
                          function(d) {
                            fileUploadCompleted = true;
                            s3UploadError = false;
                            that.$refs.fileUploadProgressLabel.textContent = "Upload Completed.";
                            Vue.$toast.success("Upload completed", toastOptions);
                            hideFileUploadError();
                            hideFileUploadProgress();
                            hideFileUploadInfoPanel();
                            showFileUploadReady();
                          },
                          function(err) {
                            s3UploadError = true;
                            that.$refs.fileUploadProgressLabel.textContent = "Upload Failed.";
                            Vue.$toast.error("Upload Failed", toastOptions);
                            hideFileUploadReady();
                            hideFileUploadProgress();
                            hideFileUploadInfoPanel();
                            showFileUploadError();
                          })
                      });
                }
                else {
                  that.$refs.fileUploadProgressLabel.textContent = "Upload Failed.";
                  Vue.$toast.error("Upload Failed", toastOptions);
                  hideFileUploadReady();
                  hideFileUploadProgress();
                  hideFileUploadInfoPanel();
                  showFileUploadError();
                }
            }
            let progressCallback = progress => {
                showFileUploadProgress();
                that.$refs.fileUploadProgressLabel.textContent = "Uploading(" + progress + "%)";
            }
            tryS3Upload("upload", completeCallback, progressCallback);
          }
          else {
            let mUploadProgressCallback = (total, uploaded, progressStatusText) => {
                if(progressStatusText) {
                  that.$refs.fileUploadProgressLabel.textContent = progressStatusText;
                }
            }
            let mUploader = S3MultipartQueueUpload(4, mUploadProgressCallback)
            if(mUploader) {
              mUploader.then(d=> {
                // Now finish the uploading
                var instruction = {
                    key: "videos/" + videoRecordUUID,
                    fileId: videoRecordUUID,
                    context:"upload" 
                };
                var buf = Buffer.from(JSON.stringify(instruction));

                initializeS3(bucketName, bucketRegion, IdentityPoolId, function(s3Instance) {
                    fileUploadS3FileKey = "videos/" + videoRecordUUID + "/index.json";
                    fileUploadS3Instance = s3Instance;

                    uploadToS3(fileUploadS3Instance, 1, fileUploadS3FileKey, buf, null, null, null,
                      function(d) {
                        fileUploadCompleted = true;
                        s3UploadError = false;
                        that.$refs.fileUploadProgressLabel.textContent = "Upload Completed.";
                        Vue.$toast.success("Upload completed", toastOptions);
                        hideFileUploadError();
                        hideFileUploadProgress();
                        hideFileUploadInfoPanel();
                        showFileUploadReady();
                      },
                      function(err) {
                        s3UploadError = true;
                        that.$refs.fileUploadProgressLabel.textContent = "Upload Failed.";
                        Vue.$toast.error("Upload Failed", toastOptions);
                        hideFileUploadReady();
                        hideFileUploadProgress();
                        hideFileUploadInfoPanel();
                        showFileUploadError();
                      })
                  });

              }).catch(err => {
                  s3UploadError = true;
                  that.$refs.fileUploadProgressLabel.textContent = "Upload Failed.";
                  Vue.$toast.error("Upload Failed", toastOptions);
                  hideFileUploadReady();
                  hideFileUploadProgress();
                  hideFileUploadInfoPanel();
                  showFileUploadError();
              })
            }
            else {
              Vue.$toast.error("Please select a video to upload", toastOptions);
            }
          }
        },
    },
    created() {
      this.moment = moment;
    },
    beforeDestroy() {
      
    },
    mounted() {
      AWS.config.httpOptions.timeout = 0;
      
      let that = this;
      Dropzone.autoDiscover = false;
      dropzone = null;
      this.video_file_selected = false;

      dropzone = new Dropzone("div#idPrerecordedVideo", {
          url: "/dummy",
          autoProcessQueue: false,
          maxFilesize: maxFileUploadSize, // MB
          maxFiles: 1,
          addRemoveLinks: true,
          disablePreviews: false,
          dictRemoveFile: "Remove",
          previewTemplate: `<div class="dz-preview dz-file-preview well" id="dz-preview-template">
                    <div class="dz-details p-3 mt-2">
                            <div class="dz-filename pt-2 pb-2"><span data-dz-name></span></div>
                            <div class="dz-size" data-dz-size></div>
                    </div>
                    <div class="dz-success-mark"><span></span></div>
                    <div class="dz-error-mark"><span></span></div>
                    <div class="dz-error-message"><span data-dz-errormessage></span></div>
            </div>`,
          acceptedFiles:
            "video/mp4,video/x-matroska,video/x-flv,video/3gpp,video/x-ms-wmv,video/x-msvideo",
          dictDefaultMessage:
            "<div style='font-weight:900;color:#565656;'>Click here or drag & drop to upload videos</div><p class='mt-3'>Following file format are supported.</p><p>(.flv, .mp4, .3gp, .mov, .avi, .wmv)</p>",
          addedfile: function (file, done) {
            if (file.size > (1024 * 1024 * maxFileUploadSize))
                {
                    this.removeFile(file);
                    that.file_upload_size_error = "File size must not exceed " + maxFileUploadSize + " MB";
                    //return done(this.options.dictFileTooBig.replace("{{filesize}}", Math.round(file.size / 1024 / 10.24) / 100).replace("{{maxFilesize}}", this.options.maxFilesize));
                }
            else {
              that.file_upload_size_error = "";
              binaryBlob = file;
              that.uploadVideoFile();
            }
          },
        });
    },
  }
  </script>
  <style scoped>
  .dropzone {
    text-align: center;
    padding: 20px;
    border: 3px dashed #eeeeee!important;
    background-color: #fafafa!important;
    color: #bdbdbd;

    margin-bottom: 20px;
  }

  .accept {
    border-color: #107c10 !important;
  }

  .reject {
    border-color: #d83b01 !important;
  }

  #idVideoFileUploadContainer {
     width: 800px;
     -webkit-animation: fadeIn 1s;
     animation: fadeIn 1s;
     border-radius: 10px;
  }

  @-webkit-keyframes fadeIn {
      from { opacity: 0; }
        to { opacity: 1; }
  }
  @keyframes fadeIn {
      from { opacity: 0; }
        to { opacity: 1; }
  }

  @media only screen and (max-width: 767px) {
    #idVideoFileUploadContainer {
      width: 100%;
    }
  }
  </style>
  
  