import Validations from './Validations';

export default class SignupValidations {
    constructor(email, password) {
        this.email = email;
        this.password = password;
    }

    checkValidations() {
        let errors = [];

        //email validations
        if (!Validations.checkEmail(this.email)) {
            errors['email'] = 'Enter a valid email';
        }

        if(!this.password) {
            errors['password'] = 'Enter the password';
        }
        else if (!Validations.minLength(this.password, 6)) {
            errors['password'] = 'Password need to be at least 6 characters long';
        }

        return errors;
    }
}