<template>
  <div>
    <video ref="videoPlayer" class="video-js vjs-16-9 vjs-default-skin vjs-sve vjs-big-play-centered" oncontextmenu="return false;">
      <source v-for="(source, i) in options.sources" :key="i" :src="source.src" :type="source.type">
    </video>
  </div>
</template>

<script>
import Vue from "vue";
import "video.js/dist/video-js.css";
import videojs from "video.js";

export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    //console.log(this.options);

    let that = this;

    that.player = videojs(this.$refs.videoPlayer, this.options, () => {
      that.player.log("onPlayerReady", this);
    });

    that.player.on("waiting", function ()
    {
      console.log("Waiting");
      that.$emit("waiting", this.currentTime());
    });
    that.player.on("playing", function ()
    {
      console.log("Playing");
      that.$emit("playing", this.currentTime());
    });

    that.player.on("play", function ()
    {
        console.log("Play")
        //console.log(that.player.currentTime());
        that.$emit("play", this.currentTime());
    });

    that.player.on("pause", function ()
    {
      console.log("Pause");
      that.$emit("pause", this.currentTime());
    });

    that.player.on("ended", function ()
    {
      console.log("ended");
      that.$emit("ended", this.currentTime());
    });

    //that.player.play()

    this.player.on("timeupdate", function (e) {
      //console.log("timeupdate");
      //console.log(this.currentTime());
      //console.log(e);
      //window.e = e;
      //that.$emit("watchProgress", this.currentTime());
      //console.log("timeupdate");
      //console.log(this.player.currentTime);
    });

    this.player.on("progress", function (e) {
      //console.log(e);
      //console.log("progress");
      //console.log(this.player.currentTime);
    });

  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
<style scoped>
/* Change all text and icon colors in the player. */
.vjs-sve.video-js {
  color: #ffd602;
  margin-left: auto;
  margin-right: auto;
}

/* Change the border of the big play button. */
.vjs-sve .vjs-big-play-button {
  border-color: #ffd602;
}

/* Change the color of various "bars". */
.vjs-sve .vjs-volume-level,
.vjs-sve .vjs-play-progress,
.vjs-sve .vjs-slider-bar {
  background: #ffd602;
}
@media only screen and (max-width: 767px) {
  .vjs-sve.video-js {
    width: 100% !important;
    max-width: 100% !important;
    /* height: 0!important; */
  }
}
</style>
