// eslint-disable-next-line no-unused-vars
import Cookies from "js-cookie";
import moment from "moment";
import store from "../store";
import globalVars from "../global";

function getTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

function removeNulls(j /*JSON*/) {
  for (var k in j) {
    if (j[k] === null) {
      delete j[k];
    }
  }
  return j;
}

export function applydefaults(j, def) {
  for (var k in j) {
    if (j[k] === null) {
      j[k] = def[k];
    }
  }
  return j;
}

function getCommonHeaders() {
  let _headers = {
    "Content-Type": "application/json",
    Authorization: "Token " + localStorage.getItem("sve_token"),
    ctz: getTimezone(),
  };

  if (Cookies.get("csrftoken")) {
    _headers["X-CSRFToken"] = Cookies.get("csrftoken");
  }

  return _headers;
}

async function checkauth(r) {
  //console.log(r);
  if (!r) {
    return {};
  }
  const j = await r.json();
  if (j.detail === "Invalid token.") {
    window.location.href = "/login";
    throw "Redirecting user due to invalid session.";
  }
  return j;
}

async function postProcessAPIResponse(r) {
  if(typeof r === "undefined") {
    return false;
  }
  store.commit('setAPIFetchError', false);
  //alert(r.status);
  //window.r = r;
  //console.log(r);
  //console.log(r.status);
  //alert(1);
  let j = {};
  try {
    j = await r.json();
    j["http_status"] = r.status;
    if (j.detail === "Invalid token.") {
      window.localStorage.removeItem("sve_token");
      window.localStorage.removeItem("u");
      window.location.href = "/login?apierror=true";
      throw "Redirecting user due to invalid session.";
    }
  } catch (e) {
    //console.log("");
  }

  if (r.status === 204) {
    store.commit('setGlobalAPIResponseError', null);
    j["status"] = "SUCCESS";
    j["message"] = "DELETED";
    return j;
  } else if (r.status === 403) {
    window.location.href = "/permission-denied";
    store.commit('setGlobalAPIResponseError', 403);
  } else if (r.status === 429) {
    window.location.href = "/too-many-requests";
    store.commit('setGlobalAPIResponseError', 429);
    return {
      code: 429,
      message: "Too many requests. Please try little slow or later.",
    };
  } else if (r.status === 404) {
    window.location.href = "/not-found";
    store.commit('setGlobalAPIResponseError', 404);
  } else if (r.status === 500) {
    //window.location.href = "/server-error";
    store.commit('setGlobalAPIResponseError', 500);
  } else {
    store.commit('setGlobalAPIResponseError', null);
    return j;
  }
}

let baseAPIUrl = globalVars.BASE_API_URL;

function ajax(method, path, data) {
  //alert(path);
  let fullURLPath = baseAPIUrl + path;
  var s = undefined;
  if (typeof data !== "undefined") {
    s = JSON.stringify(removeNulls(data));
  }
  return fetch(fullURLPath, {
    method: method,
    headers: getCommonHeaders(),
    body: s,
    //credentials: 'include'
  })
    .catch(function (e) {
      //alert(fullURLPath);
      // window.location.href = "/server-error";
       if(e.toString() === 'TypeError: Failed to fetch') {
          //Show popup dialog saying fetch failed error.
          store.commit('setAPIFetchError', true);
       }
    })
    .then(postProcessAPIResponse);
}

function get(path, params) {
  let param_list = [];
  for (var k in params) {
    param_list.push(k + "=" + params[k]);
  }
  let apiPath = path;
  let urlParam = "";
  if (param_list.length > 0) {
    urlParam = param_list.join("&");
    apiPath += "?" + urlParam;
  }
  return ajax("GET", apiPath, undefined /*data*/);
}

function patch(path, data) {
  return ajax("PATCH", path, data);
}
function put(path, data) {
  return ajax("PUT", path, data);
}
function post(path, data) {
  return ajax("POST", path, data);
}
function deleteAPI(path) {
  return ajax("DELETE", path, undefined);
}

export function sendVideo(args) {
  const fd = new FormData();
  //fd.append('video_file_name', args.video_file_name);
  fd.append("video_uuid", args.video_uuid);
  fd.append("recipients", args.recipients);
  fd.append("from_email", args.from_email);
  fd.append("email_subject", args.email_subject);
  fd.append("template", args.templateId);
  fd.append("message", args.messagestr);
  fd.append("thumb_type", args.thumbnailtype);
  fd.append("video_type", args.video_type);
  fd.append("custom_thumbnail", args.custom_thumbnail);
  fd.append("preheader_text", args.preheader_text);
  fd.append("scheduled_on", args.schedule_time_formated);
  fd.append("notify_before", args.notify_before);
  fd.append("schedule", args.schedule);
  fd.append("tz_offset", args.tz_offset);

  let apiHeaders = {
    "X-CSRFToken": Cookies.get("csrftoken"),
    Authorization: "Token " + localStorage.getItem("sve_token"),
    ctz: getTimezone(),
    "Access-Control-Allow-Origin": "*",
  };
  //apiHeaders["Content-Type"] = "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
  return fetch(baseAPIUrl + "/api/send-video/", {
    method: "POST",
    headers: apiHeaders,
    body: fd,
  });
}
export function uploadVideoChunk(args) {
  const fd = new FormData();
  fd.append("video_file_name", args.video_file_name);
  fd.append("video_blob", args.blob);
  fd.append("uuid", args.uuid);

  let apiHeaders = {
    "X-CSRFToken": Cookies.get("csrftoken"),
    Authorization: "Token " + localStorage.getItem("sve_token"),
    ctz: getTimezone(),
    "Access-Control-Allow-Origin": "*",
  };
  //apiHeaders["Content-Type"] = "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"

  return fetch(baseAPIUrl + "/api/upload-video-chunk/", {
    method: "POST",
    headers: apiHeaders,
    body: fd,
  });
}

export function createVideoInstruction(args) {
  const fd = new FormData();
  fd.append("uuid", args.uuid);

  let apiHeaders = {
    "X-CSRFToken": Cookies.get("csrftoken"),
    Authorization: "Token " + localStorage.getItem("sve_token"),
    ctz: getTimezone(),
    "Access-Control-Allow-Origin": "*",
  };

  return fetch(baseAPIUrl + "/api/create-video/", {
    method: "POST",
    headers: apiHeaders,
    body: fd,
  });
}

export function cancelVideoInstruction(args) {
  const fd = new FormData();
  fd.append("uuid", args.uuid);

  let apiHeaders = {
    "X-CSRFToken": Cookies.get("csrftoken"),
    Authorization: "Token " + localStorage.getItem("sve_token"),
    ctz: getTimezone(),
    "Access-Control-Allow-Origin": "*",
  };

  return fetch(baseAPIUrl + "/api/cancel-video/", {
    method: "POST",
    headers: apiHeaders,
    body: fd,
  });
}

export function uploadVideoChunkFinalize(args) {
  const fd = new FormData();
  fd.append("video_file_name", args.video_file_name);
  fd.append("uuid", args.uuid);

  let apiHeaders = {
    "X-CSRFToken": Cookies.get("csrftoken"),
    Authorization: "Token " + localStorage.getItem("sve_token"),
    ctz: getTimezone(),
    "Access-Control-Allow-Origin": "*",
  };
  //apiHeaders["Content-Type"] = "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"

  return fetch(baseAPIUrl + "/api/upload-video-chunk-finalize/", {
    method: "POST",
    headers: apiHeaders,
    body: fd,
  });
}

export function uploadFileChunkFinalize(args) {
  const fd = new FormData();
  fd.append("video_file_name", args.video_file_name);
  fd.append("uuid", args.uuid);

  let apiHeaders = {
    "X-CSRFToken": Cookies.get("csrftoken"),
    Authorization: "Token " + localStorage.getItem("sve_token"),
    ctz: getTimezone(),
    "Access-Control-Allow-Origin": "*",
  };
  //apiHeaders["Content-Type"] = "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"

  return fetch(baseAPIUrl + "/api/upload-video-chunk-finalize/", {
    method: "POST",
    headers: apiHeaders,
    body: fd,
  });
}

export function uploadReplyVideo(args) {
  const fd = new FormData();
  fd.append("video_file_name", args.blob.name);
  fd.append("video_blob", args.blob);
  fd.append("thread", args.thread);
  fd.append("sender", args.sender);
  fd.append("text", args.text);

  let apiHeaders = {
    "X-CSRFToken": Cookies.get("csrftoken"),
    Authorization: "Token " + localStorage.getItem("sve_token"),
    ctz: getTimezone(),
  };
  //apiHeaders["Content-Type"] = "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"

  return fetch(baseAPIUrl + "/api/upload-reply-video/", {
    method: "POST",
    headers: apiHeaders,
    body: fd,
  });
}

export function uploadNewRecordingVideo(args) {
  const fd = new FormData();
  fd.append("video_uuid", args.video_uuid);
  fd.append("title", args.title);
  fd.append("source", args.source);
  fd.append("original_file_name", args.original_file_name);

  let apiHeaders = {
    "X-CSRFToken": Cookies.get("csrftoken"),
    Authorization: "Token " + localStorage.getItem("sve_token"),
    ctz: getTimezone(),
  };
  //apiHeaders["Content-Type"] = "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"

  return fetch(baseAPIUrl + "/api/upload-new-recording/", {
    method: "POST",
    headers: apiHeaders,
    body: fd,
  });
}

export function uploadImage(blob /*Blob*/) {
  const fd = new FormData();
  fd.append("file", blob);
  return fetch(baseAPIUrl + "/api/upload-image/", {
    method: "POST",
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken"),
      Authorization: "Token " + localStorage.getItem("sve_token"),
      ctz: getTimezone(),
    },
    body: fd,
  });
}

export function getthreads(params) {
  return get("/api/message-thread/", params);
}

export function getReplies(params) {
  return get("/api/message-thread/replies/", params);
}

export function getThreadDetail(thread_unique_id) {
  return get("/api/message-thread-detail/" + thread_unique_id + "/");
}

export function markAsRead(thread_unique_id) {
  return get("/api/markasread/" + thread_unique_id + "/");
}

export function getThreadReplies(thread_unique_id) {
  return get("/api/message-thread-detail/" + thread_unique_id + "/replies/");
}

export function getVerifiedEmails(params) {
  let url = "/api/verified-email/";
  if (params) {
    let qparams = [];
    for (let k in params) {
      qparams.push(k + "=" + params[k]);
    }
    let qparamsStr = qparams.join("&");
    if (qparamsStr) {
      url += "?" + qparamsStr;
    }
  }
  return get(url);
}
export function getInvoices(params) {
  return get(`/api/invoices/`, params);
}

export function getRecipients(params) {
  return get("/api/recipients/", params);
}

export function submitSignupEmailVerification(signup_data) {
  return post("/api/send-verification-email/", signup_data);
}

export function changepassword(payload) {
  return post("/api/change-password/", payload);
}
export function profileUpload(payload) {
  return post("/api/upload-profile-photo/", payload);
}

export function timezoneList() {
  return get("/api/timezone-list/");
}
export function profileTimezoneList() {
  return get("/api/profile/timezone/");
}
export function profileTimezone(payload) {
  return post("/api/profile/timezone/", payload);
}

export function deleteAccount(payload) {
  return post("/api/profile/delete/", payload);
}

export function unsubscribe(payload) {
  return post("/api/unsubscribe/", payload);
}

export function notificationSubmit(payload) {
  return post("/api/notification-settings/", payload);
}
export function notificationFetchData() {
  return get("/api/notification-settings/");
}

export function verifyDomain(payload) {
  return post("/api/verified-domain/", payload);
}
export function getDashboard(payload) {
  return get("/api/dashboard/", payload);
}
export function contactForm(payload) {
  return post("/api/contact-support/", payload);
}
export function verifygetDomain(payload) {
  return get("/api/verified-domain/", payload);
}

export function verifyDeleteDomain(id) {
  return deleteAPI("/api/verified-domain/" + id + "/");
}
export function getSingleDomain(id) {
  return get("/api/verified-domain/" + id + "/");
}
export function verifyEmail(payload) {
  return post("/api/verified-email/", payload);
}
export function updateEmailVerification(payload) {
  return post("/api/update-email-verification-status/", payload);
}
export function deleteVerifiedEmails(id) {
  return deleteAPI("/api/verified-email/" + id + "/?all=true");
}

export function verifyGetSingleEmailData(id, payload) {
  return get("/api/verified-email/" + id + "/", payload);
}
export function verifyGetEmailData(payload) {
  return get("/api/verified-email/", payload);
}
export function submitPWDEmailVerification(payload) {
  return post("/api/send-pw-verification-code/", payload);
}

export function verifyPWDCode(payload) {
  return post("/api/verify-pwd-code/", payload);
}

export function updatePassword(payload) {
  return post("/api/update-password/", payload);
}

export function verifySignupEmailToken(data) {
  return get("/api/verify-signup-email-token/?token=" + data.token);
}

export function SubmitSignupComplete(signup_data) {
  return post("/api/signup/", signup_data);
}

export function SubmitLogin(login_data) {
  //alert(baseUrl);
  return post("/api/login/", login_data);
}
export function SubmitSocialLogin(login_data) {
  return post("/api/social-login/", login_data);
}
export function SubmitSocialSignup(data) {
  return post("/api/submit-social-signup/", data);
}
export function Logout() {
  return get("/api/logout/");
}
export function SubmitAddRecient(data) {
  return post("/api/recipients/", data);
}
export function submitEditRecipient(id, data) {
  return put("/api/recipients/" + id + "/", data);
}
export function GetRecipient(id) {
  return get("/api/recipients/" + id + "/");
}
export function getProfile(id) {
  return get("/api/profile/" + id + "/");
}
export function putProfile(id, payload) {
  return put("/api/profile/" + id + "/", payload);
}
export function usernameAvailability(payload) {
  return post("/api/check-username-availability/", payload);
}

export function SaveTemplate(template_payload) {
  if (template_payload.unique_id) {
    let unique_id = template_payload.unique_id;
    delete template_payload["unique_id"];
    return put("/api/templates/" + unique_id + "/", template_payload);
  } else {
    return post("/api/templates/", template_payload);
  }
}

export function FetchTemplateDetail(template_unique_id) {
  return get("/api/templates/" + template_unique_id + "/");
}

export function FetchTemplatePreview(payload) {
  return post("/api/template-preview/", payload);
}

export function StripeCreateSession(payload) {
  return post("/api/create-checkout-session/", payload);
}
export function StripeCompletedSession(payload) {
  return post("/api/checkout-session-completed/", payload);
}
export function StripeCancelSubscription(payload) {
  return post("/api/cancel-subscription/", payload);
}
export function SubscriptionStatus(payload) {
  return post("/api/subscription-status/", payload);
}
export function CustomerPortal(payload) {
  return post("/api/customer-portal/", payload);
}
// export function CustomerPortal(payload) {
//   return post(baseUrl + '/api/customer-portal/' ,payload);
// }

export function ScheduleList() {
  return get("/api/schedules/");
}
export function ScheduleDetails(payload) {
  return get(`/api/schedules/${payload}/`);
}
export function ScheduleCancelAction(payload) {
  if (payload.unique_id) {
    let unique_id = payload.unique_id;
    delete payload["unique_id"];
    return put("/api/schedules/" + unique_id + "/cancel/", payload);
  }
}

export function getDeleteSchedule(deleteId) {
  return deleteAPI("/api/schedules/" + deleteId + "/");
}

export function ScheduleChangeAction(payload) {
  if (payload.unique_id) {
    let unique_id = payload.unique_id;
    delete payload["unique_id"];
    return put("/api/schedules/" + unique_id + "/change/", payload);
  }
}
export function uploadPosterVideo(blob /*Blob*/) {
  const fd = new FormData();
  fd.append("file", blob);
  return fetch(baseAPIUrl + "/api/upload-poster-video/", {
    method: "POST",
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken"),
      Authorization: "Token " + localStorage.getItem("security-token"),
      ctz: getTimezone(),
    },
    body: fd,
  });
}

export function getTemplates(params) {
  return get("/api/templates/", params);
}

export function deleteTemplate(templateUniqueId) {
  return deleteAPI("/api/templates/" + templateUniqueId + "/");
}
export function getDeleteRecipient(deleteId) {
  return deleteAPI("/api/recipients/" + deleteId + "/");
}

export function fetchLPWatchDetail(payload) {
  return post("/api/lp-detail/", payload);
}

export function getRecordings(params) {
  return get("/api/recordings/", params);
}

export function fetchRedirectURL(payload) {
  return post("/api/fetch-redirect-link/", payload);
}

export function saveAnalytics(payload) {
  return post("/api/save-analytics/", payload);
}

export function pageReport(payload) {
  return post("/api/report-page/", payload);
}

export function importContacts(payload) {
  return post("/api/recipients/import/", payload);
}

export function fetchUnreadRepliesCount(payload) {
  return post("/api/message-stat/", payload);
}

export function getUnsubscriptionList(params) {
  return get("/api/unsubscription-list/", params);
}
