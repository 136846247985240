<template>
    <div class="d-flex flex-column justify-content-center lp-main-wrapper" style="margin: 0 auto; position: relative; background: #fff;">        
        <div style="width: 100%; background: #efefef; padding: 5px; position: relative;">
          <img id="idProgressLoader"
                      class="d-flex"
                      src="../../public/assets/images/loading.gif"
                      style="width: 60px !important; height: 60px"/>
          <video v-if="!device_permission_denied" id="kVideoRecorder" controls playsinline preload="auto" class="d-none video-js vjs-default-skin"></video> 
          <button class="btn btn-sm- btn-default d-none" id="idShowVideoSettingsOptions" style="cursor: pointer;position: absolute;top: 20px;right: 20px;font-size: 1.6rem;background: rgb(223 223 223 / 40%);border-radius: 30px;" @click="showVideoSettingsOptions()">
            <i class="fas fa-ellipsis-v"></i>
          </button>
          <div
              v-if="showVideoSettings"
              class="d-flex flex-column p-3"
              style="
                z-index: 999;
                position: absolute;
                background: rgba(255, 255, 255, 0.8);
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
              "
            >
              <div
                style="
                  margin-top: 60px;
                  max-width: 400px;
                  margin-left: auto;
                  margin-right: auto;
                "
              >
                <select
                  class="form-control"
                  v-show="deviceReady"
                  v-model="vidsrc"
                  @change="changeVideoInput"
                  style="max-width: 400px"
                >
                  <option
                    v-for="(d, i) in videoDevices"
                    :value="d.deviceId"
                    :key="i"
                  >
                    {{ d.label }}
                  </option>
                </select>
                <select
                  class="form-control mt-2"
                  v-show="deviceReady"
                  v-model="audiosrc"
                  @change="changeAudioInput"
                  style="max-width: 400px"
                >
                  <option
                    v-for="(d, i) in audioDevices"
                    :value="d.deviceId"
                    :key="i"
                  >
                    {{ d.label }}
                  </option>
                </select>
                <button
                  @click="hideVideSettings()"
                  class="btn btn-sm text-white mt-4"
                  style="
                    background: rgb(255, 214, 2) !important;
                    width: 60px;
                  "
                >
                  Ok
                </button>
              </div>
            </div>
        </div>   
        
        <button id="" class="d-none" ref="recordToggleBtn" v-show="deviceReady"
                      style="width: 70px;
                        height: 70px;
                        position: absolute;
                        left: calc(50% - 35px);
                        bottom: 60px;
                        background: white;
                        flex-wrap: nowrap;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;" @click="togglerecord">
                      <span style="display: block; width: 60px; height: 60px; border-radius: 50%; background: red;" v-show="!isRecording">
                        
                      </span>
                      <span style="display: block; width:35px; height: 35px; background: red;" v-show="isRecording">
                        
                      </span>
        </button>
        <button @click="retryS3Upload('record')" id="idRecordManualUploadBtn" class="d-none" ref="recordManualUploadBtn" v-show="deviceReady" 
        style="width: 150px;height: 45px;
                        position: absolute;
                        font-weight: 600;
                        color: #fff;
                        left: calc(50% - 75px);
                        bottom: 60px;
                        background: white;
                        flex-wrap: nowrap;
                        justify-content: center;
                        align-items: center;
                        background: rgb(73, 201, 203) !important;">
                        <span>Upload</span>
        </button>
        <div id="idRecordingProgressText" ref="recordingProgressText" style="position: absolute;top: 80px;left: calc(50% - 110px);font-size: 1.6rem;background: rgba(255, 255, 255, 0.5);padding: 10px;width: 220px;color: #000;font-weight: 600;border-radius: 10px;"
          class="d-none flex-wrap justify-content-center">
              
        </div>
        
        <div class="p-3 alert alert-danger mt-3" v-if="device_permission_denied">
        <img
            class="mr-2"
            src="../../public/assets/images/denied.png"
            width="15"
        />
        <span
            >Please allow device permission to record audio &
            video using your mic and camera.</span
        >
        </div>
        <div id="idDeviceAlreadyUsedUnavailable" class="d-none p-3 alert alert-danger mt-3">
        <img
            class="mr-2"
            src="../../public/assets/images/denied.png"
            width="15"
        />
        <span
            >The device is unavailable or already being
            used.</span
        >
        </div>

        <div
            v-if="showVideoSettings"
            class="d-flex flex-column p-3"
            style="
              z-index: 999;
              position: absolute;
              background: rgba(255, 255, 255, 0.8);
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
            "
          >
            <div
              style="
                margin-top: 60px;
                max-width: 400px;
                margin-left: auto;
                margin-right: auto;
              "
            >
              <select
                class="form-control"
                v-show="deviceReady"
                v-model="vidsrc"
                @change="changeVideoInput"
                style="max-width: 400px"
              >
                <option
                  v-for="(d, i) in videoDevices"
                  :value="d.deviceId"
                  :key="i"
                >
                  {{ d.label }}
                </option>
              </select>
              <select
                class="form-control mt-2"
                v-show="deviceReady"
                v-model="audiosrc"
                @change="changeAudioInput"
                style="max-width: 400px"
              >
                <option
                  v-for="(d, i) in audioDevices"
                  :value="d.deviceId"
                  :key="i"
                >
                  {{ d.label }}
                </option>
              </select>
              <button
                @click="hideVideSettings()"
                class="btn btn-sm text-white mt-4"
                style="
                  background: rgb(255, 214, 2) !important;
                  width: 60px;
                "
              >
                Ok
              </button>
            </div>
          </div>
    </div>
  </template>
  <script>
  import Vue from "vue";
  import { Buffer } from "buffer";
  import { uuid } from "vue-uuid";
  import moment from "moment";
  import * as api from "@/services/api";
  import globalVars from "@/global";
  
  // Video JS
  import "video.js/dist/video-js.min.css";
import videojs from "video.js";
import RecordRTC from "recordrtc";
import "videojs-record/dist/css/videojs.record.css";

import Record from "videojs-record/dist/videojs.record.js";
  
  // the following imports are only needed when you're recording
  // audio-only using the videojs-wavesurfer plugin
  import WaveSurfer from "wavesurfer.js";
  import MicrophonePlugin from "wavesurfer.js/dist/plugin/wavesurfer.microphone.js";
  WaveSurfer.microphone = MicrophonePlugin;
  
  // register videojs-wavesurfer plugin
  import "videojs-wavesurfer/dist/css/videojs.wavesurfer.css";
  
  var isOpera = !!window.opera || navigator.userAgent.indexOf("OPR/") !== -1;
  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  var isEdge = /Edge/.test(navigator.userAgent);
  
  
  let maxRecordingDuration = 5 * 60;
  let maxRecordingSize = 1024 * 1024 * 100;
  let maxTimeWaitAfterRecordingStopped = 60; // 10 seconds


  let toastOptions = {
    position: "bottom-right",
    timeout: 2000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false,
  };
  
  const options = {
    controls: true,
    liveui: true,
    width: 500,
    // height: 500,
    fluid: true,
    bigPlayButton: true,
    PictureInPictureWindow: true,
    controlBar: {
      recordToggle: false,
      trimmer: false,
      fullscreenToggle: false,
      progressControl: true,
    },
    plugins: {
      record: {
        pip: true,
        audio: true,
        video: { frameRate: { ideal: 30, max: 30 } },
        //screen: true,
        maxLength: maxRecordingDuration /*seconds*/, // 5 minutes
        maxFileSize: maxRecordingSize,
        displayMilliseconds: false,
        debug: true,
        timeSlice: 2000,
        frameWidth: 320,
        frameHeight: 240,
      },
    },
  };
  
  function applyVideoWorkaround() {
    // use correct video mimetype for opera
    if (isOpera) {
      options.plugins.record.videoMimeType = "video/webm;codecs=vp8"; // or vp9
    } else {
      // options.plugins.record.videoMimeType = 'video/webm;codecs=H264';
      // options.plugins.record.videoMimeType = 'video/mp4';
    }
  }
  
  applyVideoWorkaround();
  
  let player = null;
  let recordedBlob = null;
  let recordedBlobs = [];
  let videoRecordUUID = null;
  let totalUploadInitiated = 0;
  let totalUploadCompleted = 0;
  let fileUploadS3Instance = null;
  let fileUploadS3FileKey = null;
  let bucketName = globalVars.VIDEO_UPLOAD_BUCKET;
  let bucketRegion = "us-east-1";
  let IdentityPoolId = "us-east-1:e0bbf09b-7694-4843-9166-8abab853ba40";
  let fileUploadS3SimpleUploadRef = null;
  
  //
  let currentChunkIndex = 0;
  let s3UploadError = false;
  let streamRecordCompleted = false;
  let uploadInstances = [];
  let recordingProcessStarted = false;
  let fileUploadCompleted = false;
  let recordingStopped = false;
  let maxUploadTries = 3;
  let binaryBlob = null;
  let canUpload = false;
  let videoRecordCompleted = false;
  
  function resetGolabVariables() {
    player = null;
    recordedBlob = null;
    recordedBlobs = [];
    videoRecordUUID = null;
    totalUploadInitiated = 0;
    totalUploadCompleted = 0;
    fileUploadS3Instance = null;
    fileUploadS3FileKey = null;
    bucketName = globalVars.VIDEO_UPLOAD_BUCKET;
    bucketRegion = "us-east-1";
    IdentityPoolId = "us-east-1:e0bbf09b-7694-4843-9166-8abab853ba40";
    fileUploadS3SimpleUploadRef = null;
  
    //
    currentChunkIndex = 0;
    s3UploadError = false;
    streamRecordCompleted = false;
    uploadInstances = [];
    recordingProcessStarted = false;
    fileUploadCompleted = false;
    recordingStopped = false;
    maxUploadTries = 3;
    binaryBlob = null;
    canUpload = false;
    videoRecordCompleted = false;
  }
  
  function abortS3DirectUpload(uInstance) {
    return new Promise(function (resolve, reject) {
      try {
        uInstance.abort();
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }
  
  function initializeS3(
    bucketName,
    bucketRegion,
    IdentityPoolId,
    completedCallback,
    failedCallback
  ) {
    try {
      if (fileUploadS3Instance) {
        completedCallback(fileUploadS3Instance);
        return;
      }
      AWS.config.update({
        region: bucketRegion,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: IdentityPoolId,
        }),
      });
  
      let s3Instance = new AWS.S3({
        apiVersion: "2006-03-01",
        params: { Bucket: bucketName },
      });
      completedCallback(s3Instance);
    } catch (e) {
      failedCallback(e);
    }
  }
  
  function initializeS3Force(
    bucketName,
    bucketRegion,
    IdentityPoolId,
    completedCallback,
    failedCallback
  ) {
    try {
      AWS.config.update({
        region: bucketRegion,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: IdentityPoolId,
        }),
      });
  
      let s3Instance = new AWS.S3({
        apiVersion: "2006-03-01",
        params: { Bucket: bucketName },
      });
      completedCallback(s3Instance);
    } catch (e) {
      failedCallback(e);
    }
  }
  
  function uploadToS3(
    s3Instance,
    attempt,
    fileKey,
    blob,
    acl,
    contentEncoding,
    contentType,
    successCallback,
    failedCallback,
    progressCallback
  ) {
    if (s3UploadError) {
      failedCallback("ERROR");
      return;
    }
    var attempt = attempt || 1;
    let payload = {
      Key: fileKey,
      Body: blob,
      ACL: "public-read",
    };
    if (acl) {
      payload.ACL = acl;
    }
    if (contentEncoding) {
      payload.ContentEncoding = contentEncoding;
    }
    if (contentType) {
      payload.ContentType = contentType;
    }
    try {
      fileUploadS3SimpleUploadRef = s3Instance
        .upload(payload, function (err, data) {
          console.log(err, data);
          if (err) {
            console.log("Err");
            //videoRecordUploadCompletedCallback("FAILURE");
            //console.log(err);
            if (attempt < maxUploadTries) {
              console.log("Retrying uploadToS3 of attempt: #", attempt);
              uploadToS3(
                s3Instance,
                attempt + 1,
                fileKey,
                blob,
                acl,
                contentEncoding,
                contentType,
                successCallback,
                failedCallback,
                progressCallback
              );
            } else {
              console.log("Failed uploading file key: ", fileKey);
              failedCallback(err);
            }
          } else {
            console.log("Success");
            //videoRecordUploadCompletedCallback("SUCCESS");
            successCallback(data);
          }
        })
        .on("httpUploadProgress", function (progress) {
          var uploaded = parseInt((progress.loaded * 100) / progress.total);
          //alert(uploaded);
          if (progressCallback) {
            progressCallback(uploaded);
          }
        });
      uploadInstances.push(fileUploadS3SimpleUploadRef);
    } catch (e) {
      console.log("Gherr");
      console.log(e);
      failedCallback(e);
    }
  }
  
  function S3MultipartQueueUpload(qSize, progressCallback) {
    console.log("Inside multi part");
    if (!binaryBlob) {
      return false;
    }
    if (progressCallback) {
      progressCallback(null, null, "Initializing Upload");
    }
    var qSize = qSize || 4;
    var numOfWorkers = 0;
    var taskIndex = 0;
  
    var tasks = {};
  
    var partSize = 1024 * 1024 * 2;
    var totalParts = 0;
    for (
      var rangeStart = 0;
      rangeStart < binaryBlob.size;
      rangeStart += partSize
    ) {
      var end = Math.min(rangeStart + partSize, binaryBlob.size);
      tasks[totalParts] = {
        start: rangeStart,
        end: end,
        status: "p", //p, u, f = Pending, Uploaded, Failed
      };
      totalParts += 1;
    }
  
    console.log(tasks);
  
    var totalTasks = Object.keys(tasks).length;
    var totalSuccess = 0;
  
    let blobName = binaryBlob.name;
    var re = /(?:\.([^.]+))?$/;
    var fileExt = re.exec(blobName)[1];
    console.log("Starting");
    return new Promise((resolve, reject) => {
      initializeS3(
        bucketName,
        bucketRegion,
        IdentityPoolId,
        function (s3Instance) {
          fileUploadS3Instance = s3Instance;
  
          const handleSuccessResult = (index) => (result) => {
            console.log("handleSuccessResult " + index + " " + result);
            //console.log(result);
            totalSuccess += 1;
            let uploaded = parseInt((totalSuccess * 100) / totalTasks);
            if (progressCallback) {
              progressCallback(
                totalTasks,
                totalSuccess,
                "Uploading(" + uploaded + "%)"
              );
            }
            tasks[index].status = result;
            numOfWorkers--;
            getNextTask();
          };
  
          const handleFailureResult = (index) => (result) => {
            console.log("handleFailureResult " + index + " " + result);
            //console.log(result);
            tasks[index].status = result;
            //numOfWorkers--;
            //getNextTask();
            s3UploadError = true;
            reject();
          };
  
          const getNextTask = () => {
            console.log("getNextTask " + taskIndex);
            console.log("s3UploadError " + s3UploadError);
            console.log("numOfWorkers " + numOfWorkers);
            console.log("taskIndex " + taskIndex);
            console.log("totalTasks " + totalTasks);
            if (s3UploadError) {
              return;
            }
  
            if (numOfWorkers < qSize && taskIndex < totalTasks) {
              console.log("Entering " + taskIndex);
              var rangeStart = tasks[taskIndex].start;
              var end = tasks[taskIndex].end;
              console.log("rangeStart " + rangeStart);
              console.log("end " + end);
              fileUploadS3FileKey =
                "videos/" + videoRecordUUID + "/" + taskIndex + "." + fileExt;
              console.log("fileUploadS3FileKey " + fileUploadS3FileKey);
              let blobChunk = binaryBlob.slice(rangeStart, end);
              uploadToS3(
                fileUploadS3Instance,
                1,
                fileUploadS3FileKey,
                blobChunk,
                null,
                null,
                null,
                handleSuccessResult(taskIndex),
                handleFailureResult(taskIndex)
              );
              taskIndex++;
              numOfWorkers++;
              getNextTask();
            } else if (numOfWorkers === 0 && taskIndex === totalTasks) {
              resolve(tasks);
            }
          };
          getNextTask();
        }
      );
    });
  }
  
  function tryS3Upload(
    context = "record",
    completedCallback,
    progressCallback,
    skipUI = false,
    mUploadProgressCallback
  ) {
    if (!binaryBlob) {
      return false;
    }
    if (binaryBlob.size <= 100 * 1024 * 1024) {
      let blobChunk = binaryBlob;
      initializeS3(
        bucketName,
        bucketRegion,
        IdentityPoolId,
        function (s3Instance) {
          console.log("S3 initialized");
          console.log(s3Instance);
          let blobName = blobChunk.name;
          let fileExt = blobName.substr(
            blobName.lastIndexOf("."),
            blobName.length
          );
          fileUploadS3FileKey = "videos/" + videoRecordUUID + "/single" + fileExt;
          console.log("2");
          fileUploadS3Instance = s3Instance;
          uploadToS3(
            fileUploadS3Instance,
            1,
            fileUploadS3FileKey,
            blobChunk,
            null,
            null,
            null,
            function (d) {
              console.log("Uploaded: " + fileUploadS3FileKey);
              videoRecordCompleted = true;
              s3UploadError = false;
              if (completedCallback) {
                completedCallback("SUCCESS");
              }
            },
            function (err) {
              console.log("Upload error 2");
              s3UploadError = true;
              console.log("Error uploading: " + fileUploadS3FileKey);
              console.log(err);
              if (completedCallback) {
                completedCallback("FAILED");
              }
            },
            progressCallback
          );
        },
        function (err) {
          console.log("Herror");
          console.log(err);
          completedCallback("FAILED");
        }
      );
    } else {
      let mUploader = S3MultipartQueueUpload(4, mUploadProgressCallback);
      if (mUploader) {
        mUploader
          .then((d) => {
            videoRecordCompleted = true;
            fileUploadCompleted = true;
            s3UploadError = false;
            if (completedCallback) {
              completedCallback("SUCCESS");
            }
          })
          .catch((err) => {
            s3UploadError = true;
            fileUploadCompleted = false;
            console.log("Error uploading: " + fileUploadS3FileKey);
            if (completedCallback) {
              completedCallback("FAILED");
            }
          });
      } else {
        Vue.$toast.error("Please select a video to upload", toastOptions);
      }
    }
  }
  
  function cancelAllUploads() {
    return new Promise(function (resolve, reject) {
      for (var i = 0; i < uploadInstances.length; i++) {
        abortS3DirectUpload(uploadInstances[i]);
      }
      resolve();
    });
  }
  
  async function loadPlayer(app) {
    let recorderError = false;
    let that = app;
  
    let stream = await navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: true,
        width: { ideal: 9999 },
        height: { ideal: 9999 },
      })
      .catch(function (err) {
        recorderError = true;
      });
  
    if (recorderError) {
      return;
    }
  
    let { width, height } = stream.getTracks()[0].getSettings();
  
    options.plugins.record.video.width = width;
    options.plugins.record.video.height = height;
    options.plugins.record.frameWidth = width;
    options.plugins.record.frameHeight = height;
    // options.plugins.record.image = true;

    function initializeVideoJSPlayer(successCallback) {
      //alert(3)
      try {
        player = videojs("kVideoRecorder", options, function () {
          // print version information at startup
          var msg =
            "Using video.js " +
            videojs.VERSION +
            " with videojs-record " +
            videojs.getPluginVersion("record") +
            " and recordrtc " +
            RecordRTC.version;
          videojs.log(msg);
        });
      } catch(e) {
        //alert(e);
        return false;
      }
      

      //alert(player)
  
      //alert(6);
  
      // error handling
      player.on("deviceError", function () {
        console.warn("device error:", player.deviceErrorCode);
      });
  
      player.on("error", function (element, error) {
        console.error(error);
      });
  
      // user clicked the record button and started recording
      player.on("startRecord", async function () {
        console.log("started recording!");
        //takethumbnail(myVideo_html5_api);
        app.hasstaticthumbnail = true;
  
        if (videoRecordUUID) {
          api
            .cancelVideoInstruction({ uuid: videoRecordUUID })
            .catch(function (err) {});
        }

        document.getElementById("idShowVideoSettingsOptions").classList.remove("d-flex");
        document.getElementById("idShowVideoSettingsOptions").classList.add("d-none");
  
        videoRecordUUID = uuid.v4();
        console.log("Started record");
        //console.log(videoRecordUUID);
  
        recordedBlobs = [];
        streamRecordCompleted = false;
        cancelAllUploads()
          .then(function () {
            uploadInstances = [];
            console.log("Aborted");
          })
          .catch(function (err) {});
        recordingProcessStarted = true;
        //disableRecordingOptions();
        //hideVideoReady();
        //hideVideoRecordError();
  
        fileUploadCompleted = false;
  
        totalUploadInitiated = 0;
        totalUploadCompleted = 0;
        recordingStopped = false;

        that.record_video_src = null;

        that.$emit("started");
  
        //console.log(vm)
  
        api
          .createVideoInstruction({ uuid: videoRecordUUID })
          .catch(function (err) {});
  
        currentChunkIndex = 0;
        s3UploadError = false;
        binaryBlob = null;
      });
  
      // user clicked the record button and started recording
      player.on("progressRecord", async function (r) {
        //console.log("progressRecord recording!");
        //takethumbnail(myVideo_html5_api);
        //console.log(r);
        //window.r = r;
        //console.log(player.recordedData);
        //console.log(player.recordedData.size());
      });
      player.on("timestamp", async function (current, all) {
        if (totalUploadInitiated - totalUploadCompleted >= 5) {
          s3UploadError = true;
          cancelAllUploads()
            .then(function () {
              uploadInstances = [];
              console.log("Aborted");
            })
            .catch(function (err) {});
          return;
        }
  
        try {
          console.log("s3UploadError " + s3UploadError);
          if (s3UploadError) {
            return;
          }
          //console.log("timestamp recording!");
          //takethumbnail(myVideo_html5_api);
          //console.log(player.recordedData);
  
          let blobChunk = player.recordedData[player.recordedData.length - 1];
          if (blobChunk) {
            currentChunkIndex += 1;
            initializeS3(
              bucketName,
              bucketRegion,
              IdentityPoolId,
              function (s3Instance) {
                let blobName = blobChunk.name;
                let fileExt = blobName.substr(
                  blobName.lastIndexOf("."),
                  blobName.length
                );
                fileUploadS3FileKey =
                  "videos/" + videoRecordUUID + "/" + currentChunkIndex + fileExt;
                fileUploadS3Instance = s3Instance;
                totalUploadInitiated += 1;
                uploadToS3(
                  fileUploadS3Instance,
                  1,
                  fileUploadS3FileKey,
                  blobChunk,
                  null,
                  null,
                  null,
                  function (d) {
                    totalUploadCompleted += 1;
                    console.log("Uploaded chunk: " + currentChunkIndex);
                  },
                  function (err) {
                    s3UploadError = true;
                    console.log("Error uploading chunk: " + currentChunkIndex);
                  }
                );
              }
            );
          }
          //console.log(player.recordedData.size);
        } catch (e) {
          s3UploadError = true;
          cancelAllUploads()
            .then(function () {
              uploadInstances = [];
              console.log("Aborted");
            })
            .catch(function (err) {});
        }
      });
  
      player.on("deviceError", async function (r) {
        //User doesn't allow the browser to access the webcam and/or microphone.. https://developer.mozilla.org/en-US/docs/Web/API/Navigator/getUserMedia#errorcallback
        console.log("Error", r);
      });
  
      // user completed recording and stream is available
      player.on("finishRecord", function () {
        try {
          console.log("Finishing");
          that.$refs['recordToggleBtn'].classList.remove('d-flex');
          that.$refs['recordToggleBtn'].classList.add('d-none');
          that.$emit("finishedrecording");
          binaryBlob = player.recordedData;

          that.record_video_src = URL.createObjectURL(binaryBlob);

          that.$refs["recordingProgressText"].textContent = "Uploading";
          that.$refs["recordingProgressText"].classList.remove("d-none");
          that.$refs["recordingProgressText"].classList.add("d-flex");
          if (s3UploadError) {
            cancelAllUploads()
              .then(function () {
                uploadInstances = [];
                console.log("Aborted");
              })
              .catch(function (err) {});
            s3UploadError = false;
            fileUploadCompleted = false;

            let completedCallback = status => {
                if(status === "SUCCESS") {
                  // Now finish the uploading
                  var instruction = {
                      key: "videos/" + videoRecordUUID,
                      fileId: videoRecordUUID,
                      context:"record",
                      library: true 
                  };
                  var buf = Buffer.from(JSON.stringify(instruction));

                  initializeS3(bucketName, bucketRegion, IdentityPoolId, function(s3Instance) {
                      fileUploadS3FileKey = "videos/" + videoRecordUUID + "/index.json";
                      fileUploadS3Instance = s3Instance;

                      uploadToS3(fileUploadS3Instance, 1, fileUploadS3FileKey, buf, null, null, null,
                        function(d) {
                          console.log("Uploaded index.json");
                          fileUploadCompleted = true;
                          s3UploadError = false;
                          // hideVideoRecordError();
                          // showVideoReady();
                          document.getElementById("idRecordingProgressText").classList.remove("d-flex");
                          document.getElementById("idRecordingProgressText").classList.add("d-none");
                          //Vue.$toast.success("Video is ready", toastOptions);
                          that.$emit("uploaded",  videoRecordUUID, that.record_video_src);
                        },
                        function(err) {
                          s3UploadError = true;
                          // hideVideoReady();
                          // showVideoRecordError();
                          document.getElementById("idRecordingProgressText").classList.remove("d-flex");
                          document.getElementById("idRecordingProgressText").classList.add("d-none");
                          //Vue.$toast.error("Error in upload", toastOptions);
                          console.log("Error uploading index.json: ");
                        })
                    });
                }
                else {
                  s3UploadError = true;
                  // hideVideoReady();
                  // showVideoRecordError();
                  document.getElementById("idRecordingProgressText").classList.remove("d-flex");
                  document.getElementById("idRecordingProgressText").classList.add("d-none");
                  document.getElementById("idRecordManualUploadBtn").classList.remove("d-none");
                  document.getElementById("idRecordManualUploadBtn").classList.add("d-flex");
                  //Vue.$toast.error("Error in upload", toastOptions);
                  //that.$emit("uploadfailed");
                }
            }
            let mUploadProgressCallback = (
              total,
              completed,
              progressStatusText
            ) => {
              if (progressStatusText) {
                let progress = parseInt((completed * 100) / total);
                that.$refs["recordingProgressText"].textContent =
                  "Uploading(" + progress + "%)";
                that.$emit("uploading", progress);
              }
            };
            let progressCallback = (progress) => {
              that.$refs["recordingProgressText"].textContent =
                "Uploading(" + progress + "%)";
                that.$emit("uploading", progress);
            };
            tryS3Upload(
              "record",
              completedCallback,
              progressCallback,
              true,
              mUploadProgressCallback
            );
          } else {
            if (totalUploadInitiated === totalUploadCompleted) {
              s3UploadError = false;
              that.$refs["recordingProgressText"].classList.remove("d-flex");
              that.$refs["recordingProgressText"].classList.add("d-none");
              that.$emit("uploaded",  videoRecordUUID, that.record_video_src);
            } else {
              console.log("All files not yet uploaded. Waiting to finish...");
              let timeWaited = 0;
              let timeToWait =
                maxTimeWaitAfterRecordingStopped +
                (totalUploadInitiated - totalUploadCompleted) * 3;
              (function waitForUploadFinish() {
                if (totalUploadInitiated === totalUploadCompleted) {
                  s3UploadError = false;
                  that.$refs["recordingProgressText"].classList.remove(
                    "d-flex"
                  );
                  that.$refs["recordingProgressText"].classList.add(
                    "d-none"
                  );
                  that.$emit("uploaded",  videoRecordUUID, that.record_video_src);
                  return;
                }
                if (timeWaited >= timeToWait) {
                  console.log("Waiting is over");
                  s3UploadError = true;
                  cancelAllUploads()
                    .then(function () {
                      uploadInstances = [];
                      console.log("Aborted");
                    })
                    .catch(function (err) {});

                  that.$refs["recordingProgressText"].classList.remove(
                    "d-flex"
                  );
                  that.$refs["recordingProgressText"].classList.add(
                    "d-none"
                  );
                  document.getElementById("idRecordManualUploadBtn").classList.remove("d-none");
                  document.getElementById("idRecordManualUploadBtn").classList.add("d-flex");
                  return;
                }
                let progressPerccentage = parseInt(
                  (totalUploadCompleted * 100) / totalUploadInitiated
                );
                that.$refs["recordingProgressText"].textContent =
                  "Uploading(" + progressPerccentage + "%)";
                that.$emit("uploading", progressPerccentage);
                timeWaited += 1;
                console.log("Waiting...");
                setTimeout(waitForUploadFinish, 1000);
              })();
            }
          }
        } catch (e) {
          console.log(e);
          s3UploadError = true;
          that.$refs["recordingProgressText"].classList.remove("d-flex");
          that.$refs["recordingProgressText"].classList.add("d-none");
          document.getElementById("idRecordManualUploadBtn").classList.remove("d-none");
                  document.getElementById("idRecordManualUploadBtn").classList.add("d-flex");
          cancelAllUploads()
            .then(function () {
              uploadInstances = [];
              console.log("Aborted");
            })
            .catch(function (err) {});
            document.getElementById("idRecordManualUploadBtn").classList.remove("d-none");
            document.getElementById("idRecordManualUploadBtn").classList.add("d-flex");
        }
      });
  
      //player.trimmer();
  
      //player.trimmer();
  
      if (typeof successCallback !== "undefined") {
        successCallback();
      }
    }
  
    initializeVideoJSPlayer();
  }
  
  function firstvideodevice(devices) {
    for (let d of devices) {
      if (d.kind === "videoinput") {
        return d.deviceId;
      }
    }
    return null;
  }
  
  function firstaudiodevice(devices) {
    for (let d of devices) {
      if (d.kind === "audioinput") {
        return d.deviceId;
      }
    }
    return null;
  }
  
  function getInitialData() {
    return {
      sessionGroup: uuid.v4(),
      vidsrc: null,
      audiosrc: null,
      devices: [],
      isRecording: false,
      deviceReady: false,
      video_upload_error: false,
      playbackReady: false,
      replyVideoPlaybackReady: false,
      device_error: false,
      device_status: "loading",
      recordingCompleted: false,
      startRecordingLabel: "Start Recording",
      showVideoSettings: false,
      device_permission_denied: false,
      record_video_src: null
    };
  }
  
  export default {
    name: "CameraRecorderComponent",
    components: {
      
    },
    computed: {
      audioDevices: function () {
        return this.devices.filter(function (d) {
          return d.kind === "audioinput";
        });
      },
      videoDevices: function () {
        //console.log(this.devices);
        return this.devices.filter(function (d) {
          return d.kind === "videoinput";
        });
      },
    },
    data() {
      return getInitialData();
    },
    methods: {
      showVideoSettingsOptions() {
        this.showVideoSettings = true;
      },
      hideVideSettings() {
        this.showVideoSettings = false;
        document.getElementById("idShowVideoSettingsOptions").classList.remove("d-none");
        document.getElementById("idShowVideoSettingsOptions").classList.add("d-flex");
      },
      retryS3Upload(context) {
        //alert(1)
        let that = this;
        cancelAllUploads().then(function() {
          uploadInstances = [];
          console.log("Aborted")
        }).catch(function(err) {});
        s3UploadError = false;
        fileUploadCompleted = false;
        if(context === "record") {
          let mUploadProgressCallback = (total, completed, progressStatusText) => {
              if(progressStatusText) {
                let progress = parseInt(
                    (completed * 100) / total
                  );
                  document.getElementById("idRecordingProgressText").textContent = "Uploading(" + progress + "%)";
              }
          }
          document.getElementById("idRecordingProgressText").classList.remove("d-none");
          document.getElementById("idRecordingProgressText").classList.add("d-flex");
          document.getElementById("idRecordingProgressText").textContent = "Uploading";
          let completedCallback = status => {
              if(status === "SUCCESS") {
                // Now finish the uploading
                var instruction = {
                    key: "videos/" + videoRecordUUID,
                    fileId: videoRecordUUID,
                    context:"record",
                    library: true 
                };
                var buf = Buffer.from(JSON.stringify(instruction));

                initializeS3(bucketName, bucketRegion, IdentityPoolId, function(s3Instance) {
                    fileUploadS3FileKey = "videos/" + videoRecordUUID + "/index.json";
                    fileUploadS3Instance = s3Instance;

                    uploadToS3(fileUploadS3Instance, 1, fileUploadS3FileKey, buf, null, null, null,
                      function(d) {
                        console.log("Uploaded index.json");
                        fileUploadCompleted = true;
                        s3UploadError = false;
                        that.$emit("uploaded",  videoRecordUUID, that.record_video_src);
                        // hideVideoRecordError();
                        // showVideoReady();
                        document.getElementById("idRecordingProgressText").classList.remove("d-flex");
                        document.getElementById("idRecordingProgressText").classList.add("d-none");
                        Vue.$toast.success("Upload completed", toastOptions);
                      },
                      function(err) {
                        s3UploadError = true;
                        that.$emit("uploadfailed");
                        // hideVideoReady();
                        // showVideoRecordError();
                        document.getElementById("idRecordingProgressText").classList.remove("d-flex");
                        document.getElementById("idRecordingProgressText").classList.add("d-none");
                        Vue.$toast.error("Upload failed", toastOptions);
                        console.log("Error uploading index.json: ");
                      })
                  });
              }
              else {
                s3UploadError = true;
                that.$emit("uploadfailed");
                // hideVideoReady();
                // showVideoRecordError();
                document.getElementById("idRecordingProgressText").classList.remove("d-flex");
                document.getElementById("idRecordingProgressText").classList.add("d-none");
                Vue.$toast.error("Upload failed", toastOptions);
              }
          }
          let progressCallback = progress => {
              document.getElementById("idRecordingProgressText").textContent = "Uploading(" + progress + "%)";
          }
          tryS3Upload("record", completedCallback, progressCallback, true, mUploadProgressCallback);
        }
      },
      resetRecordingVariables() {
        this.vidsrc = null;
        this.audiosrcv;
        this.devices = [];
        this.isRecording = false;
        this.deviceReady = false;
        this.video_upload_error = false;
        this.disable_sending = false;
        this.device_permission_denied = false;
        this.device_error = false;
        this.device_status = "loading";
      },
      loadRecorder(app) {
        let that = app;
        //console.log("loadRecorder with: ");
        //console.log(that);
        loadPlayer(that)
          .then(function () {
            that.device_status = "ready";
            that.$refs["recordToggleBtn"].classList.remove("d-none");
            that.$refs["recordToggleBtn"].classList.add("d-flex");

            document.getElementById("kVideoRecorder").classList.remove("d-none");
            document.getElementById("kVideoRecorder").classList.add("d-flex");

            document.getElementById("idProgressLoader").classList.remove("d-flex");
            document.getElementById("idProgressLoader").classList.add("d-none");

            document.getElementById("idShowVideoSettingsOptions").classList.remove("d-none");
            document.getElementById("idShowVideoSettingsOptions").classList.add("d-flex");
            
            player.on("deviceReady", function () {
              if (that.devices.length === 0) {
                player.record().enumerateDevices();
              }
            });
            player.on("enumerateReady", function () {
              that.devices = player.record().devices;
              //console.log("Devices: ");
              //console.log(that.devices);
              that.deviceReady = true;
              if (!that.vidsrc) {
                that.vidsrc = firstvideodevice(that.devices);
              }
              if (!that.audiosrc) {
                that.audiosrc = firstaudiodevice(that.devices);
              }
            });
            that.deviceReady = true;
            player.record().getDevice();
          })
          .catch(function (err) {
            if (err.message === "Permission denied") {
              that.device_permission_denied = true;
            } else {
              that.device_permission_denied = false;
            }
            that.device_status = "failed";
            that.$refs["recordToggleBtn"].classList.remove("d-flex");
            that.$refs["recordToggleBtn"].classList.add("d-none");
          });
      },
      
      changeVideoInput: function (e) {
        if (this.previewMode) {
          return false;
        }
        const deviceId = e.target.value;
        try {
          // change video input device
          player.record().setVideoInput(deviceId);
        } catch (error) {
          e.target.selectedIndex = 0;
        }
      },
      changeAudioInput: function (e) {
        if (this.previewMode) {
          return false;
        }
        const deviceId = e.target.value;
        try {
          // change video input device
          player.record().setAudioInput(deviceId);
        } catch (error) {
          e.target.selectedIndex = 0;
        }
      },
      togglerecord: function (e) {
        if (this.previewMode) {
          return false;
        }
        e.preventDefault();
        if (player.record().isRecording()) {
          player.record().stop();
        } else {
          player.record().start();
        }
        this.isRecording = player.record().isRecording();
      },      
      getUserTZ: function () {
        return this.$moment.tz.guess();
      },
      initRecording: async function () {
        this.device_permission_denied = false;
        let that = this;
        that.deviceReady = false;
        that.isRecording = false;
        
        resetGolabVariables();

        let recorderError = false;

        await navigator.mediaDevices
            .getUserMedia({
            video: true,
            audio: true,
            width: { ideal: 9999 },
            height: { ideal: 9999 },
            })
            .catch(function (err) {
                recorderError = true;
                if (err.message === "Permission denied") {
                    that.device_permission_denied = true;
                } else {
                    that.device_permission_denied = false;
                }
            });

        if (recorderError) {
            return;
        }

        that.loadRecorder(that);
      },
    },
    created() {
      this.moment = moment;
    },
    beforeDestroy() {
      if (player) {
        player.dispose();
      }
      if (videojs.getPlayers()["kVideoRecorder"]) {
        delete videojs.getPlayers()["kVideoRecorder"];
      }
    },
    mounted() {
        AWS.config.httpOptions.timeout = 0;
        this.initRecording();
    },
  };
  </script>
  <style scoped>
  #kVideoRecorder {
    width: 800px;
  }
  @media only screen and (max-width: 767px) {
    #kVideoRecorder {
      width: 350px;
    }
  }
  </style>
  
  