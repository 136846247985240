let BASE_URL = process.env.VUE_APP_FRONTEND_URL;
let BASE_API_URL = process.env.VUE_APP_API_URL;
let VIDEO_UPLOAD_BUCKET = process.env.VUE_APP_VIDEO_UPLOAD_BUCKET;

let MAX_INIFINITY = "9999999999999";

let GOOGLE_CLIENT_ID =
  "1004695588384-mkckmn2nsl4m4438f7f1cirquvcvokmr.apps.googleusercontent.com";

module.exports = {
  BASE_URL,
  BASE_API_URL,
  GOOGLE_CLIENT_ID,
  MAX_INIFINITY,
  VIDEO_UPLOAD_BUCKET
};
