<template>
  <div
    id="idFormLoginWrapper"
    class="d-flex flex-wrap justify-content-center w-100"
    style=""
  >
    <div
      style="background: #fff;"
    >
      
      <div id="idLoginForm" class="vh-100" style="padding: 30px; background: #fff">
        <p class="w-100 d-flex flex-wrap justify-content-center">
          <img
            style="width: 200px; height: auto; margin-bottom: 30px;margin-left: auto; margin-right: auto;"
            src="../../public/assets/images/logo1.png"
          />
        </p>
        <h4
          style="
            font-size: 2.5rem;
            font-weight: 600;
            letter-spacing: 0.03rem;
            text-align: center;
          "
        >
          Login
        </h4>
        <div class="d-flex flex-column mt-5">
          <div>
            <input
              class="form-control p-4"
              v-model="email"
              placeholder="Enter your email"
              id="email"
              type="email"
              autocomplete="on"
              required="required"
              autofocus
              ref="refLoginEmail"
            />
          </div>
          <p class="alert alert-danger mt-2" v-if="errors.email">
            {{ errors.email }}
          </p>
        </div>

        <div class="d-flex flex-column mt-3">
          <div>
            <input
              class="form-control p-4"
              v-model="password"
              placeholder="Password"
              id="password"
              type="password"
              autocomplete="off"
              required="required"
              ref="refloginPassword"
            />
          </div>
          <p class="alert alert-danger mt-2" v-if="errors.password">
            {{ errors.password }}
          </p>
          <p class="alert alert-danger mt-2" v-if="errorMessage">
            {{ errorMessage }}
          </p>
          <p class="mt-2" style="letter-spacing: 0.03rem; text-align: center">
            Forgot your password?
            <router-link class="login-reset" :to="{ path: `/password-reset` }">
              Reset now
            </router-link>
          </p>
        </div>
        <div class="d-flex flex-column justify-content-center">
          <div class="form-group">
            <b-button
              @click="submitLogin"
              variant="primary btn btn-primary form-control mt-4 submit-btn"
            >
              <b-spinner v-show="isUserLoading" small type="grow"></b-spinner>
              <span v-show="isUserLoading"> {{ loginText }} </span>

              <span v-show="!isUserLoading"> {{ loginText }} </span>
            </b-button>
          </div>
          <div class="d-flex flex-wrap justify-content-center p-2">
            <span style="opacity: 0.7; letter-spacing: 0.03rem">OR</span>
          </div>
          <button
            class="form-control d-flex flex-wrap vh-100 align-items-center justify-content-center"
            style="
              height: 40px !important;
              letter-spacing: 0.03rem;
              text-transform: uppercase;
              font-size: 0.9rem;
              font-weight: 600;
            "
            @click="submitGoogleLogin"
          >
            <img
              class="mr-2"
              src="../../public/assets/images/GoogleLogo.webp"
              width="20"
              height="20"
            />
            <span>Sign In using Google</span>
          </button>
        </div>
        <p class="mt-2" style="letter-spacing: 0.03rem; text-align: center">
          Don't have any account yet? Please
          <router-link class="login-reset" :to="{ path: `/signup` }">
            register
          </router-link>
        </p>
        <div
            class="pt-5 text-center pb-5"
            style="font-size: 0.875rem; line-height: 1.3rem"
          >
            <a class="terms" href="https://kimpod.com/terms.html">Terms of Service</a>
            <a
              class="terms ml-2 ml-3"
              href="https://kimpod.com/privacy.html">Privacy policy</a>
            <a class="terms ml-2 ml-3" href="https://kimpod.com/#how-work">How it works</a>
            <p class="text-center mt-3" style="color: #595959">
              For any support -
              <a href="mailto:support@kimpod.com">support@kimpod.com</a>
            </p>
            <div class="text-center pb-1 pr-1 mt-3" style="color: #595959">
              Copyright &copy; 2023 Kimpod. All rights reserved.
            </div>            
          </div>
      </div>
    </div>

    <b-modal
      ref="authSignupModal"
      title="Complete signup to proceed"
      no-close-on-backdrop
      centered
      hide-footer
    >
      <div class="d-block text-center mb-4">
        <p class="mb-4" style="font-size: 1.6rem; font-weight: 600">
          {{ modalHeader }}
        </p>
        <p class="alert alert-info">
          There is no account associated with this email. Please confirm your
          information below to create a new Kimpod account.
        </p>
        <div class="d-flex flex-column">
          <label class="social_label">Email </label>
          <input
            type="text"
            readonly
            class="form-control p-4"
            v-model="email"
          />
          <label class="social_label">First Name </label>

          <input
            type="text"
            class="form-control mt-2 p-4"
            v-model="firstName"
          />
          <label class="social_label">Last Name </label>

          <input type="text" class="form-control mt-2 p-4" v-model="lastName" />
          <button
            @click="submitSocial"
            :disabled="signupInProgress"
            class="form-control btn btn-warning text-white d-flex flex-wrap vh-100 align-items-center justify-content-center mt-4"
            style="
              height: 40px !important;
              letter-spacing: 0.03rem;
              text-transform: uppercase;
              font-size: 0.9rem;
              font-weight: 600;
            "
          >
            {{ signupBtnText }}
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import * as api from "@/services/api";
import * as lib from "@/services/library";
import { BModal, VBModal } from "bootstrap-vue";
import AuthValidations from "../services/utils/authValidation";

export default {
  name: "LoginComponent",
  components: {
    //'bootstrap-modal': require('vue2-bootstrap-modal'),
    BModal: BModal,
  },
  directives: { "b-modal": VBModal },
  data() {
    return {
      email: "",
      password: "",
      loginText: "Login",
      loginInProgress: false,

      //Modal information
      modalTitle: "Review your Information and Signup",
      modalHeader: "Complete your registration",
      firstName: "",
      lastName: "",
      signupBtnText: "Sign Up",
      signupInProgress: false,
      errorMessage: "",
      errors: [],
      isBtnHide: true,
      next_page: "/record-video",
      isUserLoading: false,
      accessToken: "",
    };
  },
  computed: {
    client_timezone() {
      return this.$store.state.client_tz;
    },
  },
  methods: {
    showModal() {
      this.$refs["authSignupModal"].show();
    },
    hideModal() {
      this.$refs["authSignupModal"].hide();
    },
    submitLogin: function (e) {
      e.preventDefault();
      let $that = this;

      $that.errors = {}

      let validations = new AuthValidations($that.email, $that.password);
      let errors = validations.checkValidations();

      if(errors.email) {
          $that.errors["email"] = errors.email;
          $that.errors["password"] = "";
          $that.$refs["refLoginEmail"].focus();
          return false;
      }

      if(errors.password) {
          $that.errors["email"] = "";
          $that.errors["password"] = errors.password;
          $that.$refs["refLoginPassword"].focus();
          return false;
      }

      if ($that.loginInProgress) return false;
      $that.loginInProgress = true;
      $that.loginText = "Logging in...";
      $that.isUserLoading = true;

      api
        .SubmitLogin({
          username: $that.email,
          password: $that.password,
          timezone: $that.client_timezone
        })
        .then(function (response) {
          if (response.success) {
            $that.isUserLoading = false;
            localStorage.setItem("sve_token", response.token);
            localStorage.setItem("u", JSON.stringify(response.user_info));
            $that.$store.commit("setLoggedIn", response.user_info);
            $that.$store.commit("setSubscriptionStatus", response.subscription);            
            let nextURL = sessionStorage.getItem("next");
            if(nextURL === null) {
              nextURL = "/record-video";
            }
            sessionStorage.removeItem("next");
            $that.$router.push(nextURL);
          } else {
            $that.errorMessage = response.message;
            $that.loginText = "Login";
            $that.isUserLoading = false;
            $that.loginInProgress = false;
          }
        })
        .catch((r) => {
          $that.isUserLoading = false;
          $that.errorMessage =
            "The request could not be processed. Please try again later.";
          $that.loginInProgress = false;
          $that.loginText = "Login";
        });
      return false;
    },
    submitGoogleLogin: function (e) {
      let that = this;
      e.preventDefault();
      this.$gAuth
        .signIn()
        .then(function (u) {
          that.email = u.getBasicProfile().getEmail();
          let fullName = u.getBasicProfile().getName();
          that.firstName = u.getBasicProfile().getGivenName();
          that.lastName = u.getBasicProfile().getFamilyName();
          let profileImage = u.getBasicProfile().getImageUrl();
          that.accessToken = u.getAuthResponse()["access_token"];
          api
            .SubmitSocialLogin({
              email: that.email,
              access_token: that.accessToken,
              timezone: that.client_timezone
            })
            .then(function (response) {
              //console.log(response.success);
              //console.log(response.code);
              //console.log(response.token);
              if (!response.success) {
                if (response.code == 210) {
                  that.showModal();
                }
              } else {
                //console.log("res", response);
                localStorage.setItem("sve_token", response.token);
                localStorage.setItem("u", JSON.stringify(response.user_info));
                that.$store.commit("setLoggedIn", response.user_info);
                that.$store.commit(
                  "setSubscriptionStatus",
                  response.subscription
                );
                let nextURL = sessionStorage.getItem("next");
                if(nextURL === null) {
                  nextURL = "/record-video";
                }
                sessionStorage.removeItem("next");
                that.$router.push(nextURL);
              }
            })
            .catch(function (err) {
              console.log(err);
            });
          window.u = u;
        })
        .catch(function (e) {
          console.log(e);
          that.hideModal();
        });
      return false;
    },
    submitSocial: function (e) {
      e.preventDefault();

      let that = this;
      if (that.signupInProgress) {
        return false;
      }
      that.signupInProgress = true;
      that.signupBtnText = "Signing Up";
      
      let fullName = that.firstName;
      if(that.lastName) {
        fullName += that.lastName;
      }

      let hslStr = lib.HSLtoString(lib.generateHSL(fullName));

      api
        .SubmitSocialSignup({
          email: that.email,
          first_name: that.firstName,
          last_name: that.lastName,
          avatar_hsl_color: hslStr
        })
        .then(function (r) {
          //console.log("r", r);
          api
            .SubmitSocialLogin({
              email: that.email,
              access_token: that.accessToken,
              timezone: that.client_timezone
            })
            .then(function (response) {
              if (response.code == 210) {
                that.$toast.error(
                  "Internal Server Error! For any query or support please contact us at support@kimpod.com",
                  {
                    position: "top-right",
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                  }
                );
              } else {
                localStorage.setItem("sve_token", response.token);
                localStorage.setItem("u", JSON.stringify(response.user_info));
                that.$store.commit("setLoggedIn", response.user_info);
                that.$store.commit(
                  "setSubscriptionStatus",
                  response.subscription
                );
                let nextURL = sessionStorage.getItem("next");
                if(nextURL === null) {
                  nextURL = "/record-video";
                }
                sessionStorage.removeItem("next");
                that.$router.push(nextURL);
              }
            })
            .catch(function (err) {
              console.log(err);
            });
          that.signupInProgress = false;
          that.signupBtnText = "Sign Up";
        })
        .catch(function (err) {
          that.signupInProgress = false;
          that.signupBtnText = "Sign Up";
        });
      return false;
    },
  },
  mounted: function () {
    document.body.classList.add("home_bg");
    let that = this;
    let data = localStorage.getItem("u");
    let email = JSON.parse(data).email;

    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("next")) {
      this.next_page = urlParams.get("next");
    } else {
      this.next_page = urlParams.get("/record-video");
    }
  },
};
</script>
<style>
input.form-control {
  outline: none !important;
}
#idGlobalFooter {
  border-top: none !important;
}

#idLoginForm {
  width: 550px;
  border: 1px solid rgb(241, 241, 237);
}
p.error-validation-message {
  color: red;
  margin-top: 20px;
  padding: 0px 5px;
  text-align: center;
}
@media only screen and (max-width: 48em) {
  #idLoginForm {
    width: 100%;
    overflow-y: auto;
    border: none;
  }
}
@media only screen and (max-width: 767px) {
  #idLoginForm {
    width: 100%;
  }
}
label.social_label {
  text-align: left;
  margin-bottom: 0px;
  margin-top: 10px;
}
a.login-reset {
  color: blue !important;
}
button.btn.btn-primary.form-control.mt-4.submit-btn {
  width: 100% !important;
}
span.spinner-grow.spinner-grow-sm {
  color: white !important;
}
</style>
