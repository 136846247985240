import Vue from "vue";
import Router from "vue-router";
import LoginComponent from "@/components/LoginComponent";
import MessagesSentComponent from "@/components/MessagesSentComponent";
import isLoggedIn from "./middleware/is_logged_in";
import store from '@/store';

Vue.use(Router);


function lazyLoad(ComponentName){
  return () => import(`@/components/${ComponentName}.vue`)
}

let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home_view",
      component: MessagesSentComponent,
      meta: {
        requiresAuth: true,
        middleware: [isLoggedIn],
      },
    },
    {
      path: "/open/:token/",
      name: "open_view",
      component: lazyLoad('Security/OpenLinkComponent'),
    },
    {
      path: "/unsubscribe/:token/",
      name: "unsubscribe",
      component: lazyLoad('EmailUnsubscribeComponent'),
    },
    {
      path: "/analytics",
      name: "analytics",
      component: lazyLoad('DashboardComponent'),
      meta: {
        requiresAuth: true,
        middleware: [isLoggedIn],
      },
    },
    {
      path: "/library",
      name: "my_recordings",
      component: lazyLoad('MyRecording'),
      meta: {
        requiresAuth: true,
        middleware: [isLoggedIn],
      },
    },
    {
      path: "/new-recording",
      name: "new_recording",
      component: lazyLoad('RecordVideo'),
      props: {
        recording_only: true,
      },
      meta: {
        requiresAuth: true,
        middleware: [isLoggedIn],
      },
    },
    {
      path: "/manage-schedules",
      name: "manage_schedules",
      component: lazyLoad('ManageSchedule'),
      meta: {
        requiresAuth: true,
        middleware: [isLoggedIn],
      },
    },
    {
      path: "/manage-domains",
      name: "manage_domains",
      component: lazyLoad('ManageVerifyDomain'),
      meta: {
        requiresAuth: true,
        middleware: [isLoggedIn],
      },
    },
    {
      path: "/help",
      name: "help",
      component: lazyLoad('HelpPage'),
      meta: {
        requiresAuth: true,
        middleware: [isLoggedIn],
      },
    },
    {
      path: "/integrations",
      name: "integrations",
      component: lazyLoad('IntegrationComponent'),
      meta: {
        requiresAuth: true,
        middleware: [isLoggedIn],
      },
    },

    {
      path: "/password-reset",
      name: "password_reset",
      component: lazyLoad('PasswordResetComponent'),
    },
    {
      path: "/record-video",
      name: "record_video",
      component: lazyLoad('RecordVideo'),
      meta: {
        requiresAuth: true,
      },
    },
    // {
    //   path: "/new-video",
    //   name: "new_video",
    //   component: lazyLoad('NewVideoComponent'),
    //   meta: {
    //     requiresAuth: true,
    //   },
    // },
    {
      path: "/manage-contacts",
      name: "manage_contacts",
      component: lazyLoad('ManageRecipientComponent'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/unsubscribed-list",
      name: "unsubscribed_list",
      component: lazyLoad('ManageUnsubscriptionListComponent'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/profile",
      name: "profile",
      component: lazyLoad('Profile'),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/payments",
      name: "manage_payment_history",
      component: lazyLoad('PaymentHistory'),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/edit-recipient/:id",
      name: "edit_recipient",
      component: lazyLoad('AddRecipientComponent'),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/add-email-verification",
      name: "add_email_verification",
      component: lazyLoad('AddVerifyEmailComponent'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/add-domain",
      name: "add_domain",
      component: lazyLoad('AddDomainComponent'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/add-recipient",
      name: "add_recipient",
      component: lazyLoad('AddRecipientComponent'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/manage-subscriptions",
      name: "manage_subscription",
      component: lazyLoad('SettingsComponent'),
      props: true,
    },
    {
      path: "/checkout/failure",
      name: "payment-error",
      component: lazyLoad('PaymentError'),
      props: true,
    },
    {
      path: "/checkout/success",
      name: "payment-card",
      component: lazyLoad('PaymentSuccess'),
      props: true,
    },
    {
      path: "/error",
      name: "Error",
      component: lazyLoad('AnalyticsComponent'),
    },
    {
      path: "/messages",
      name: "messages",
      component: lazyLoad('MessagesSentComponent'),
    },
    {
      path: "/replies",
      name: "replies",
      component: lazyLoad('InboxComponent'),
    },
    {
      path: "/thread/:unique_id",
      name: "thread_detail",
      component: lazyLoad('MessageDetailComponent'),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/verified-emails",
      name: "my_verified_emails",
      component: lazyLoad('VerifiedEmailsComponent'),
    },
    {
      path: "/manage-templates",
      name: "manage_templates",
      component: lazyLoad('ManageTemplatesComponent'),
    },
    {
      path: "/manage-landing-pages",
      name: "manage_landing_pages",
      component: lazyLoad('ManageLandingPagesComponent'),
    },
    {
      path: "/new-template",
      name: "new_template",
      component: lazyLoad('NewTemplate'),
    },
    {
      path: "/my-team",
      name: "my_team",
      component: lazyLoad('myTeam'),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/template/:unique_id",
      name: "template_detail",
      component: lazyLoad('NewTemplate'),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/login",
      name: "login",
      component: LoginComponent,
    },
    {
      path: "/signup-complete",
      name: "signup_complete",
      component: lazyLoad('SignupCompleteComponent'),
    },
    {
      path: "/signup",
      name: "signup",
      component: lazyLoad('SignupComponent'),
    },
    {
      path: "/usage-limit",
      name: "usage_limit",
      component: lazyLoad('UsageLimit'),
    },
    {
      path: "/account-settings",
      name: "account_settings",
      component: lazyLoad('Account'),
    },
    {
      path: "/landing",
      name: "landing_page",
      component: lazyLoad('LandingPageComponent'),
    },
    {
      path: "/watch",
      name: "watch_video_page",
      component: lazyLoad('WatchVideoComponent'),
    },
    {
      path: "/too-many-requests",
      name: "too_many_requests",
      component: lazyLoad('error_pages/ComponentTooManyRequest'),
    },
    {
      path: "/server-error",
      name: "server_error",
      component: lazyLoad('error_pages/ComponentInternalServerError'),
    },
    {
      path: "/permission-denied",
      name: "permission_denied",
      component: lazyLoad('error_pages/ComponentPermissionDenied'),
    },
    {
      path: "*",
      name: "not_found",
      component: lazyLoad('error_pages/ComponentNotFound'),
    },
  ],
});

router.beforeEach((to, from, next) => {
  store.commit('set_route', to);
  console.log(to);
  if(to.name === "login" || to.name === "signup" || to.name === "signup_complete") {
    if(!to.query.apierror) {
      sessionStorage.removeItem("next");
    }    
  }
  else {
    sessionStorage.setItem("next", to.fullPath);
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("sve_token") == null) {
      next({
        path: "/login?next=" + to.fullPath,
        params: { nextUrl: to.fullPath },
      });
    } else {
      let user = JSON.parse(localStorage.getItem("u"));
      next();
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    next();
  } else {
    next();
  }
});

export default router;
