<template>
  <div id="app">
      <slot name="top_nav_menu">
        <div v-if="app_ready" v-bind:class="{'d-flex': app_ready, 'd-none': !app_ready}">
          <TopNavBarComponent v-if="!hide_header"></TopNavBarComponent>
        </div>        
      </slot>
      <NewRecordingComponent v-if="app_ready&&recording_mode!=null"></NewRecordingComponent>
      <div v-if="app_ready" v-bind:class="{'d-flex': app_ready, 'd-none': !app_ready}">
          <router-view /> 
      </div>
      <div v-if="apiFetchError" class="d-flex align-items-center" style="background: rgba(0, 0, 0, 0.3); position: fixed; bottom: 0; left: 0; top: 0; right: 0; z-index: 9999999;">
          <div style="background: #fff;padding: 30px;margin-left: auto; margin-right: auto; min-width: 300px; max-width: 600px;">
            <h3 class="mb-3" style="line-height: 1.2!important;">Unfortunately, we could not connect to the server. Please check your network connection and refresh.</h3>
            <p>If you are still having the issue, please contact us at <a style="text-decoration: underline;" href="maiilto:support@kimpod.com">support@kimpod.com</a></p>
          </div>
      </div>
      <div v-if="!apiFetchError&&!app_ready" ref="refAppNotYetReady" class="d-flex align-items-center" style="background: #f9f9f9; position: fixed; bottom: 0; left: 0; top: 0; right: 0; z-index: 9999999;"> 
          <div style="background: #fff;padding: 30px;margin-left: auto; margin-right: auto; min-width: 300px; max-width: 600px; height: 400px;">
            <AppNotReadyLoader></AppNotReadyLoader>
            <p>Please wait for a while. We are getting the required details. If you are waiting too long please refresh the page. If it still does not work please contact us at <a style="text-decoration: underline;" href="maiilto:support@kimpod.com">support@kimpod.com</a></p>
          </div>
      </div>
  </div>
</template>

<script>
import TopNavBarComponent from "@/components/TopNavBarComponent";
import NewRecordingComponent from "@/components/NewRecordingComponent"
import AppNotReadyLoader from "@/components/loader/AppNotReadyLoader"

var excludePages = ['open_view', 'landing_page', 'watch_video_page', 'too_many_requests', 'server_error', 'permission_denied',
      'not_found', 'password_reset', 'login', 'signup_complete', 'signup']

export default {
  name: "App",
  components: {
    TopNavBarComponent,
    NewRecordingComponent,
    AppNotReadyLoader
  },
  data() {
    return {
      app_ready: false
    }
  },
  watch: {
    access_level (newStatus, oldStatus) {
      // Our fancy notification (2).
      //console.log(newStatus);
      //console.log(oldStatus + " -> " + newStatus);
      if(excludePages.includes(this.$store.state.route.name))
      {
        this.app_ready = true;
        return;
      }
      if(newStatus != null && newStatus != oldStatus) {
        this.app_ready = true;
      }
    }
  },
  computed: {
    access_level() {
      return this.$store.state.access_level;
    },
    onlineStatus() {
      //console.log("Status", this.$store.state.isOnline)
      return this.$store.state.isOnline;
    },
    apiFetchError() {
      //console.log("Status", this.$store.state.apiFetchError)
      return this.$store.state.apiFetchError;
    },
    globalAPIError() {
      //console.log("Status", this.$store.state.globalAPIError)
      return this.$store.state.globalAPIError;
    },
    globalError() {
      return true;
    },
    recording_mode() {
      return this.$store.state.recording_mode;
    },
    hide_header() {
      return this.$store.state.route && excludePages.includes(this.$store.state.route.name);
    }
  },
  mounted: function () {
     if(!excludePages.includes(this.$store.state.route.name) && !this.$store.state.access_level) {
      this.app_ready = false;
     }
     else if(excludePages.includes(this.$store.state.route.name)) {
      this.app_ready = true;
     }
  }
};
</script>
<style>
.fixed-top {
  z-index: 999!important;
  background: #ffd602!important;
  /* #ffc63e */
}
</style>
