<template>
  <div class="uploading-video">
    <p style="font-size: 16px">
      <span
        :class="{
          'status-canceled': status == 'canceled',
        }"
      >
        {{ file.fileName }}
      </span>
    </p>
    <button class="btn btn-warning" @click="cancel()">cancel</button>

    <p style="color: green" class="upload-status" v-if="status == 'success'">
      done!
    </p>
    <p
      style="color: red"
      class="upload-retrying"
      v-else-if="status == 'retrying'"
    >
      fault, retrying...
    </p>
    <p style="color: red" class="upload-error" v-else-if="status == 'error'">
      error! cannot upload file
    </p>
    <p
      style="color: red"
      class="upload-canceled"
      v-else-if="status == 'canceled'"
    >
      cancelled
    </p>
    <div style="margin-top: 10px">
      <b-progress
        :value="uploadedAmount"
        :max="100"
        show-progress
        animated
      ></b-progress>
    </div>

    <div
      style="width: 100%; margin: 0 auto; text-align: center"
      v-if="isUploading"
    >
      <button
        style="margin-right: 10px"
        class="btn btn-secondary"
        @click="isPaused ? resume() : pause()"
      >
        {{ isPaused ? "resume" : "pause" }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["file", "status", "progress"],
  data() {
    return {
      isPaused: false, // we upload straight away by default
    };
  },
  computed: {
    isUploading() {
      return this.status !== "success" && this.status !== "canceled";
    },
    uploadedAmount() {
      document.getElementById("idMasterProgressBarTop").classList.add("d-none");
      return (this.progress * 100).toFixed(2);
    },
  },
  methods: {
    upload() {
      this.file.resumableObj.upload();
      this.isPaused = false;
    },
    pause() {
      this.file.pause();
      this.isPaused = true;
    },
    resume() {
      this.pause(); // not sure why, but we have to call pause again before upload will resume
      this.upload();
    },
    cancel() {
      this.$emit("cancel", this.file);
    },
  },
};
</script>

<style lang="scss">
.uploading-video {
  .status-canceled {
    text-decoration: line-through;
  }
}
.uploading-video p {
  margin-top: 18px;
  text-align: center;
}
</style>
