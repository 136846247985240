var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"overflow":"hidden","padding":"20px"}},[_vm._m(0),_vm._l((_vm.items),function(item,i){return _c('div',{key:i,staticClass:"card",staticStyle:{"border":"none","box-shadow":"none"},attrs:{"id":"card-link","target":"_blank"}},[_c('div',{staticClass:"card__header",attrs:{"item":item,"index":i}},[_vm._m(1,true),_vm._m(2,true)]),_c('div',{staticClass:"card__body",attrs:{"item":item,"index":i}},[_vm._m(3,true),_vm._m(4,true)]),_vm._m(5,true)])})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card",staticStyle:{"border":"none","box-shadow":"none"},attrs:{"id":"card-link","target":"_blank"}},[_c('div',{staticClass:"card__header"},[_c('div',[_c('img',{staticClass:"card__header header__img skeleton",attrs:{"id":"logo-img","alt":""}})]),_c('h3',{staticClass:"card__header header__title",attrs:{"id":"card-title"}},[_c('div',{staticClass:"skeleton skeleton-text"}),_c('div',{staticClass:"skeleton skeleton-text"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"card__header header__img skeleton",attrs:{"id":"logo-img","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"card__header header__title",attrs:{"id":"card-title"}},[_c('div',{staticClass:"skeleton skeleton-text"}),_c('div',{staticClass:"skeleton skeleton-text"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card__body body__text",attrs:{"id":"card-details"}},[_c('div',{staticClass:"skeleton skeleton-text skeleton-text__body"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card__body body__img"},[_c('img',{staticClass:"skeleton",attrs:{"alt":"","id":"cover-img"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card__footer d-flex flex-wrap justify-content-between",attrs:{"id":"card-footer"}},[_c('div',{staticClass:"skeleton skeleton-text skeleton-footer"}),_c('div',{staticClass:"skeleton skeleton-text skeleton-footer"})])
}]

export { render, staticRenderFns }