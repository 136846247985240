<template>    
      <div class="d-flex flex-column justify-content-center lp-main-wrapper" style="margin: 0 auto; position: relative;">        
        <div v-show="!show_countdown_timer" style="width: 100%; background: #efefef; position: relative;">
          <div v-if="render_video_components" 
          class="d-flex flex-column justify-content-center align-items-center p-4" id="idScreenRecordReplay">
            <video-player-obsolete
                v-show="screen_recorded"
                id="idScreenRecordPlayerReplay"
                :videoURL="screen_record_video_src"
              />
          </div>
          <button @click="retryS3Upload('screen')" id="idScreenManualUploadBtn" class="d-none" ref="screenManualUploadBtn" style="width: 150px;height: 45px;
                        position: absolute;
                        font-weight: 600;
                        color: #fff;
                        left: calc(50% - 75px);
                        bottom: 60px;
                        background: white;
                        flex-wrap: nowrap;
                        justify-content: center;
                        align-items: center;
                        background: rgb(73, 201, 203) !important; z-index: 99;">
                        <span>Upload</span>
          </button>          
          <div v-if="!render_video_components" v-bind:class="{
            'flex-column justify-content-center align-items-center p-4': true,
            'd-flex': recordingStatus==='pending',
            'd-none': recordingStatus!=='pending'
          }" id="idScreenRecordingOptions">
               <h5 class="w-100 mb-5">Recording Options:</h5>
               <label @click="toggleMicAudioOption()" style="cursor: pointer; font-size: 1.4rem; border: 1px solid #cdcdcd;border-radius: 10px;padding: 10px;" class="checkbox-inline p-3 d-flex flex-wrap justify-content-center align-items-center">
                <span>
                  <i style="width: 40px;" v-if="allowScreenRecordMic" class="fas fa-microphone"></i>
                  <i style="width: 40px;" v-if="!allowScreenRecordMic" class="fas fa-microphone-slash"></i>
                </span>
                Record Mic</label>
              <select
                class="form-select mt-4 mb-3 ml-3 mr-3"
                style="max-width: 400px"
                :disabled="!allowScreenRecordMic||recordingStatus==='recording'"
                v-model="screenAudioSrc"
                @change="changeScreenAudioInput"
              >
                <option
                  v-for="(d, i) in screenAudioDevices"
                  :value="d.deviceId"
                  :key="i"
                >
                  {{ d.label }}
                </option>
              </select>
              <button v-if="recordingStatus==='pending'" @click="startScreenRecording()" class="w-100 mt-4" style="height: 45px; padding: 8px; font-weight: 600;
                        color: #fff;
                        left: calc(50% - 75px);
                        bottom: 60px;
                        background: white;
                        flex-wrap: nowrap;
                        justify-content: center;
                        align-items: center;
                        background: rgb(73, 201, 203) !important;">
                        <span>Start Recording</span>
              </button>
            </div>
        </div>
        <button v-show="recordingStatus==='recording'" @click="stopScreenRecording()" class="w-100" style="height: 45px; padding: 8px; font-weight: 600; color: #fff;
                  flex-wrap: nowrap;
                  justify-content: center;
                  align-items: center;
                  background: rgb(225 50 50) !important;">
                  <span>
                    <span>Stop</span> 
                    <span
                      style="text-align: center"
                      class="flex-wrap justify-content-center"
                    >
                      <label
                        ref="scrDurationRef"
                        style="font-weight: 600"
                        >(00:00:00)</label
                      >
                    </span>
                  </span>
        </button>
        <div id="recordingProgressText" ref="recordingProgressText" style="position: absolute;top: 80px;left: calc(50% - 110px);font-size: 1.6rem;background: rgba(255, 255, 255, 0.5);padding: 10px;width: 220px;color: #000;font-weight: 600;border-radius: 10px;"
                class="d-none flex-wrap justify-content-center">
                    
        </div>
        <div style="background: #fff;" ref="refProceedButton" class="d-none flex-wrap justify-content-end p-3 align-items-center">
          <div class="mr-3">Please click on the proceed button</div>
          <button @click="triggerProceed()" class="btn btn-danger">Proceed</button>
        </div>
    </div>
  </template>
  <script>
  import Vue from 'vue';
  import { Buffer } from 'buffer';
  //var mime = require('mime-types')
  // eslint-disable-next-line no-unused-vars
  import "vue-search-select/dist/VueSearchSelect.css";
  import { ModelSelect } from "vue-search-select";
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import moment from "moment";
  import { uuid } from "vue-uuid";
  import Resumable from "resumablejs";
  import UploadingVideo from "./uploadvideo";
  import VideoPlayerObsolete from "@/components/players/VideoPlayerObsolete";

  import MainMenuComponent from "@/components/MainMenuComponent";
  import TopNavBarComponent from "@/components/TopNavBarComponent";
  import VideoPlayer from "@/components/players/VideoPlayer";

  import globalVars from "../global";

  import * as api from "@/services/api";
  import pell from "pell";
  import Toast from "vue-toastification";
  import "vue-toastification/dist/index.css";

let toastOptions = {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
};

//-----------------------------------------------------------------------------
// https://github.com/collab-project/videojs-record/blob/master/examples/browser-workarounds.js
//-----------------------------------------------------------------------------

/*eslint-disable*/
if (typeof MediaRecorder === "undefined") {
}

/*eslint-disable*/
var isOpera = !!window.opera || navigator.userAgent.indexOf("OPR/") !== -1;
var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
var isEdge = /Edge/.test(navigator.userAgent);

function takethumbnail(videl) {
  const dims = getscaleddims(videl.videoWidth, videl.videoHeight);
  thumbnail.width = dims[0];
  thumbnail.height = dims[1];
  thumbnail.getContext("2d").drawImage(videl, 0, 0, dims[0], dims[1]);
}

// Returns [width, height].
function getscaleddims(w, h) {
  var nw = 250;
  var ratio = nw / w;
  var nh = ratio * h;
  return [nw, nh];
}

/*eslint-disable*/
function applyAudioWorkaround() {
  if (isSafari || isEdge) {
    if (isSafari && window.MediaRecorder !== undefined) {
      // this version of Safari has MediaRecorder
      return;
    }

    // support recording in safari 11/12
    // see https://github.com/collab-project/videojs-record/issues/295
    options.plugins.record.audioRecorderType = StereoAudioRecorder;
    options.plugins.record.audioSampleRate = 44100;
    options.plugins.record.audioBufferSize = 4096;
    options.plugins.record.audioChannels = 2;

    console.log("applied audio workarounds for this browser");
  }
}

function applyVideoWorkaround() {
  // use correct video mimetype for opera
  if (isOpera) {
    options.plugins.record.videoMimeType = "video/webm;codecs=vp8"; // or vp9
  } else {
    // options.plugins.record.videoMimeType = 'video/webm;codecs=H264';
    // options.plugins.record.videoMimeType = 'video/mp4';
  }
}

/*eslint-disable*/
function applyScreenWorkaround() {
  // Polyfill in Firefox.
  // See https://blog.mozilla.org/webrtc/getdisplaymedia-now-available-in-adapter-js/
  if (adapter.browserDetails.browser == "firefox") {
    adapter.browserShim.shimGetDisplayMedia(window, "screen");
  }
}

let maxRecordingDuration = 5 * 60;
let maxRecordingSize = 1024 * 1024 * 100;
let maxScreenRecordingDuration = 5 * 60;
let maxScreenRecordingSize = 1024 * 1024 * 100;
let recordingProcessStarted = false;
let maxFileUploadSize = 100; // 100 MB
let maxTimeWaitAfterRecordingStopped = 60; // 10 seconds

// https://collab-project.github.io/videojs-record/#/usage
import "video.js/dist/video-js.min.css";
import videojs from "video.js";
import RecordRTC from "recordrtc";
import "videojs-record/dist/css/videojs.record.css";

import Record from "videojs-record/dist/videojs.record.js";

// For Dropzone
import "dropzone/dist/min/basic.min.css";
import "dropzone/dist/min/dropzone.min.css";
import Dropzone from "dropzone/dist/min/dropzone.min.js";
// the following imports are only needed when you're recording
// audio-only using the videojs-wavesurfer plugin
import WaveSurfer from "wavesurfer.js";
import MicrophonePlugin from "wavesurfer.js/dist/plugin/wavesurfer.microphone.js";
WaveSurfer.microphone = MicrophonePlugin;

// register videojs-wavesurfer plugin
import "videojs-wavesurfer/dist/css/videojs.wavesurfer.css";
import Wavesurfer from "videojs-wavesurfer/dist/videojs.wavesurfer.js";
import Cookies from "js-cookie";

// video js trimmer
/* eslint-disable */
//import "../../public/assets/vendors/videojs-trimmer.css";
//import "../../public/assets/vendors/videojs-trimmer.js";

let player = null;
let dropzone = null;

let screenRecorderStream = null;
let screenDesktopStream = null;
let screenAudioStream = null;

let screen_record_blob = null;
let custom_thumnail = null;

let allBlobs = [];

let videoRecordUUID = null;
let videoRecordCompleted = false;
let videoRecordFileKey = null;
let videoRecordMultipart = null;
let videoUploadPartNumber = 0;
let lastUploadedBlobIndex = -1;
let recordedBlobs = [];
let totalUploadInitiated = 0;
let totalUploadCompleted = 0;
let recordingStopped = false;
let streamRecordCompleted = false;
let recordingDurationInSecond = 0;
let recordingDurationTimer = null;

let screenRecordUUID = null;
let screenRecordCompleted = false;

let fileUploadUUID = null;
let fileUploadCompleted = false;
let fileUploadObject = {};
let numPartsLeftMPU = 0;
let totalPartsUploaded = 0;
let fileUploadS3Instance = null;
let fileUploadS3SimpleUploadRef = null;
let fileUploadS3InstanceSimple = null;
let fileUploadS3BucketName = null;
let fileUploadS3FileKey = null;
let fileUploadS3UploadId = null;
let bucketName = globalVars.VIDEO_UPLOAD_BUCKET;
let bucketRegion = "us-east-1";
let IdentityPoolId = "us-east-1:e0bbf09b-7694-4843-9166-8abab853ba40";

var multipartMap = {
  Parts: [],
};

//
let maxUploadTries = 3;
let currentChunkIndex = 0;
let s3UploadError = false;
let binaryBlob = null;
let canUpload = false;
let uploadInstances = [];
//

function resetGolabVariables() {
  player = null;
  dropzone = null;

  screenRecorderStream = null;
  screenDesktopStream = null;
  screenAudioStream = null;

  screen_record_blob = null;
  custom_thumnail = null;

  allBlobs = [];

  videoRecordUUID = null;
  videoRecordCompleted = false;
  videoRecordFileKey = null;
  videoRecordMultipart = null;
  videoUploadPartNumber = 0;
  lastUploadedBlobIndex = -1;
  recordedBlobs = [];
  totalUploadInitiated = 0;
  totalUploadCompleted = 0;
  streamRecordCompleted = false;
  recordingDurationInSecond = 0;
  recordingDurationTimer = null;

  screenRecordUUID = null;
  screenRecordCompleted = false;

  fileUploadUUID = null;
  fileUploadCompleted = false;
  fileUploadObject = {};
  numPartsLeftMPU = 0;
  totalPartsUploaded = 0;
  fileUploadS3Instance = null;
  fileUploadS3BucketName = null;
  fileUploadS3FileKey = null;
  fileUploadS3UploadId = null;
  bucketName = globalVars.VIDEO_UPLOAD_BUCKET;
  bucketRegion = "us-east-1";
  IdentityPoolId = "us-east-1:e0bbf09b-7694-4843-9166-8abab853ba40";

  multipartMap = {
    Parts: [],
  };

  maxUploadTries = 3;
  currentChunkIndex = 0;
  s3UploadError = false;
  binaryBlob = null
  canUpload = false;
  uploadInstances = []
}

function abortS3DirectUpload(uInstance) {
  return new Promise(function (resolve, reject) {
    try {
        uInstance.abort();
        resolve();
    } catch (e) {
      reject(e);
    }
  });
}

function abortS3MultipartUpload() {
  var params = {
    Bucket: fileUploadS3BucketName,
    Key: fileUploadS3FileKey,
    UploadId: fileUploadS3UploadId,
  };
  return new Promise(function (resolve, reject) {
    for (var i = 0; i < 6; i++) {
      try {
        fileUploadS3Instance.abortMultipartUpload(params, function (err, data) {
          //alert(1)
          if (err) console.log(err, err.stack); // an error occurred
          else {
          }
        });
      } catch (e) {
        console.log("Multi Abort Error ", e);
      }
    }
    resolve();
  });
}

function initializeS3(bucketName, bucketRegion, IdentityPoolId, completedCallback, failedCallback) {
  try {
    if (fileUploadS3Instance) {
      completedCallback(fileUploadS3Instance);
      return;
    }
    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    });

    let s3Instance = new AWS.S3({
      apiVersion: "2006-03-01",
      params: { Bucket: bucketName },
    });
    completedCallback(s3Instance);
  }catch(e) {
    failedCallback(e);
  }
}

function uploadToS3(s3Instance, attempt, fileKey, blob, acl, contentEncoding, contentType, successCallback, failedCallback, progressCallback) {
  if(s3UploadError) {
    failedCallback("ERROR")
     return;
  }  
  var attempt = attempt || 1;
    let payload = {
          Key: fileKey,
          Body: blob,
          ACL: 'public-read'
    };
    if(acl) {
      payload.ACL = acl;
    }
    if(contentEncoding) {
      payload.ContentEncoding = contentEncoding;
    }
    if(contentType) {
      payload.ContentType = contentType;
    }
    try {
      fileUploadS3SimpleUploadRef = s3Instance.upload(payload, 
        function(err, data) {
            console.log(err, data)
            if(err) {
              console.log("Err")
              //videoRecordUploadCompletedCallback("FAILURE");
              //console.log(err);
              if (attempt < maxUploadTries) {
                console.log('Retrying uploadToS3 of attempt: #', attempt)
                uploadToS3(s3Instance, attempt + 1, fileKey, blob, acl, contentEncoding, contentType, successCallback, failedCallback, progressCallback);
              } else {
                console.log('Failed uploading file key: ', fileKey)
                failedCallback(err)
              }
            }
            else {
              console.log("Success")
              //videoRecordUploadCompletedCallback("SUCCESS");
              successCallback(data);
            }
        }).on('httpUploadProgress', function (progress) {
        var uploaded = parseInt((progress.loaded * 100) / progress.total);
        //alert(uploaded);
        if(progressCallback) {
          progressCallback(uploaded);
        }
      });
      uploadInstances.push(fileUploadS3SimpleUploadRef);
    }catch(e) {
        console.log("Gherr");
        failedCallback(e);
    }
}

function S3MultipartQueueUpload(qSize, progressCallback) {
  if(!binaryBlob) {
      return false;
  }
  if(progressCallback) {
      progressCallback(null, null, "Initializing Upload");
  }
  var qSize = qSize || 4;
  var numOfWorkers = 0;
  var taskIndex = 0;

  var tasks = {};

  var partSize = 1024 * 1024 * 2;
  var totalParts = 0;
  for (
    var rangeStart = 0;
    rangeStart < binaryBlob.size;
    rangeStart += partSize
  ) {
    var end = Math.min(rangeStart + partSize, binaryBlob.size);
    /*var nextChunkSize = binaryBlob.size - end;
    if (nextChunkSize < partSize) {
      end = binaryBlob.size;
    }*/    
    tasks[totalParts] = {
        start: rangeStart,
        end: end,
        status: "p" //p, u, f = Pending, Uploaded, Failed
    }
    totalParts += 1;
  }

  //console.log(tasks);

  var totalTasks = Object.keys(tasks).length;
  var totalSuccess = 0;

  let blobName = binaryBlob.name;
  var re = /(?:\.([^.]+))?$/;
  var fileExt = re.exec(blobName)[1];
  console.log("Starting")
  return new Promise((resolve, reject) => {
    initializeS3(bucketName, bucketRegion, IdentityPoolId, function(s3Instance) {
        fileUploadS3Instance = s3Instance;

        const handleSuccessResult = index => result => {
          console.log("handleSuccessResult " + index + " " + result)
          //console.log(result);
          totalSuccess += 1;
          let uploaded = parseInt(
                  (totalSuccess * 100) / totalTasks
                );
          if(progressCallback) {
            progressCallback(totalTasks, totalSuccess, "Uploading("+ uploaded +"%)");
          }
          tasks[index].status = result;
          numOfWorkers--;
          getNextTask();
      };

      const handleFailureResult = index => result => {
        console.log("handleFailureResult " + index + " " + result)
        //console.log(result);
        tasks[index].status = result;
        //numOfWorkers--;
        //getNextTask();
        s3UploadError = true;
        reject()
      };

      const getNextTask = () => {
        console.log("getNextTask " + taskIndex)
        console.log("s3UploadError " + s3UploadError)
        console.log("numOfWorkers " + numOfWorkers)
        console.log("taskIndex " + taskIndex)
        console.log("totalTasks " + totalTasks);
        if(s3UploadError) {
          return;
        }

        if (numOfWorkers < qSize && taskIndex < totalTasks) {
          console.log("Entering " + taskIndex);
          var rangeStart = tasks[taskIndex].start;
          var end = tasks[taskIndex].end;
          console.log("rangeStart " + rangeStart)
          console.log("end " + end)
          fileUploadS3FileKey = "videos/" + videoRecordUUID + "/" + taskIndex + "." + fileExt;
          console.log("fileUploadS3FileKey " + fileUploadS3FileKey);
          let blobChunk = binaryBlob.slice(rangeStart, end);
          uploadToS3(fileUploadS3Instance, 1, fileUploadS3FileKey, 
            blobChunk, null, null, null, handleSuccessResult(taskIndex), handleFailureResult(taskIndex));
          taskIndex++;
          numOfWorkers++;
          getNextTask();

        } else if (numOfWorkers === 0 && taskIndex === totalTasks) {
            resolve(tasks);
        }
      };
      getNextTask();
    })
  });
}

function tryS3Upload(context="record", completedCallback, progressCallback, skipUI=false, mUploadProgressCallback) {
  if(!binaryBlob) {
      return false;
  }
  if(!skipUI) {
    if(context === "record") {
      document.getElementById("idVideoRecordProgressPopup").classList.remove("d-none");
      document.getElementById("idVideoRecordProgressPopup").classList.add("d-flex");
    }
    else if(context === "screen") {
      document.getElementById("idScrProgressPopupRef").classList.remove("d-none");
      document.getElementById("idScrProgressPopupRef").classList.add("d-flex");
    }
  }
  if(binaryBlob.size <= 100 * 1024 * 1024) {
    let blobChunk = binaryBlob;
    initializeS3(bucketName, bucketRegion, IdentityPoolId, function(s3Instance) {
        console.log("S3 initialized");
        console.log(s3Instance);
        let blobName = blobChunk.name;
        let fileExt = blobName.substr(blobName.lastIndexOf("."), blobName.length);
        fileUploadS3FileKey = "videos/" + videoRecordUUID + "/single" + fileExt;
        console.log("2");
        fileUploadS3Instance = s3Instance;
        uploadToS3(fileUploadS3Instance, 1, fileUploadS3FileKey, blobChunk, null, null, null,
          function(d) {
            console.log("Uploaded: " + fileUploadS3FileKey);
            videoRecordCompleted = true;
            s3UploadError = false;
            if(!skipUI) {
              if(context === "record") {
                document.getElementById("idVideoRecordProgressPopup").classList.remove("d-flex");
                document.getElementById("idVideoRecordProgressPopup").classList.add("d-none");
                hideVideoRecordError();
                showVideoReady();
              }
              else if(context === "screen") {
                document.getElementById("idScrProgressPopupRef").classList.remove("d-flex");
                document.getElementById("idScrProgressPopupRef").classList.add("d-none");
                hideScreenRecordError();
                showScreenRecordReady();
              }
              Vue.$toast.success("Video is ready", toastOptions);
            }
            
            if(completedCallback) {
              completedCallback("SUCCESS")
            }
          },
          function(err) {
            console.log("Upload error 2")
            s3UploadError = true;
            if(!skipUI) {
              Vue.$toast.error("Error in upload", toastOptions);
              if(context === "record") {
                document.getElementById("idVideoRecordProgressPopup").classList.remove("d-flex");
                document.getElementById("idVideoRecordProgressPopup").classList.add("d-none");
                hideVideoReady();
                showVideoRecordError();
              }
              else if(context === "screen") {
                document.getElementById("idScrProgressPopupRef").classList.remove("d-flex");
                document.getElementById("idScrProgressPopupRef").classList.add("d-none");
                hideScreenRecordError();
                showScreenRecordError();
              }
            }
            console.log("Error uploading: " + fileUploadS3FileKey);
            if(completedCallback) {
              completedCallback("FAILED")
            }
        },
        progressCallback)
    },
    function(err) {
        console.log("Herror");
        console.log(err)
        completedCallback("FAILED");
    });
  }
  else {
    let mUploader = S3MultipartQueueUpload(4, mUploadProgressCallback)
    if(mUploader) {
      mUploader.then(d=> {
        videoRecordCompleted = true;
        fileUploadCompleted = true;
        s3UploadError = false;
        if(!skipUI) {
          if(context === "record") {
            document.getElementById("idVideoRecordProgressPopup").classList.remove("d-flex");
            document.getElementById("idVideoRecordProgressPopup").classList.add("d-none");
            hideVideoRecordError();
            showVideoReady();
          }
          else if(context === "screen") {
            document.getElementById("idScrProgressPopupRef").classList.remove("d-flex");
            document.getElementById("idScrProgressPopupRef").classList.add("d-none");
            hideScreenRecordError();
            showScreenRecordReady();
          }
          else if(context == "upload") {
            document.getElementById("idFileUploadProgressLabel").textContent = "Upload Completed.";
            Vue.$toast.success("Upload completed", toastOptions);
            hideFileUploadError();
            hideFileUploadProgress();
            hideFileUploadInfoPanel();
            showFileUploadReady();
          }
          Vue.$toast.success("Video is ready", toastOptions);
        }
        
        if(completedCallback) {
          completedCallback("SUCCESS")
        }

      }).catch(err => {
        s3UploadError = true;
        fileUploadCompleted = false;
        if(!skipUI) {
          Vue.$toast.error("Error in upload", toastOptions);
          if(context === "record") {
            document.getElementById("idVideoRecordProgressPopup").classList.remove("d-flex");
            document.getElementById("idVideoRecordProgressPopup").classList.add("d-none");
            hideVideoReady();
            showVideoRecordError();
          }
          else if(context === "screen") {
            document.getElementById("idScrProgressPopupRef").classList.remove("d-flex");
            document.getElementById("idScrProgressPopupRef").classList.add("d-none");
            hideScreenRecordError();
            showScreenRecordError();
          }
          else if(context == "upload") {
            document.getElementById("idFileUploadProgressLabel").textContent = "Upload Failed.";
            Vue.$toast.error("Upload Failed", toastOptions);
            hideFileUploadReady();
            hideFileUploadProgress();
            hideFileUploadInfoPanel();
            showFileUploadError();
          }
        }
        console.log("Error uploading: " + fileUploadS3FileKey);
        if(completedCallback) {
          completedCallback("FAILED")
        }
      })
    }
    else {
      Vue.$toast.error("Please select a video to upload", toastOptions);
    }
  }
}

function cancelAllUploads() {
   return new Promise(function(resolve, reject) {
      for(var i = 0; i < uploadInstances.length; i++) {
          abortS3DirectUpload(uploadInstances[i])
      }
      resolve();
   });
}

// To merge audio sources
const mergeAudioStreams = (desktopStream, voiceStream) => {
  const context = new AudioContext();
  const destination = context.createMediaStreamDestination();
  let hasDesktop = false;
  let hasVoice = false;
  //console.log("Desktop audio stream");
  //console.log(desktopStream);
  //console.log(desktopStream.getAudioTracks());
  if (desktopStream && desktopStream.getAudioTracks().length > 0) {
    // If you don't want to share Audio from the desktop it should still work with just the voice.
    const source1 = context.createMediaStreamSource(desktopStream);
    const desktopGain = context.createGain();
    desktopGain.gain.value = 0.7;
    source1.connect(desktopGain).connect(destination);
    hasDesktop = true;
  }

  if (voiceStream && voiceStream.getAudioTracks().length > 0) {
    const source2 = context.createMediaStreamSource(voiceStream);
    const voiceGain = context.createGain();
    voiceGain.gain.value = 0.7;
    source2.connect(voiceGain).connect(destination);
    hasVoice = true;
  }

  return hasDesktop || hasVoice ? destination.stream.getAudioTracks() : [];
};

async function prepareScreenRecording(app, micAudio, desktopAudio) {
  //https://glitch.com/edit/#!/screen-record-voice?path=script.js%3A51%3A17
  //https://github.com/t-mullen/video-stream-merger
  //https://www.webrtc-experiment.com/ConcatenateBlobs/
  //https://javascriptio.com/view/409364/how-i-can-merge-audio-and-video-blob-on-javascript-jquery
  //https://github.com/webrtc/samples/tree/gh-pages/src/content/getusermedia/canvas
  //https://github.com/fbsamples/Canvas-Streaming-Example
  //https://www.youtube.com/watch?v=nCrQ1A2BEZ0
  //alert(desktopAudio)
  screenDesktopStream = await navigator.mediaDevices.getDisplayMedia({
    video: true,
    //audio: desktopAudio,
  });

  if (micAudio) {
    screenAudioStream = await navigator.mediaDevices.getUserMedia({
      audio: true
    });
    const tracks = [
      ...screenDesktopStream.getVideoTracks(),
      //...mergeAudioStreams(screenDesktopStream, screenAudioStream),
      ...screenAudioStream.getAudioTracks()
    ];

    //console.log("Tracks to add to stream", tracks);
    screenRecorderStream = new MediaStream(tracks);
  }
  else {
    const tracks = [
      ...screenDesktopStream.getVideoTracks()
    ];

    //console.log("Tracks to add to stream", tracks);
    screenRecorderStream = new MediaStream(tracks);
  }
  
}

function getInitialData() {
  return {
    // Keep track of the available inputs available for video.
    vidsrc: null,
    audiosrc: null,
    devices: [],

    loading: false,
    uploadContentType: "record",
    deviceReady: false,
    isRecording: false,

    submitReady: true,
    device_status: "loading",
    screenRecordPlayer: null,
    videoStart: false,
    recorder: null,
    screenDeviceReady: false,
    recordingStatus: "pending",
    screenAudioStream: null,
    screenDevices: [],
    screenAudioSrc: null,
    allowScreenRecordMic: true,
    allowScreenRecordDesktop: false,

    isRecordingSupported: false,
    isDisplaySupported: false,

    device_permission_denied: false,
    device_error: false,
    video_file_selected: false,
    pre_recorded_video_src: "",
    screen_recorded: false,
    screen_record_video_src: "",
    submit_ok_button_text: "Okay",
    recording_title: "",
    new_recording_error: "",
    start_recording_label: "Start Recording",
    notif_duration: null,
    show_countdown_timer: false,
    countdown_number: 4,
    settings_sidebar_open: true,
    render_video_components: false,
  };
}

function firstaudiodevice(devices) {
  for (let d of devices) {
    if (d.kind === "audioinput") {
      return d.deviceId;
    }
  }
  return null;
}
  
  export default {
    name: "ScreenRecorderComponent",
    components: {
      VideoPlayerObsolete
    },
    computed: {
      screenAudioDevices: function () {
        return this.screenDevices.filter(function (d) {
          return d.kind === "audioinput";
        });
      },
    },
    data() {
      return getInitialData();
    },
    methods: {
      triggerProceed(e) {
        this.$emit("proceed",  videoRecordUUID, this.screen_record_video_src);
      },
      retryS3Upload(context) {
          //alert(1)
          let that = this;
          cancelAllUploads().then(function() {
            uploadInstances = [];
            console.log("Aborted")
          }).catch(function(err) {});
          s3UploadError = false;
          fileUploadCompleted = false;
          if(context === "screen") {
              //alert(2)
              let mUploadProgressCallback = (total, completed, progressStatusText) => {
                  if(progressStatusText) {
                    let progress = parseInt(
                        (completed * 100) / total
                      );
                      document.getElementById("recordingProgressText").textContent = "Uploading(" + progress + "%)";
                  }
              }
              document.getElementById("recordingProgressText").classList.remove("d-none");
              document.getElementById("recordingProgressText").classList.add("d-flex");

              document.getElementById("idScreenManualUploadBtn").classList.remove("d-flex");
              document.getElementById("idScreenManualUploadBtn").classList.add("d-none");

              let completedCallback = status => {
                  if(status === "SUCCESS") {
                    // Finish the upload
                    var instruction = {
                          key: "videos/" + videoRecordUUID,
                          fileId: videoRecordUUID,
                          context:"screen"
                      };
                      var buf = Buffer.from(JSON.stringify(instruction));

                      initializeS3(bucketName, bucketRegion, IdentityPoolId, function(s3Instance) {
                          fileUploadS3FileKey = "videos/" + videoRecordUUID + "/index.json";
                          fileUploadS3Instance = s3Instance;

                          uploadToS3(fileUploadS3Instance, 1, fileUploadS3FileKey, buf, null, null, null,
                            function(d) {
                              console.log("Uploaded index.json");
                              fileUploadCompleted = true;
                              s3UploadError = false;
                              //hideScreenRecordError();
                              //showScreenRecordReady();
                              document.getElementById("recordingProgressText").classList.remove("d-flex");
                              document.getElementById("recordingProgressText").classList.add("d-none");
                              //Vue.$toast.success("Video is ready", toastOptions);
                              that.$emit("uploaded",  videoRecordUUID, that.screen_record_video_src);
                            },
                            function(err) {
                              s3UploadError = true;
                              //hideScreenRecordReady();
                              //showScreenRecordError();
                              document.getElementById("recordingProgressText").classList.remove("d-flex");
                              document.getElementById("recordingProgressText").classList.add("d-none");

                              document.getElementById("idScreenManualUploadBtn").classList.remove("d-none");
                              document.getElementById("idScreenManualUploadBtn").classList.add("d-flex");

                              //Vue.$toast.error("Error in upload", toastOptions);
                              console.log("Error uploading index.json: ");
                            })
                        });
                  }
                  else {
                    s3UploadError = true;
                    //hideScreenRecordReady();
                    //showScreenRecordError();
                    document.getElementById("recordingProgressText").classList.remove("d-flex");
                    document.getElementById("recordingProgressText").classList.add("d-none");
                    //Vue.$toast.error("Error in upload", toastOptions);

                    document.getElementById("idScreenManualUploadBtn").classList.remove("d-none");
                    document.getElementById("idScreenManualUploadBtn").classList.add("d-flex");

                  }
              }
              let progressCallback = progress => {
                  document.getElementById("recordingProgressText").textContent = "Uploading(" + progress + "%)";
              }
              tryS3Upload("screen", completedCallback, progressCallback, true, mUploadProgressCallback);
          }
        },
      toggleMicAudioOption: function() {
        if(this.recordingStatus === 'recording') {
          return false;
        }
        this.allowScreenRecordMic = !this.allowScreenRecordMic;
      },
      changeScreenAudioInput: function (e) {
        const deviceId = e.target.value;
        try {
        } catch (error) {
          console.warn("error in changing audio input", error);
          // jump back to first output device in the list as it's the default
          e.target.selectedIndex = 0;
        }
      },

      calculateTimeDuration: function (secs) {
        var hr = Math.floor(secs / 3600);
        var min = Math.floor((secs - hr * 3600) / 60);
        var sec = Math.floor(secs - hr * 3600 - min * 60);

        if (min < 10) {
          min = "0" + min;
        }

        if (sec < 10) {
          sec = "0" + sec;
        }

        if (hr <= 0) {
          return min + ":" + sec;
        }

        return hr + ":" + min + ":" + sec;
      },
      startScreenRecording: function () {
        const that = this;
        const mic = this.allowScreenRecordMic;
        const desktopAudio = this.allowScreenRecordDesktop;

        screen_record_blob = null;
        that.screen_recorded = false;
        that.screen_record_video_src = "";

        let countdownTimer = null;

        /* Multipart upload parameters */
        totalPartsUploaded = 0;
        videoUploadPartNumber = 0;
        lastUploadedBlobIndex = -1;
        recordedBlobs = [];
        fileUploadS3Instance = null;
        fileUploadS3InstanceSimple = null;
        totalUploadInitiated = 0;
        totalUploadCompleted = 0;
        streamRecordCompleted = false;
        fileUploadS3UploadId = null;

        //document.getElementById("idScrProgressPopupRef").classList.remove("d-flex");
        //document.getElementById("idScrProgressPopupRef").classList.add("d-none");

        multipartMap = {
          Parts: [],
        };
        /* End */

        fileUploadCompleted = false;

        //console.log(vm)

        //api.createVideoInstruction({uuid: videoRecordUUID});

        currentChunkIndex = 0;
        s3UploadError = false;
        binaryBlob = null;

        prepareScreenRecording(this, mic, desktopAudio)
          .then(function () {
            //document.getElementById("idScreenRecorderContainer").classList.add("d-none");
            //document.getElementById("idScreenRecorderContainer").classList.remove("d-flex");            

            cancelAllUploads().then(function() {
              uploadInstances = [];
              console.log("Aborted")
            }).catch(function(err) {});
            recordingProcessStarted = true;
            //disableRecordingOptions();
            //hideScreenRecordReady();
            //hideScreenRecordError();

            that.$emit("started");

            that.countdown_number = 3;
            that.show_countdown_timer = true;

            that.$store.commit('setScreenRecordCountdownInfo', {
                  show_screen_record_countdown_timer: true,
                  scr_countdown_value: that.countdown_number
            })

            countdownTimer = setInterval(function () {
              if (that.countdown_number > 1) {

                that.countdown_number -= 1;
                //alert(that.countdown_number);

                that.$store.commit('setScreenRecordCountdownInfo', {
                  show_screen_record_countdown_timer: true,
                  scr_countdown_value: that.countdown_number
                })

              } else {
                if (that.countdown_number === 1) {
                  that.show_countdown_timer = false;
                  clearInterval(countdownTimer);
                  countdownTimer = null;

                  that.$store.commit('setScreenRecordCountdownInfo', {
                    show_screen_record_countdown_timer: true,
                    scr_countdown_value: that.countdown_number
                  })

                  if(videoRecordUUID) {
                    api.cancelVideoInstruction({uuid: videoRecordUUID}).catch(function(err) {});
                  }
                  // Start the recording now
                  videoRecordUUID = uuid.v4();
                  api.createVideoInstruction({ uuid: videoRecordUUID });
                  currentChunkIndex = 0;
                  s3UploadError = false;
                  binaryBlob = null;
                  totalUploadInitiated = 0;
                  totalUploadCompleted = 0;

                  that.$store.commit('setScreenRecordCountdownInfo', {
                    show_screen_record_countdown_timer: false,
                    scr_countdown_value: that.countdown_number
                  })

                  //console.log('Screen recording Stream', screenRecorderStream);
                  ///that.screenRecordPlayer.srcObject = screenRecorderStream;
                  ///that.screenRecordPlayer.muted = true;
                  //console.log("Stream started showing in preview");

                  that.videoStart = true;
                  that.screenDeviceReady = true;
                  that.recordingStatus = "recording";

                  let fileExtension = ".webm";

                  let mimeTypeSupported = "video/webm";
                  if (MediaRecorder.isTypeSupported("video/mp4")) {
                    mimeTypeSupported = "video/mp4";
                    fileExtension = ".mp4";
                  }

                  that.recorder = new MediaRecorder(screenRecorderStream, {
                    //mimeType: mimeTypeSupported,
                    frameRate: {
                      min: 24, // very important to define min value here
                      ideal: 24,
                      max: 25,
                    },
                    audioBitsPerSecond: 128000,
                    videoBitsPerSecond: 2500000, //https://mobile.developer.smartlook.com/docs/screen-recording-quality
                  });

                  that.recorder.ondataavailable = (e) => {
                    //console.log("Screen data");
                    //console.log(e.data);
                    //console.log(e.data.size);
                    //console.log("Screen data end");
                    recordedBlobs.push(e.data);

                    //console.log("totalUploadInitiated=" + totalUploadInitiated);
                    //console.log("totalUploadCompleted=" + totalUploadCompleted);

                    if(totalUploadInitiated - totalUploadCompleted >= 5) {
                      s3UploadError = true;
                      cancelAllUploads().then(function() {
                        uploadInstances = [];
                        console.log("Aborted")
                      }).catch(function(err) {});
                      return;
                    }

                    try {
                      console.log("s3UploadError " + s3UploadError)
                      if(s3UploadError) {
                        return;
                      }
                      let blobChunk = e.data;
                      if(blobChunk) {
                        currentChunkIndex += 1;
                        initializeS3(bucketName, bucketRegion, IdentityPoolId, function(s3Instance) {
                              fileUploadS3FileKey = "videos/" + videoRecordUUID + "/" + currentChunkIndex + fileExtension;
                              fileUploadS3Instance = s3Instance;
                              totalUploadInitiated += 1;
                              uploadToS3(fileUploadS3Instance, 1, fileUploadS3FileKey, blobChunk, null, null, null,
                              function(d) {
                                totalUploadCompleted += 1;
                                console.log("Uploaded chunk: " + currentChunkIndex);
                              },
                              function(err) {
                                s3UploadError = true;
                                console.log("Error uploading chunk: " + currentChunkIndex);
                              })
                          });
                      }
                    }catch(e) {
                      s3UploadError = true;
                      cancelAllUploads().then(function() {
                        uploadInstances = [];
                        console.log("Aborted")
                      }).catch(function(err) {

                      });
                    }
                  };

                  let onStopHandler = async () => {
                    //console.log(that.recorder.mimeType);
                    //blobs.push(MediaRecorder.requestData());
                    let blob = new Blob(recordedBlobs, {
                        type: mimeTypeSupported,
                      });
                    binaryBlob = blob;
                    binaryBlob.name = videoRecordUUID + fileExtension;
                    try {
                      console.log("Finishing");
                      that.$emit("stopped");
                      that.$refs["recordingProgressText"].textContent = "Uploading";
                      that.$refs["recordingProgressText"].classList.remove("d-none");
                      that.$refs["recordingProgressText"].classList.add("d-flex");

                      //that.screenRecordPlayer.src = URL.createObjectURL(blob);
                      if(s3UploadError) {
                          // Show the upload button
                          // Do the whole upload now
                          let completedCallback = status => {
                            if(status === "SUCCESS") {
                              // Finish the upload
                              var instruction = {
                                  key: "videos/" + videoRecordUUID,
                                  fileId: videoRecordUUID,
                                  context:"screen"
                              };
                              var buf = Buffer.from(JSON.stringify(instruction));

                              initializeS3(bucketName, bucketRegion, IdentityPoolId, function(s3Instance) {
                                  fileUploadS3FileKey = "videos/" + videoRecordUUID + "/index.json";
                                  fileUploadS3Instance = s3Instance;

                                  uploadToS3(fileUploadS3Instance, 1, fileUploadS3FileKey, buf, null, null, null,
                                    function(d) {
                                      console.log("Uploaded index.json");
                                      fileUploadCompleted = true;
                                      s3UploadError = false;
                                      //hideScreenRecordError();
                                      //showScreenRecordReady();
                                      //document.getElementById("idScrProgressPopupRef").classList.remove("d-flex");
                                      //document.getElementById("idScrProgressPopupRef").classList.add("d-none");
                                      //Vue.$toast.success("Video is ready", toastOptions);
                                      that.$refs["recordingProgressText"].textContent = "Uploading";
                                      that.$refs["recordingProgressText"].classList.remove("d-flex");
                                      that.$refs["recordingProgressText"].classList.add("d-none");
                                      that.$refs['refProceedButton'].classList.remove('d-none');
                                      that.$refs['refProceedButton'].classList.add('d-flex');
                                      that.$emit("uploaded",  videoRecordUUID, that.screen_record_video_src);
                                    },
                                    function(err) {
                                      s3UploadError = true;
                                      //hideScreenRecordReady();
                                      //showScreenRecordError();
                                      //document.getElementById("idScrProgressPopupRef").classList.remove("d-flex");
                                      //document.getElementById("idScrProgressPopupRef").classList.add("d-none");
                                      //Vue.$toast.error("Error in upload", toastOptions);
                                      that.$refs["recordingProgressText"].textContent = "Uploading";
                                      that.$refs["recordingProgressText"].classList.remove("d-flex");
                                      that.$refs["recordingProgressText"].classList.add("d-none");

                                      document.getElementById("idScreenManualUploadBtn").classList.remove("d-none");
                                      document.getElementById("idScreenManualUploadBtn").classList.add("d-flex");
                                      console.log("Error uploading index.json: ");
                                    })
                                });
                            }
                            else {
                              s3UploadError = true;
                              //hideScreenRecordReady();
                              //showScreenRecordError();
                              //document.getElementById("idScrProgressPopupRef").classList.remove("d-flex");
                              //document.getElementById("idScrProgressPopupRef").classList.add("d-none");
                              //Vue.$toast.error("Error in upload", toastOptions);
                              that.$refs["recordingProgressText"].textContent = "Uploading";
                              that.$refs["recordingProgressText"].classList.remove("d-flex");
                              that.$refs["recordingProgressText"].classList.add("d-none");

                              document.getElementById("idScreenManualUploadBtn").classList.remove("d-none");
                              document.getElementById("idScreenManualUploadBtn").classList.add("d-flex");

                            }
                        }
                        let progressCallback = progress => {
                            //document.getElementById("idScrProgressPopupRefText").textContent = "Processing(" + progress + "%)";
                            that.$refs["recordingProgressText"].textContent = "Uploading(" + progress + "%)";
                            that.$refs["recordingProgressText"].classList.remove("d-none");
                            that.$refs["recordingProgressText"].classList.add("d-flex");
                        }
                        tryS3Upload("screen", completedCallback, progressCallback, true);
                      }
                      else {
                        if(totalUploadInitiated === totalUploadCompleted) {
                            // No upload Error
                            // Finish the upload
                            var instruction = {
                                key: "videos/" + videoRecordUUID,
                                fileId: videoRecordUUID,
                                context:"screen"
                            };
                            var buf = Buffer.from(JSON.stringify(instruction));

                            initializeS3(bucketName, bucketRegion, IdentityPoolId, function(s3Instance) {
                                fileUploadS3FileKey = "videos/" + videoRecordUUID + "/index.json";
                                fileUploadS3Instance = s3Instance;

                                uploadToS3(fileUploadS3Instance, 1, fileUploadS3FileKey, buf, null, null, null,
                                  function(d) {
                                    console.log("Uploaded index.json");
                                    fileUploadCompleted = true;
                                    s3UploadError = false;
                                    //hideScreenRecordError();
                                    //showScreenRecordReady();
                                    //document.getElementById("idScrProgressPopupRef").classList.remove("d-flex");
                                    //document.getElementById("idScrProgressPopupRef").classList.add("d-none");
                                    //Vue.$toast.success("Video is ready", toastOptions);
                                    that.$refs["recordingProgressText"].textContent = "Uploading";
                                    that.$refs["recordingProgressText"].classList.remove("d-flex");
                                    that.$refs["recordingProgressText"].classList.add("d-none");
                                    that.$refs['refProceedButton'].classList.remove('d-none');
                                    that.$refs['refProceedButton'].classList.add('d-flex');
                                    that.$emit("uploaded",  videoRecordUUID, that.screen_record_video_src);
                                  },
                                  function(err) {
                                    s3UploadError = true;
                                    //hideScreenRecordReady();
                                    //showScreenRecordError();
                                    //document.getElementById("idScrProgressPopupRef").classList.remove("d-flex");
                                    //document.getElementById("idScrProgressPopupRef").classList.add("d-none");
                                    //Vue.$toast.error("Error in upload", toastOptions);
                                    that.$refs["recordingProgressText"].textContent = "Uploading";
                                    that.$refs["recordingProgressText"].classList.remove("d-flex");
                                    that.$refs["recordingProgressText"].classList.add("d-none");
                                    console.log("Error uploading index.json: ");

                                    document.getElementById("idScreenManualUploadBtn").classList.remove("d-none");
                                    document.getElementById("idScreenManualUploadBtn").classList.add("d-flex");

                                  })
                              });
                        }
                        else {
                          console.log("All files not yet uploaded. Waiting to finish...");
                          let timeWaited = 0;
                          let timeToWait = maxTimeWaitAfterRecordingStopped + (totalUploadInitiated - totalUploadCompleted) * 3;
                          (function waitForUploadFinish() {
                            if(totalUploadInitiated === totalUploadCompleted) {
                              console.log("All files uploaded. Now finishing...");
                              var instruction = {
                                  key: "videos/" + videoRecordUUID,
                                  fileId: videoRecordUUID,
                                  context:"screen"
                              };
                              var buf = Buffer.from(JSON.stringify(instruction));

                              initializeS3(bucketName, bucketRegion, IdentityPoolId, function(s3Instance) {
                                  fileUploadS3FileKey = "videos/" + videoRecordUUID + "/index.json";
                                  fileUploadS3Instance = s3Instance;

                                  uploadToS3(fileUploadS3Instance, 1, fileUploadS3FileKey, buf, null, null, null,
                                    function(d) {
                                      console.log("Uploaded index.json");
                                      fileUploadCompleted = true;
                                      s3UploadError = false;
                                      //hideScreenRecordError();
                                      //showScreenRecordReady();
                                      //document.getElementById("idScrProgressPopupRef").classList.remove("d-flex");
                                      //document.getElementById("idScrProgressPopupRef").classList.add("d-none");
                                      //Vue.$toast.success("Video is ready", toastOptions);
                                      that.$refs["recordingProgressText"].textContent = "Uploading";
                                      that.$refs["recordingProgressText"].classList.remove("d-flex");
                                      that.$refs["recordingProgressText"].classList.add("d-none");
                                      that.$refs['refProceedButton'].classList.remove('d-none');
                                      that.$refs['refProceedButton'].classList.add('d-flex');
                                      that.$emit("uploaded",  videoRecordUUID, that.screen_record_video_src);

                                    },
                                    function(err) {
                                      s3UploadError = true;
                                      //hideScreenRecordReady();
                                      //showScreenRecordError();
                                      //document.getElementById("idScrProgressPopupRef").classList.remove("d-flex");
                                      //document.getElementById("idScrProgressPopupRef").classList.add("d-none");
                                      //Vue.$toast.error("Error in upload", toastOptions);
                                      that.$refs["recordingProgressText"].textContent = "Uploading";
                                      that.$refs["recordingProgressText"].classList.remove("d-flex");
                                      that.$refs["recordingProgressText"].classList.add("d-none");

                                      document.getElementById("idScreenManualUploadBtn").classList.remove("d-none");
                                      document.getElementById("idScreenManualUploadBtn").classList.add("d-flex");

                                      console.log("Error uploading index.json: ");
                                    })
                                });
                                return;
                            }
                            if (timeWaited >= timeToWait) {
                              console.log("Waiting is over");
                              s3UploadError = true;
                              cancelAllUploads().then(function() {
                                uploadInstances = [];
                                console.log("Aborted")
                              }).catch(function(err) {});
                              //hideScreenRecordReady();
                              //showScreenRecordError();
                              //document.getElementById("idScrProgressPopupRef").classList.remove("d-flex");
                              //document.getElementById("idScrProgressPopupRef").classList.add("d-none");
                              //Vue.$toast.error("Error in upload", toastOptions);
                              that.$refs["recordingProgressText"].textContent = "Uploading";
                              that.$refs["recordingProgressText"].classList.remove("d-flex");
                              that.$refs["recordingProgressText"].classList.add("d-none");

                              document.getElementById("idScreenManualUploadBtn").classList.remove("d-none");
                              document.getElementById("idScreenManualUploadBtn").classList.add("d-flex");
                              return;
                            }
                            let progressPerccentage = parseInt(
                              (totalUploadCompleted * 100) / totalUploadInitiated
                            );
                            //document.getElementById("idScrProgressPopupRefText").textContent = "Processing(" + progressPerccentage + "%)";
                            that.$refs["recordingProgressText"].textContent = "Uploading(" + progressPerccentage + "%)";
                            that.$refs["recordingProgressText"].classList.remove("d-none");
                            that.$refs["recordingProgressText"].classList.add("d-flex");
                            timeWaited += 1;
                            console.log("Waiting...")
                            setTimeout(waitForUploadFinish, 1000);
                          })();
                        }
                      } 
                    }catch(e) {
                        s3UploadError = true;
                        //document.getElementById("idScrProgressPopupRef").classList.remove("d-flex");
                        //document.getElementById("idScrProgressPopupRef").classList.add("d-none");
                        that.$refs["recordingProgressText"].textContent = "Uploading";
                        that.$refs["recordingProgressText"].classList.remove("d-flex");
                        that.$refs["recordingProgressText"].classList.add("d-none");

                        document.getElementById("idScreenManualUploadBtn").classList.remove("d-none");
                        document.getElementById("idScreenManualUploadBtn").classList.add("d-flex");

                        cancelAllUploads().then(function() {
                          uploadInstances = [];
                          console.log("Aborted")
                        }).catch(function(err) {});
                    }
                    /* End */

                    //that.screenRecordPlayer.src = null;

                    //console.log("Blob Size");
                    //console.log(blob.size);

                    that.render_video_components = true;

                    screen_record_blob = blob;

                    //allBlobs.push(blob);

                    that.screen_recorded = true;
                    that.screen_record_video_src = URL.createObjectURL(blob);

                    //that.screenRecordPlayer.srcObject = null;
                    //that.screenRecordPlayer.muted = false;
                    that.recorder = null;
                    that.videoStart = false;
                    that.recordingStatus = "pending";

                    screenRecorderStream = null;
                    screenDesktopStream = null;
                    screenAudioStream = null;
                  };

                  that.recorder.onstop = onStopHandler;

                  screenRecorderStream
                    .getVideoTracks()[0]
                    .addEventListener("ended", function () {
                      //setTimeout(onStopHandler, 1500);
                      that.recorder.stop();
                    });

                  that.recorder.start(2000);

                  // To show recording time.
                  let dateStarted = new Date().getTime();
                  (function looper() {
                    if (!that.recorder) {
                      return;
                    }
                    let rd = (new Date().getTime() - dateStarted) / 1000;
                    if (rd >= maxScreenRecordingDuration) {
                      that.recorder.stop();
                      return;
                    }
                    if(that.recordingStatus === 'recording') {
                      that.$refs['scrDurationRef'].textContent =
                        "(" + that.calculateTimeDuration(rd) + ")";
                        //" / " +
                        //that.calculateTimeDuration(maxScreenRecordingDuration) + ")";
                    }
                    
                    setTimeout(looper, 1000);
                  })();
                }
              }
            }, 1000);
          })
          .catch(function (err) {
            console.log(err);
          });
    },

    stopScreenRecording: function () {
      this.recorder.stop();
      screenRecorderStream.getTracks().forEach((s) => s.stop());
      //this.screenRecordPlayer.srcObject = null;
      //this.screenRecordPlayer.muted = false;
      this.recorder = null;
      this.videoStart = false;
      this.recordingStatus = "pending";

      screenRecorderStream = null;
      screenDesktopStream = null;
      screenAudioStream = null;
    },
  },
    created() {
      this.moment = moment;
    },
    beforeDestroy() {
      
    },
    mounted() {
      AWS.config.httpOptions.timeout = 0;

      let that = this;

      document.getElementById("idScreenRecorderContainer").classList.add("d-flex");
      document.getElementById("idScreenRecorderContainer").classList.remove("d-none");  

      navigator.mediaDevices
      .enumerateDevices()
      .then(function (deviceInfo) {
        that.screenDevices = deviceInfo;
        console.log(that.screenDevices)
        if (!that.screenAudioSrc) {
          that.screenAudioSrc = firstaudiodevice(that.screenDevices);
        }
      })
      .catch(function (err) {
         //alert(err)
      });
    },
  }
  </script>
  <style scoped>
  #idScreenRecordingOptions {
    width: 600px;
    height: 340px;
    position: relative;
    -webkit-animation: fadeIn 1s;
     animation: fadeIn 1s;
  }
  #idScreenRecordReplay {
    width: 800px;
    position: relative;
    -webkit-animation: fadeIn 1s;
     animation: fadeIn 1s;
  }
  @-webkit-keyframes fadeIn {
      from { opacity: 0; }
        to { opacity: 1; }
  }
  @keyframes fadeIn {
      from { opacity: 0; }
        to { opacity: 1; }
  }
  @media only screen and (max-width: 767px) {
    #idScreenRecordingOptions, #idScreenRecordReplay {
      width: 100%;
      height: 340px;
    }
  }
  </style>
  
  