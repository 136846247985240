<template>
  <div class="d-flex flex-column">
      <a v-if="recording_mode!=null&&!hide_record_popup_close" href="#" @click="closeRecordingDialog()" style="color: #fff!important; font-size: 2rem; position: fixed; top: 20px; right: 20px; z-index: 9999999;"><i class="fas fa-times"></i></a>
      <div v-if="recording_mode==='camera'" id="idCameraRecorderContainer" class="d-flex flex-wrap justify-content-center align-items-center" style="position: fixed; inset: 0; z-index: 9999998; background: rgba(0, 0, 0, 0.4)">
          <CameraRecorderComponent v-on:proceed="onProceed" v-on:started="onStarted" v-on:uploaded="onCameraUploadCompleted"></CameraRecorderComponent>
      </div>
      <div v-if="recording_mode==='screen'" id="idScreenRecorderContainer" class="d-flex flex-wrap justify-content-center align-items-center" :style="css_style_for_screen_recorder">
        <ScreenRecorderComponent v-on:proceed="onProceed" v-on:started="onScreenRecordingStarted" v-on:uploaded="onScreenUploadCompleted" v-on:stopped="onScreenRecordingStopped"></ScreenRecorderComponent>
      </div>
      <div v-if="recording_mode==='upload'" id="idVideoFileUploaderContainer" class="d-flex flex-wrap justify-content-center align-items-center" style="position: fixed; inset: 0; z-index: 9999998; background: rgba(0, 0, 0, 0.4)">
          <VideoFileUploaderComponent v-on:proceed="onProceed" v-on:started="onStarted" v-on:uploaded="onFileUploadCompleted"></VideoFileUploaderComponent>
      </div>
    </div>
</template>

<script>
import Vue from "vue";
import * as api from "@/services/api";
import CameraRecorderComponent from "@/components/CameraRecorderComponent";
import ScreenRecorderComponent from "@/components/ScreenRecorderComponent"
import VideoFileUploaderComponent from "@/components/VideoFileUploaderComponent"
import moment from "moment";

let toastOptions = {
    position: "bottom-right",
    timeout: 2000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false,
};

export default {
  name: "NewRecordingComponent",
  data() {
    return {
        show_create_options: false,
        hideRecordPopup: false,
        screenRecordingStatus: "pending",
        recording_started: false
    };
  },
  components: {
    CameraRecorderComponent,
    ScreenRecorderComponent,
    VideoFileUploaderComponent
  },
  created() {
    this.moment = moment;
  },
  computed: {
    css_style_for_screen_recorder() {
      if(this.screenRecordingStatus == 'pending') {
        return 'position: fixed;inset: 0; z-index: 9999998; background: rgba(0, 0, 0, 0.4)';
      }
      else if(this.screenRecordingStatus == 'recording') {
        return 'position: fixed; right: 0; bottom: 0; z-index: 9999998; background: rgba(0, 0, 0, 0.4)';
      }
      else {
        return 'position: fixed; inset: 0; z-index: 9999998; background: rgba(0, 0, 0, 0.4)';
      }
    },
    show_screen_record_countdown_timer() {
      return this.$store.state.show_screen_record_countdown_timer;
    },
    recording_mode() {
      return this.$store.state.recording_mode;
    },
    hide_record_popup() {
      return this.$store.state.hide_record_popup;
    },
    hide_record_popup_close() {
      return this.$store.state.hide_record_popup_close;
    }
  },
  mounted: function () {
    
  },
  methods: {
    onProceed() {
      this.$store.commit("setExecutePostUploadAction");
      this.closeRecordingDialog();
    },
    onStarted() {
      this.recording_started = true;
      this.$store.commit("setRecordPopupCloseD", true);
    },
    closeRecordingDialog() {
      this.recording_started = false;
      this.$store.commit("clearRecordingMode");
    },
    onScreenRecordingStarted() {
        this.recording_started = true;
        this.screenRecordingStatus = "recording";
        this.$store.commit("setRecordPopupCloseD", true);
    },
    onScreenRecordingStopped() {
      this.screenRecordingStatus = "stopped";
    },
    showCreateOptions() {
      this.show_create_options = true;
    },
    hideCreateOptions() {
      this.show_create_options = false;
    },
    startRecordingBy(mode) {
        this.recording_mode = 'random';
        this.show_create_options = false;
        this.recording_mode = mode;
        this.hideRecordPopup = false;
    },
    onCameraUploadCompleted(videoRecordUUID, record_video_src) {
      //alert(videoRecordUUID);
      let videoTitle = 'Untitled Recording';
      this.$store.commit('updateRecordingInfo', {
        status: 'uploaded',
        video_upload_uuid: videoRecordUUID,
        video_record_src: record_video_src,
        new_video_title: videoTitle
      });
      //this.closeRecordingDialog();
      //Vue.$toast.success("Upload completed", toastOptions);
      api.uploadNewRecordingVideo({
        video_uuid: videoRecordUUID,
        title: videoTitle,
        source: 'camera',
        original_file_name: ''
      })
    },
    onScreenUploadCompleted(videoRecordUUID, record_video_src) {
      //alert(videoRecordUUID);
      let videoTitle = 'Untitled Recording';
      this.$store.commit('updateRecordingInfo', {
        status: 'uploaded',
        video_upload_uuid: videoRecordUUID,
        video_record_src: record_video_src,
        new_video_title: videoTitle
      });
      //this.closeRecordingDialog();
      //Vue.$toast.success("Upload completed", toastOptions);
      api.uploadNewRecordingVideo({
        video_uuid: videoRecordUUID,
        title: videoTitle,
        source: 'screen',
        original_file_name: ''
      })
    },
    onFileUploadCompleted(videoRecordUUID, record_video_src) {
      //alert(videoRecordUUID);
      let videoTitle = 'Untitled Recording';
      this.$store.commit('updateRecordingInfo', {
        status: 'uploaded',
        video_upload_uuid: videoRecordUUID,
        video_record_src: record_video_src,
        new_video_title: videoTitle
      });
      //this.closeRecordingDialog();
      //Vue.$toast.success("Upload completed", toastOptions);
      api.uploadNewRecordingVideo({
        video_uuid: videoRecordUUID,
        title: videoTitle,
        source: 'file',
        original_file_name: ''
      })
    }
  },

  beforeDestroy() {},
};
</script>
<style>
.recording-item h5 {
  font-weight: 600;
  font-size: 1.2rem;
}
.recording-item {
  width: 220px;
  height: 350px;
}

.create-opt-block-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.create-opt-block {
    padding: 10px;
}
@media only screen and (max-width: 767px) {
  .recording-item {
    width: 100%;
    height: 350px;
    margin-bottom: 20px;
  }
  div.modal-dialog {
    max-width: 700px !important;
  }
  .create-opt-block-wrapper {
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .create-opt-block {
    margin-bottom: 10px;
  }
  .video-options-container {
    width: 100%;
    margin-top: 20px;
  }
}
@media (min-width: 576px) {
  div.modal-dialog {
    max-width: 700px !important;
  }
}
#idRecordingDetailPopup .modal-dialog {
  max-width: 700px !important;
}

.record-content {
  background: #fff;
  padding: 0px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.record-content h5 {
  font-size: 20px !important;
  margin-left: 20px;
}

.record-content ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  text-align: left;
}
.more-action p {
  font-weight: 900;
  margin-top: 4px;
  font-size: 16px;
}
.more-action {
  position: absolute;
  top: 30px;
  right: 30px;
  background: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
}

.record-content li {
  display: inline-block;
  margin: 18px;
  margin: 18px;
  padding: 0px !important;
}

.col-md-4.record-list {
  padding: 20px;
  height: auto;
}
i.fas.fa-copy {
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
}
.record-content {
  background: #fff;
  padding: 0px;
  cursor: pointer;
}
.record-content:hover {
  border: 1px solid yellow;
  transition: 1s;
}

.image-content img {
  width: 100%;
  height: 240px;
  border-radius: 10px 10px 0px 0px;
  object-fit: cover;
}
.image-content {
  height: 265px;
}
ul.video-recorder-list {
  position: absolute;
  top: 80px;
  right: 30px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 10px;
}

ul.video-recorder-list li {
  display: block;
  padding: 7px 25px !important;
  text-align: center;
  margin: 0px !important;
}
ul.video-recorder-list li:hover {
  background: #e9e9e9bf;
}
.copy-link {
  position: absolute;
  top: 30px;
  right: 90px;
}
@-webkit-keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}
@keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}
@-webkit-keyframes fadeOut {
    from { opacity: 1; }
      to { opacity: 0; }
}
@keyframes fadeOut {
    from { opacity: 1; }
      to { opacity: 0; }
}
#idScreenRecorderContainer, #idCameraRecorderContainer, #idVideoFileUploaderContainer {
  -webkit-animation: fadeIn 1s;
   animation: fadeIn 1s;
}
.d-none {
  -webkit-animation: fadeOut 1s;
    animation: fadeOut 1s;
}
@media only screen and (max-width: 48em) {
  .header-link {
    width: 100% !important;
  }
}
</style>
