<template>
    <div :style="get_style">
    <span v-html="text"></span>
    </div>
</template>
  <script>
  // eslint-disable-next-line no-unused-vars
  export default {
    name: "Avatar",
    props: ['hsl', 'text'],
    data() {
      return {};
    },
    computed: {
        get_style() {
            return "display: flex; width: 35px; height: 35px; border-radius: 50%; font: 14px / 35px Helvetica, Arial, sans-serif; align-items: center; justify-content: center; text-align: center; user-select: none; background-color: "+ this.hsl +"; color: rgb(255, 255, 255);";
        }
    },
    methods: {},
    mounted: function () {},
    beforeDestroy() {},
  };
  </script>
  
  <style scoped>
  
  </style>
  